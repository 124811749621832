import './FacultyRow.sass'

const FacultyRow = (props) => {
    return (
        <div className='w-100 faculty-row d-flex align-items-center justify-content-between'>
            <span className='faculty-name'>
                    {props.name}
            </span>
            <span className='faculty-score'>
                {props.score}
            </span>
        </div>
    )
}

export default FacultyRow
