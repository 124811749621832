import "./Sporturi.sass"
export default function General({language}) {
    return (
        <>
        {language === "RO" ? 
            <div className="sportRuleSet">
            <div className="sportRule">
                <br/>
                {/* <h4>
                    <a href='https://docs.google.com/spreadsheets/d/1FXjN8zvVt9IlJx0UeIJIHB24PBp0eAaudBpU0cfXGU8/edit?usp=sharing'
                        target="_blank" rel="noreferrer">
                        Grupele si orarul pe zile
                    </a>
                </h4> */}
                <h4>
                    <b>Data</b>: 17-19 mai
                </h4>
                <h4>
                    <b>Locație</b>: vineri - UPB, sâmbătă - UTCB&Tei, duminică - UTCB
                </h4>
				<h4>
					{/* <b>Perioada inscrierilor</b>: 20 Martie - 17 Aprilie */}
				</h4>
				<br/><br/>
                <h3>
                    <b>Structura evenimentului</b>
                </h3>
                <h4>
                    Competiția constă în <b>5</b> activități sportive: 
                </h4>
                <ul>
                    <li>
                        Fotbal
                    </li>
                    <li>
                        Handbal
                    </li>
                    <li>
                        Volei
                    </li>
                    <li>
                        Streetball
                    </li>
                    <li>
                        Tenis de masă
                    </li>
                </ul>
                <p>
                    Competiția se desfășoară între <b>facultățile din cadrul UNSTPB.</b>
                </p>
                <p>
                    Fiecare facultate își va forma o echipă de <b>minimum 12 și maximum 18 studenți</b> (obligatoriu aparținând facultății respective), dintre care, în mod obligatoriu:
                </p>
                <ul>
                    <li>
                        <b>14 băieți</b> (maximum);
                    </li>
                    <li>
                        <b>4 fete</b> (minimum);
                    </li>
                </ul>
                <p>
                    <b>Echipele ce nu vor respecta condițiile enunțate mai sus nu vor fi considerate eligibile pentru a participa în cadrul Poli Olympics.</b>
                </p>
				<p>
					<b>Opțional, dintre cei 18 sportivi reprezentanți ai unei facultăți, se acceptă și integrarea în lot a unui număr de maximum 2 profesori care să predea la facultatea respectivă.</b>
				</p>
                <p>
					Echipele care aleg să nu se prezinte la o anumită competiție își asumă faptul că nu vor obține puncte pentru sportul respectiv care să le ajute în clasamentul general. Neanunțarea neparticiparii la unul sau mai multe sporturi până în momentul tragerilor la sorți pentru grupe, vor fi sancționate conform regulamentelor pentru fiecare sport în parte.
                </p>
				<h3>
					<b>Înscrierea la eveniment</b>
				</h3>
				<p>
					Înscrierile se vor face pe platforma online a evenimentului. Oricine se înscrie la competiție este obligat ca în formularul de înregistrare să completeze adresa de e-mail instituțională. Potențialele conturi ce vor fi create utilizând adrese de e-mail personale nu vor putea fi generate, platforma nepermițând astfel de adrese de e-mail.
				</p>
				<p>
					Vor exista două etape de înscriere:
				</p>
				<ul>
					<li>
						<b>Prima etapă(20 Martie - 3 Aprilie)</b>, fiind una colectivă în care o singură persoană, din ipostaza de căpitan, își va înscrie echipa. O echipă ce se înscrie în prima etapă, trebuie să fie formată din minimum 8 oameni pentru a fi luată în considerare drept echipă posibil participantă în cadrul Poli Olympics și a fi eligibilă pentru a doua etapă de înscriere.
					</li>
					<li>
						<b>A doua etapă(4 Aprilie - 17 Aprilie)</b> de înscriere este una individuală în care fiecare participant își va face cont și va putea vizualiza toate echipele formate în cadrul facultății sale, având posibilitatea de a solicita înscrierea în cadrul tuturor echipelor incomplete. Ulterior, căpitanii solicitați vor lua legătura cu participanții care s-au înscris individual și vor decide pe cine își vor asuma să accepte în echipele lor (specificăm că, simpla înscriere în etapa a doua nu garantează participarea în cadrul Poli Olympics).
					</li>
				</ul>
				<p>
					Odată ce se vor forma echipele, se vor face grupurile de WhatsApp aferente, unde echipele se vor organiza pentru a juca între ele. Echipa de organizare nu va interveni în niciun fel în alegerea loturilor reprezentante ale fiecărei facultăți, selecția echipelor finale făcându-se intern, pentru fiecare facultate în parte. La finalul selecțiilor, doar o echipă va trece și va fi reprezentanta facultății sale, având posibilitatea de a lua parte la competiția Poli Olympics ce se va desfășura în perioada 17 - 19 mai 2024. Echipele ce vor fi disputat faza preliminară pentru a decide echipa reprezentantă a facultății vor avea dreptul de a face 3 schimbări sau completări în cadrul echipei, cu condiția de a nu se depăși numărul maxim de 18 participanți în echipă.
				</p>
				<p>
					La momentul începerii competiției, în ziua de <b>17 mai</b>, fiecare echipă va trebui să vină în <b>formație completă</b> (toți jucătorii, de la toate competițiile, indiferent de programul meciurilor) pentru <b>check-in.</b> <b>Fiecare echipă are obligativitatea de a se asigura că toți participanții unei echipe ce se prezintă la check-in și în cadrul competiției, sunt aceleași persoane ce figurează și pe platforma evenimentului în cadrul facultății pe care o reprezintă.</b>
				</p>
                <h3>
                    <b>Punctare</b>
                </h3>
                <ul>
                    <li>
						Punctarea fiecărei competiții poate fi analizată în cadrul regulamentului aferent ce se regăsește, de asemenea, pe platforma evenimentului.
                    </li>
                    <li>
						Fiecare competiție are un format predefinit în ceea ce privește componența echipei, atât ca număr de membri, cât și ca distribuție pe sexe, iar nerespectarea acestuia duce la pierderea punctajului pe competiție.		</li>
                    <li>
					Punctajul final aferent fiecărei echipe se va calcula însumând toate punctajele obținute în cadrul fiecărei competiții la care a participat, în vederea stabilirii clasamentului final.
                    </li>
                </ul>
                <h3>
                    Premii
                </h3>
                <ul>
                    <li>
						În cadrul fiecărei competiții, vor fi premiate echipele de pe primele 3 locuri.
                    </li>
                    <li>
						Vor exista premii și pentru clasamentul general, unde se adună punctajele obținute de o anumită facultate la toate cele 5 competiții. Podiumul va fi alcătuit din primele 3 locuri, echipe ce vor fi ulterior premiate.
                    </li>
                    <li>
						În cazul în care mai multe echipe au același punctaj în clasamentul general, se va organiza un meci de departajare la un sport ales de comun acord. În cazul neințelegerii de comun acord sportul va fi ales aleator. In cazul în care ambele echipe decid ca meciul de departajare sa fie un meci de fotbal, atunci acesta se va ține în sala.
                    </li>
                </ul>
                <h3>
                    Conduita sportivilor
                </h3>
				<ul>
					<li>
						Orice tip de agresiune fizică intenționată din afara spațiului de joc, se sancționează cu eliminarea jucătorului în cauză din competiția respectivă.
					</li>
					<li>
						Orice tip de agresiune fizică intenționată ce are loc în timpul unei acțiuni de joc va fi analizată de către echipa de organizare și arbitri pentru a se stabili dacă sportivul va fi sau nu eliminat din competiție.
					</li>
					<li>
						În funcție de sportul unde au avut loc agresiunile fizice, va exista un număr maxim de astfel de acțiuni până când întreaga echipa va fi eliminată din competiția sportivă aferentă.
					</li>
					<li>
						Orice tip de agresiune fizică va fi sancționată imediat conform regulamentului, neexistând posibilitatea primirii unui avertisment sau revenire asupra deciziei.
					</li>
					<li>
						Utilizarea unui limbaj rasial sau homofob implică eliminarea directă din cadrul evenimentului fără posibilitatea de revenire la decizie.
					</li>
					<li>
						Acțiunile ostentative de natură nudică sau sexuală duc, de asemenea, la eliminarea directă din cadrul evenimentului fără posibilitatea de revenire la decizie.
					</li>
					<li>
						Pentru acțiunile de natură obscenă, o să se aplice regulamentul aferent fiecărei competiții sportive, urmând ca în cazul unui abuz al acestui tip de comportament, echipa de organizare să se consulte pentru a stabili sancțiunea ce va fi aplicată în conformitate cu gravitatea faptei.
					</li>
					<li>
						Jucătorii care vor prezenta un comportament ce poate pune în pericol siguranța și/sau integritatea fizică și/sau morală a arbitrilor vor fi sancționați la fața locului în funcție de gravitatea faptei.
					</li>
					<li>
						Orice comportament neadecvat față de echipa de organizare sau față de voluntarii evenimentului se va sancționa cu eliminarea directă din cadrul evenimentului.
					</li>
					<li>
						Orice acțiune intenționată distructivă asupra bunurilor evenimentului (ex: cameră de filmat, banner, etc.) va duce la eliminarea din Poli Olympics.
					</li>
					<li>
						Este interzis accesul cu obiecte/substanțe ce pot afecta integritatea sălii și a terenului de joc (ex: CLISTER, petarde, etc.). Mai multe detalii legate de ce obiecte/substanțe sunt interzise în funcție de competiție se pot regăsi în regulamentele aferente fiecărui sport. Orice încălcare a acestei reguli va duce la eliminarea directă din cadrul evenimentului.
					</li>
				</ul>
				<h3>
					Conduita suporterilor
				</h3>
				<p>
					În incinta bazei sportive, suporterii trebuie să prezinte un comportament civilizat, respectând regulile impuse de către organizatori.
				</p>
				<p>
					<b>În situația în care un număr semnificativ de suporteri ai unei facultăți prezintă un comportament neadecvat și repetat ce se regăsește în regulamentul de conduită ce va fi prezentat mai jos, toți aceștia vor fi eliminați din cadrul evenimentului, iar echipa sportivă a respectivei facultății va suferi sancțiuni disciplinare, care pot merge până la eliminarea sa definitivă din cadrul competiției/evenimentului, în funcție de gravitatea situației, decizie ce va fi analizată de către echipa de organizare.</b>
				</p>
				<ul>
					<li>
						Orice tip de agresiune fizică, se sancționează cu eliminarea suporterului în cauză din cadrul evenimentului
					</li>
					<li>
						Suporterilor le este interzis să intre pe suprafața de joc, în timpul desfășurării meciurilor. Încălcarea acestei reguli poate duce la eliminarea din cadrul competiției/evenimentului în funcție de gravitatea faptei.
					</li>
					<li>
						Orice tip de agresiune fizică va fi sancționată imediat conform regulamentului, neexistând posibilitatea primirii unui avertisment sau revenire asupra deciziei
					</li>
					<li>
						Utilizarea unui limbaj rasial sau homofob implica eliminarea directă din cadrul evenimentului fără posibilitatea de revenire la decizie
					</li>
					<li>
						Acțiunile ostentative de natură nudică sau sexuală duc, de asemenea, la eliminarea directă din cadrul evenimentului fără posibilitatea de revenire la decizie.
					</li>
					<li>
						Suporterii care vor prezenta un comportament ce poate pune în pericol siguranța și/sau integritatea fizică și/sau morală a arbitrilor vor fi sancționați la fața locului în funcție de gravitatea faptei.
					</li>
					<li>
						Orice comentariu adresat in mod direct arbitrului se va sanctiona initial cu avertisment si ulterior cu cartonas galben si rosu.
					</li>
					<li>
						Orice comportament neadecvat față de echipa de organizare sau față de voluntarii evenimentului se va sancționa cu eliminarea directă din cadrul evenimentului.
					</li>
					<li>
						Utilizarea repetată a unui limbaj licențios poate aduce eliminarea din cadrul competiției/evenimentului, decizie ce va fi luată de către echipa de organizare în urma analizei faptei săvârșite.
					</li>
					<li>
						Orice acțiune intenționată distructivă asupra bunurilor evenimentului (ex: cameră de filmat, banner, etc.) va duce la eliminarea din Poli Olympics.
					</li>
					<li>
						Este interzis accesul cu obiecte/substanțe ce pot afecta integritatea atât a persoanelor prezente, și a bazelor sportive (ex:petarde, fumigene etc.). Mai multe detalii legate de ce obiecte/substanțe sunt interzise în funcție de competiție se pot regăsi în regulamentele aferente fiecărui sport. Orice încălcare a acestei reguli va duce la eliminarea directă din cadrul evenimentului.
					</li>
				</ul>
                <p>
                	<i>Menționăm faptul că echipa de organizare își rezervă dreptul de a modifica prezentul regulament în cazul apariției unor situații neprevăzute.</i>
                </p>
                <p>
                    <i>De asemenea, fiecare participant în momentul în care se înscrie la evenimentul Poli Olympics își asumă riscurile unor eventuale accidentări venite din situații mai mult sau mai puțin sportive, echipa de organizare neasumându-și responsabilitatea pentru posibilele accidentări ce ar putea surveni. Totuși, echipa de organizare își asumă responsabilitatea asigurării tuturor condițiilor necesare desfășurării evenimentului în condițiile de siguranță impuse prin lege.</i>
                </p>
            </div>
        </div> : 
            // ------------------------------------------- Engleza -------------------------------------------
            <div className="sportRuleSet">
            <div className="sportRule">
                <br/>
                {/* <h4>
                    <a href='https://docs.google.com/spreadsheets/d/1FXjN8zvVt9IlJx0UeIJIHB24PBp0eAaudBpU0cfXGU8/edit?usp=sharing'
                        target="_blank" rel="noreferrer">
                        Grupele si orarul pe zile
                    </a>
                </h4> */}
                <h4>
                    <b>Date</b>: May 20-21
                </h4>
                <h4>
                    <b>Location</b>: UPB gym
                </h4><br/>
                <h3>
                    <b>Event's structure</b>
                </h3>
                <h4>
                    The competition consists of <b>5</b> sports: 
                </h4>
                <ul>
                    <li>
                        Football
                    </li>
                    <li>
                        Handball
                    </li>
                    <li>
                        Volleyball
                    </li>
                    <li>
                        Streetball
                    </li>
                    <li>
                        Table tennis
                    </li>
                </ul>
                <p>
                    The competition takes place between<b>15 teams</b> representing the 15 faculties of the <b>Politehnica University of Bucharest..</b>
                </p>
                <p>
                    Each faculty will form a team of minimum 12 and maximum 18 students (mandatory belonging to the respective faculty), of which, on a mandatory basis:
                </p>
                <ul>
                    <li>
                        <b>16 boys (maximum);</b>
                    </li>
                    <li>
                        <b>4 girls (minimum); </b>
                    </li>
                </ul>
                <p>
                    <b>Teams that will not have a minimum of 12 people, a minimum of 5 girls and a maximum of 13 boys will not be considered eligible to participate in the Poli Olympics. </b>
                </p>
                <p>
                    All teams are required to participate in all competitions. Teams that choose not to attend a certain competition assume the default loss of all the matches they would have had to play, this being done according to the rules for each competition.
                </p>
                <p>
                    Registration will be made on the online platform of the event. Anyone who registers for the competition is required to fill in the institutional email address in the registration form.  Potential accounts that will be created using personal email addresses will not be able to be generated, the platform not allowing such email addresses. There will be two stages of registration, the first being a collective one in which each person, as captain, will register their team. A team that registers for the first stage must consist of at least 8 people to be considered as a possible team participating in Poli Olympics and to be eligible for the second stage of registration. The second stage of registration is an individual one in which each participant will make an account and will be able to view all the teams formed within his faculty, having the possibility to request enrollment in all the teams that are not filled. Subsequently, the requested captains will contact the participants who have registered individually in order to complete their own team (we specify that the mere registration in the second stage does not guarantee participation în Poli Olympics, team captains for each team will decide who they will accept into their teams).
                </p>
                <p>
                    Once the teams are formed, the Whatsapp groups related to each faculty will be created, where the teams will organize to play each other.  Following the games that will be assisted by people from the organizing team and the sports department of the event, only one team will pass and will be the representative of its faculty, having the opportunity to take part in the Poli Olympics competition that will take place between 20-21 May 2023. The teams that will have played the preliminary phase in order to decide the team representing the faculty they represent will have the right to make 3 changes or additions within the team, provided that the maximum number of 18 participants in the team is not exceeded. 
                </p>
                <p>
                    At the time of the start of the competition, on May 20, each team will have to come in full formation (all players, from all competitions, regardless of the schedule of matches) for check-in. <b> Each team has the obligation to ensure that all the participants of a team that shows up at check-in and in the competition, are the same people who appear on the event platform  within the faculty he represents. </b>
                </p>

                <h3>
                    <b>Scoring</b>
                </h3>
                <ul>
                    <li>
                        The scoring of each competition can be analyzed within each competition’s related regulation that can also be found on the event platform.
                    </li>
                    <li>
                        Each competition has a predefined format in terms of team composition, both in number and sex, and failure to do so leads to the loss of the score on the competition. 
                    </li>
                    <li>
                    The final score for each team will be calculated by summing up all the scores obtained in each competition in which it participated, in order to establish the final ranking.
                    </li>
                </ul>
                {/* <h3>
                    Premii
                </h3>
                <ul>
                    <li>
                        În cadrul fiecărei competiții, vor fi premiate echipele de pe primele 3 locuri.
                    </li>
                    <li>
                        Vor exista premii și pentru clasamentul general, unde se adună punctajele obținute de o anumită facultate la toate cele 6 competiții. Și în acest caz, podiumul va fi alcătuit din 3 locuri, toate aceste echipe fiind premiate.
                    </li>
                    <li>
                        În cazul în care mai multe echipe au același punctaj în clasamentul general, se va organiza un meci de departajare la un sport ales de comun acord.
                    </li>
                </ul> */}
                <h3>
                    Conduct of athletes and supporters 
                </h3>
                <p>
                    Inside the sports base, each member of each team, including the supporters, must present a civilized behavior, observing the rules imposed by the organizers.                 </p>
                <p>
                    Any team or player of a team that behaves inappropriately to a sports competition (provocations to violence, insults, insults to both those around and the referee) will be excluded from the premises of the sports base and from the competition both he and the team in which he participates as a player.                </p>
                <p>
                    If due to unsportsmanlike altercations between the teams or the smooth running of the game can no longer be allowed, the match will stop and both teams will be penalized by not awarding points for the match. If the uncivilized behavior of some persons (players or supporters) will cause damage of any kind, those persons will have to bear all the damages produced.                
                </p>
                <p>
                <i>We mention that the organizing team reserves the right to amend this Regulation in case of unforeseen situations. </i>

                </p>
                <p>
                    <i>Also, each participant when registering for the Poli Olympics event assumes the risks of possible injuries coming from more or less sporting situations, the organizing team does not take responsibility for possible injuries that may occur.  However, the organizing team assumes the responsibility of ensuring all the necessary conditions for the event to be carried out in the safe conditions imposed by law. </i>

                </p>
            </div>
        </div>
        }
        </>
    )
}