// Here happens the logic of verifying compatibility and forwarding to service worker the message to register
// Approach: look at this file as 'USER'S DEVICE SIDE'
// Logic: this is a wrapper which makes life easier when trying to communicate with user's device. In this implementation
// we have wrapped the logic of 'user can use notification' from 'does push notifications work?' + 'does the API work?' +
// 'does the service worker work?' + etc. This way you can easily debug, maintain and build upon this logic.
// Overall: 'serviceWorker.js' stores sw manipulation logic, 'notificationService.js' stores
// device manipulation logic and 'notificationButton.jsx' stores the ui and event manipulation logic
import Swal from "sweetalert2";
import RequestService from "./universalService";
import config from '../Config/constants';

function urlB64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
	const rawData = atob(base64);
	const outputArray = new Uint8Array(rawData.length);
	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}

	return outputArray;
}

async function unregisterServiceWorker() {
	const registration = await navigator.serviceWorker.getRegistration();
	await registration.unregister();
}

function saveSubscription(subscription) {
	return new Promise((resolve, reject) => { // This is a promise to prevent 'Swal - successfully subscribed' message from 'notificationButton.jsx' while backend failing to save subscription.
		RequestService.ask('notifications.saveSubscription', subscription, (result, error) => {
			if (error) {
				reject(error);
			} else {
				resolve(result);
			}
		});
	});
}

function iosVersionSupport(version) {
	const userAgent = window.navigator.userAgent;
	const ios = userAgent.match(/iPad|iPhone|iPod/i);
	if (!ios) { // Not an iOS device
		return true;
	}

	const supported = getIOSVersion(userAgent) >= version;

	return supported ? true : false;
}

function getIOSVersion(userAgent) { // Returns the iOS version of the device
    const match = userAgent.match(/OS (\d+(_\d+)*)/i);
    return match ? match[1].replace('_', '.') : 'Unknown';
}

async function checkCompatibility() {
	if (!iosVersionSupport('16.4')) {
		console.error('iOS version is not supported!');
		return false;
	} else if (!('serviceWorker' in navigator)) {
		console.error('Service Worker not supported!');

		const show = localStorage.getItem('showNotificationHelper');
		if (show === 'false') {
			return false;
		}

		Swal.fire({ title: 'Service Worker not supported!', icon: 'error', showConfirmButton: false, timer: 2000 });
		return false;
	} else if (!('PushManager' in window)) {
		console.error('Push API not supported!');

		const show = localStorage.getItem('showNotificationHelper');
		if (show === 'false') {
			return false;
		}

		Swal.fire({
			title: 'To enable notifications, save web application to home page',
			html: 'Instructions: <a href="https://docs.google.com/document/d/10q4e4SjbhdP_XrVB_9P8FYyvf1dpFFK89PoqnIH11gk/edit">ios troubleshoot</a>',
			icon: 'info',
			showConfirmButton: true,
			didOpen: () => { // WARNING: This is a little bit bugged as it increments the 'swalCounter' multiple times for a single access
				const buttonId = 'stop-notification-message-button';
	
				// Check if the button already exists and if already shown at least 3 times(first 2 are getting ++ twice)
				if (!document.getElementById(buttonId)) {
					const swalCounter = localStorage.getItem('swalCounter') ? parseInt(localStorage.getItem('swalCounter'), 10) : 0;
					localStorage.setItem('swalCounter', swalCounter + 1);
	
					if (swalCounter > 5) {
						// Create a custom button element
						const customButton = document.createElement('button');
						customButton.id = buttonId;
						customButton.innerText = "Stop showing";
						customButton.className = 'swal2-confirm swal2-styled';
						
						// Add an event listener for the custom button
						customButton.addEventListener('click', () => {
							localStorage.setItem('showNotificationHelper', false);
							Swal.close();
						});
						
						// Insert the custom button into the Swal actions container
						const actions = Swal.getActions();
						actions.appendChild(customButton);
					}
				}
			}
		});

		return false;
	}

	return true;
}

async function requestNotificationPermission() {
    try {
        const permission = await window.Notification.requestPermission();
		return { success: true, message: permission };
    } catch (error) {
		return { success: false, message: error.message };
	}
}

async function registerServiceWorker() {
	try {
		const registration = await navigator.serviceWorker.register("/serviceWorker.js");

		if (registration.active) { // WARNING: here may be problems with the 'waiting' state of the sw, or it could be in other states while already registered or be in active state and need to save the subscription
			return { success: true };
		}

		const applicationServerKey = urlB64ToUint8Array(config.VAPID_PUBLIC_KEY);
		const options = { applicationServerKey, userVisibleOnly: true };
		
		const serviceWorker = await navigator.serviceWorker.ready;
		const subscription = await serviceWorker.pushManager.subscribe(options);
		await saveSubscription(subscription.toJSON());

		return { success: true };
	} catch (error) {
		return { success: false, message: error.message };
	}
}

// This is to manage the service worker
const ServiceWorkerService = {
		// Check if current device support push notifications
		isSupported: async () => {
			const supported = await checkCompatibility();
			if (!supported) {
				return false;
			}
	
			return true;
		},
	
		// Returns a message based on which are taken further actions
		isServiceWorkerRegistered: async () => {
			const permission = await requestNotificationPermission();
			if (!permission.success || permission.message !== 'granted') {
				unregisterServiceWorker();
				return permission.message;
			}
		
			const sw = await registerServiceWorker();
			if (!sw.success) {
				unregisterServiceWorker();
				console.error(sw);
				return sw.message;
			}
	
			return "success";
		},
	
		// Unregister from notifications
		unregisterNotifications: async () => {
			unregisterServiceWorker();
		},
	
};

// Use for building the 'NotificationButton' props data object (see 'Groups.jsx' and 'Brackets.jsx')
function templateBracket(where, whoID, whereID, subscribed, toggleSubscription) {
	return {
		eventInteractions: {
			who: undefined,
			what: undefined,
			where: where,
		},
		eventIdentifiers: {
			whoID: whoID,
			whereID: whereID,
		},
		subscribed: subscribed,
		toggleSubscription: toggleSubscription,
	};
};			

// Used for building the 'NotificationButton' props data object (see 'Groups.jsx')
function templateGroup(where, whoID, whereID, subscribed, toggleSubscription) {
	return {
		eventInteractions: {
			who: undefined,
			what: undefined,
			where: where,
		},
		eventIdentifiers: {
			whoID: whoID,
			whereID: whereID,
		},
		subscribed: subscribed,
		toggleSubscription: toggleSubscription,
	};
};

// Used for building the 'NotificationButton' props data object (see 'Livescore.jsx' and 'LiveCard.jsx')
// Common supports: team, match, competition.
function templateCommon(whoID, subscribed, toggleSubscription) {
	return {
		eventInteractions: {
			who: undefined,
			what: undefined,
			where: undefined,
		},
		eventIdentifiers: {
			whoID: whoID,
			whereID: undefined,
		},
		subscribed: subscribed,
		toggleSubscription: toggleSubscription,
	};
};

// Toggle on/off competition notification button
function toggleSubscriptionCompetition(setNotificationCompetition) {
	setNotificationCompetition(prevState => {
		const updateNotificationCompetitions = { ...prevState };
		updateNotificationCompetitions.subscribed = !updateNotificationCompetitions.subscribed;
		return updateNotificationCompetitions;
	});
};

// Toggle on/off bracket notification button
function toggleSubscriptionBracket(index, setNotificationBrackets) {
	setNotificationBrackets(prevState => {
		const updatedNotificationBrackets = [...prevState];
		updatedNotificationBrackets[index] = {
			...updatedNotificationBrackets[index],
			subscribed: !updatedNotificationBrackets[index].subscribed
		};
		return updatedNotificationBrackets;
	});
};

// Toggle on/off group notification button
function toggleSubscriptionGroup(index, setNotificationGroups) {
	setNotificationGroups(prevState => {
		const updatedNotificationGroups = [...prevState];
		updatedNotificationGroups[index] = {
			...updatedNotificationGroups[index],
			subscribed: !updatedNotificationGroups[index].subscribed
		};
		return updatedNotificationGroups;
	});
};

// Toggle on/off match notification button
function toggleSubscriptionMatch(index, setNotificationMatches) {
	setNotificationMatches(prevState => {
		const updatedNotificationMatches = [...prevState];
		updatedNotificationMatches[index] = {
			...updatedNotificationMatches[index],
			subscribed: !updatedNotificationMatches[index].subscribed
		};
		return updatedNotificationMatches;
	});
};

// Toggle on/off team notification button
function toggleSubscriptionTeam(index, teamIndex, setNotificationTeams) {
	setNotificationTeams(prevState => {
		const updatedNotificationTeams = [...prevState];
		updatedNotificationTeams[index] = {
			...updatedNotificationTeams[index],
			[teamIndex]: {
				...updatedNotificationTeams[index][teamIndex],
				subscribed: !updatedNotificationTeams[index][teamIndex].subscribed,
			}
		};
		return updatedNotificationTeams;
	});
};

// This is to manage the notification buttons
const NotificationService = {
	// Update competition notification button
	updateNotificationButtonCompetition: (competitionID, subscribers, setNotificationCompetition) => {
		const updateNotificationCompetition =
			templateCommon(
				competitionID,
				subscribers[0],
				() => toggleSubscriptionCompetition(setNotificationCompetition),
			);
		setNotificationCompetition(updateNotificationCompetition);
	},

	// Update brackets notification buttons
	updateNotificationButtonsBrackets: (where, brackets, competitionID, subscribers, setNotificationBrackets) => {
		const updatedNotificationBrackets = brackets.map((bracket, index) => {
			return (
				templateBracket(
					where,
					bracket, // Warning: here 'brackets' come pre-processed (as 'Semifinal 1', 'Semifinal 2', 'Final', 'Bronze'). This has been done because we want to store the 'whoID' as the message to be shown, and want to avoid having 'semifinal1' as message seen by user.
					competitionID,
					subscribers[index],
					() => toggleSubscriptionBracket(index, setNotificationBrackets),
				)
			);
		});
		setNotificationBrackets(updatedNotificationBrackets);
	},

	// Update groups notification buttons
	updateNotificationButtonsGroups: (where, groups, competitionID, subscribers, setNotificationGroups) => {
		const updatedNotificationGroups = groups.map((group, index) => {
			return (
				templateGroup(
					where,
					group.group, // Warning: here 'groups' come raw (as whole group object)
					competitionID,
					subscribers[index],
					() => toggleSubscriptionGroup(index, setNotificationGroups),
				)
			);
		});
		setNotificationGroups(updatedNotificationGroups);
	},

	// Update matches notification buttons
	updateNotificationButtonsMatches: (matchIDs, subscribers, setNotificationMatches) => {
		const updatedNotificationMatches = matchIDs.map((id, index) => {
			return (
				templateCommon(
					id,
					subscribers[index] || false,
					() => toggleSubscriptionMatch(index, setNotificationMatches),
				)
			);
		});
		setNotificationMatches(updatedNotificationMatches);
	},

	// Update teams notification buttons
	updateNotificationButtonsTeams: (matches, subscribers, leftTeamsLength, setNotificationTeams) => {
		const updatedNotificationTeams = matches.map((match, index) => {
			return [
				templateCommon(
					match.teams[0]._id,
					subscribers[index],
					() => toggleSubscriptionTeam(index, 0, setNotificationTeams)
				),
				templateCommon(
					match.teams[1]._id,
					subscribers[index + leftTeamsLength],
					() => toggleSubscriptionTeam(index, 1, setNotificationTeams)
				)
			];
		});
		setNotificationTeams(updatedNotificationTeams);
	},
};

export { ServiceWorkerService, NotificationService };
