import { Modal } from 'react-bootstrap';
import { useState } from 'react'
import './Partners.sass'
import esx from '../../images/parteneri/esx.svg'
import agon from '../../images/parteneri/agon.png'
import captive from '../../images/parteneri/captive.png'
import ursus from '../../images/parteneri/ursus.png'
import muzeu from '../../images/parteneri/muzeu.png'
import vmax from '../../images/parteneri/vmax.png'
import fizico from '../../images/parteneri/fizico.png'
import bronze from '../../images/medals/bronze.svg'
import silver from '../../images/medals/silver.svg'
import gold from '../../images/medals/gold.svg'
import diamond from '../../images/medals/diamond.svg'

const Partners = (props) => {
	const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const oficial = [];
    const partners = [
		{ logo: agon, medal: gold },
		{ logo: esx, medal: gold },
		{ logo: fizico, medal: gold },
		{ logo: vmax, medal: silver },
		{ logo: ursus, medal: silver },
		{ logo: muzeu, medal: silver },
		{ logo: captive, medal: bronze },
	];

    return (
		<section className='partners'>
			<div className='container-fluid text-center'>
				<div className='partner-box'>

					{
						oficial && oficial.length > 0 &&
						<div className='partners-container'>
							<div className='partner-heading'>
								<p className='title-partner'>
									Partener Oficial
								</p>
							</div>
							<div className='row logo-row align-items-center justify-content-center'>
							{oficial.map(logo =>
								<>
								<div className='logo-box'>
									<img src={logo} alt="" onClick={handleShow} className='partners-logo'/>
								</div>
								<Modal show={show} onHide={handleClose}>
										<Modal.Header>
											<Modal.Title style={{margin: "auto", fontSize: "2.5rem"}}>N-able</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<p style={{fontSize: "1.25rem"}}>
												N-able fuels IT services providers with powerful software solutions to monitor, manage and secure.
											</p>
											<a href='https://n-able.com' target='blank' style={{margin: "auto", color: "white"}}>Click here for more details!</a>
										</Modal.Body>
									<Modal.Footer>
										<button onClick={handleClose} className="btn btn-danger">Close</button>
									</Modal.Footer>
								</Modal>
								</>
									
							)}
							</div>
						</div>
					}

				</div>

				<div className='partner-box'>
					{
						partners && partners.length > 0  && 
						<div className='partners-container'>
							<div className='partner-heading'>
								<p className='title-partner'>
									Parteneri
								</p>
							</div>
							<div className='row logo-row align-items-center justify-content-center'>
							{partners.map(partner => 
									<div className='logo-box'>
										<img src={partner.logo} alt="crocodilul ti-a mancat logo-ul" className='partners-logo'/>
										<img src={partner.medal} alt="" className="partners-medal"/>
									</div>
							)}
							</div>
						</div>
					}
				</div>
				
			</div>
		</section>
    )
}

export default Partners
