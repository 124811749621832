import Timer from "../helpers/timer/Timer";
import "./Landing.sass";
import { useEffect } from "react";
import config from "../../Config/constants";

export default function Landing() {
  useEffect(() => {
    document.body.style.backgroundColor = "white";
    document.body.style.backgroundImage = "none";
    document.querySelector(".logo").style.display = "none";
    document.querySelector(".navbar").style.position = "fixed";
  	}, []);

  return (
	<div>
		<section className={"landing-banner"}>
		<img src="./logo-placeholder.svg" alt="" />
		<p className="landing-text">18-19 MAI</p>
		<Timer eventDate={config.EVENT_DATE} />
		</section>
	</div>
  );
}