import Input from "../../input/Input";
import Button from "../../buttons/button/Button";
import { useEffect, useState } from "react";
import validate from "../../../../services/validator";
import RequestService from "../../../../services/universalService";
import Swal from "sweetalert2";
import '../Register.sass';

export default function RegisterParticipant() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [signStatus, setSignStatus] = useState("Înregistrare");
	const university = "PARTICIPANT";

    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [passError, setpassError] = useState("");
    const [emailError, setemailError] = useState("");

    const handlelastName = (value) => {
        if (value.length < 48) {
            setLastName(value);
        }
    }
    const handlefirstName = (value) => {
        if (value.length < 72) {
            setFirstName(value);
        }
    }
    const handleEmail = (value) => {
        setEmail(value);
    }
    const handlePassword = (value) => {
        setPassword(value);
    }
    const handleConfirmPassword = (value) => {
        setConfirmPassword(value);
    }

    const sendRegisterRequest = async () => {
        const validateFirstName = validate.validate(firstName, "name", [
            { restrictionType: "minlen", value: "2" },
            { restrictionType: "maxlen", value: "72" },
            { restrictionType: "required" }
        ])

        if (!validateFirstName.valid) {
            setFirstNameError("Nume invalid")
            return;
        }

        const validateLastName = validate.validate(lastName, "name", [
            { restrictionType: "minlen", value: "2" },
            { restrictionType: "maxlen", value: "48" },
            { restrictionType: "required" }
        ])

        if (!validateLastName.valid) {
            setLastNameError("Nume invalid")
            return;
        }

        const validatePass = validate.validate(password, "password", [
            { restrictionType: "minlen", value: "8" },
            { restrictionType: "maxlen", value: "30" },
            { restrictionType: "required" }
        ])

        const validateEmail = validate.validate(email, "email", [
            { restrictionType: "required" },
        ])

        if (!validateEmail.valid) {
            setemailError("Email-ul trebuie sa fie cel institutional");
            return;
        } else {
            setemailError("");
        }

        if (password !== confirmPassword) {
            setpassError("Parolele nu coincid");
            return;
        }
        else if (!validatePass.valid) {
            setpassError("Minim 8 caractere");
            return;
        } else {
            setpassError("");
        }

        const data = {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: email,
            university: university,
			role: "PARTICIPANT",
        }
        if (validateFirstName.valid && validateLastName.valid && validateEmail.valid && validatePass.valid && password === confirmPassword) {
			await RequestService.ask("auth.register", data, function (result, error) {
                let textError;
                if (error) {
                    if (error.response.status === 410) {
                        textError = "Mailul de confirmare a fost deja trimis";
                    }
                    else if (error.response.status === 409) {
                        textError = "Numarul de telefon este deja inregistrat";
                    }
                    else if (error.response.status === 400) {
                        textError = "Emailul este deja inregistrat";
                    }
                }

                if (textError) {
					console.log(result, error);
                    Swal.fire({
                        icon: 'error',
                        title: textError,
                    })
                } else {
					setSignStatus("Check your email!");
					Swal.fire({
						icon: 'success',
						title: 'Check your email!',
					})
				}
            })
        }
    }

    useEffect(() => {
        setFirstNameError("")
        setLastNameError("")
        setemailError("");
        setpassError("");
        setSignStatus("Înregistrare");
    }, [firstName, lastName, email, password, confirmPassword])

    const handleRegisterSubmit = (e) => {
        e.preventDefault()
        sendRegisterRequest();
    }

	return (
		<form action="/" method="post" className="register-form" onSubmit={handleRegisterSubmit}>
			<div className="registerformBody">
				<h1 className="textReg mb-5">Înregistrare</h1>

				<Input type={"text"} name={"firstName"} ColoScheme={"reverse"} placeholder={"Nume"} icon={"fa-solid fa-user"} defaultText={firstName} onChange={handlefirstName} style={{ width: '100%' }} />
				{firstNameError && <span class="alert-color">{firstNameError}</span>}

				<Input type={"text"} name={"lastName"} ColoScheme={"reverse"} placeholder={"Prenume"} icon={"fa-solid fa-user"} defaultText={lastName} onChange={handlelastName} style={{ width: '100%' }} />
				{lastNameError && <span class="alert-color">{lastNameError}</span>}

				<Input type={"email"} name={"email"} ColoScheme={"reverse"} placeholder={"email"} icon={"fa-regular fa-envelope"} defaultText={email} onChange={handleEmail} style={{ width: '100%' }} />
				{emailError && <span class="alert-color">{emailError}</span>}

				<Input type={"password"} name={"password"} ColoScheme={"reverse"} placeholder={"Parolă"} icon={"fa-solid fa-key"} defaultText={password} onChange={handlePassword} style={{ width: '100%' }} />
				{passError && <span class="alert-color">{passError}</span>}

				<Input type={"password"} name={"confirmPassword"} ColoScheme={"reverse"} placeholder={"Confirmă parola"} icon={"fa-solid fa-key"} defaultText={confirmPassword} onChange={handleConfirmPassword} style={{ width: '100%' }} />
				{passError && <span class="alert-color">{passError}</span>}
			</div>
			<Button name={"register"} isDisabled={signStatus === "Verifică-ți mail-ul!"} type={"submit"} text={signStatus} style={{ background: "#009494", color: "#FFFFFF", marginTop: "1.5rem", marginBottom: ".5rem" }} onClick={() => {}}/>
		</form>
	);
}

