import { Col, Container, Row } from "react-bootstrap";
import "./BracketHelper.sass";
import Match from "../Match/Match";
import NotificationButton from "../../Notification/notificationButton/NotificationButton";

export default function Bracket(props) {
	const { semifinal1, semifinal2, final, bronze, supported, notificationBrackets } = props.data;

	return (
		<Container className="bracketsHelper">
			<div className="bracketsHelperTitle">Brackets</div>
			<Row className="bracketsHelperGap">
				<Col className="col-12 col-lg-3 col-xl-3">
					<Container className="p-0">
						<Row className="gy-5 flexx gap-4">
							<Col className="col-5 col-lg-12 col-xl-12 bracket">
								<Match match={semifinal1} />
								<div className="bracketType">
									Semifinal
									{notificationBrackets && notificationBrackets[0] &&
										<NotificationButton
											{...notificationBrackets[0]}
											supported={supported}
											swalMessage={'this bracket events'}
											style={{ maxWidth: '.8rem', minWidth: '.6rem' }}
											color={'rgba(0, 0, 0, 1)'}
										/>
									}
								</div>
							</Col>
							<Col className="col-5 col-lg-12 col-xl-12 bracket">
								<Match match={semifinal2} />
								<div className="bracketType">
									Semifinal
									{notificationBrackets && notificationBrackets[1] &&
										<NotificationButton
											{...notificationBrackets[1]}
											supported={supported}
											swalMessage={'this bracket events'}
											style={{ maxWidth: '.8rem', minWidth: '.6rem' }}
											color={'rgba(0, 0, 0, 1)'}
										/>
									}
								</div>
							</Col>
						</Row>
					</Container>
				</Col>
				<div className="none" />
				<Col className="flexy col-12 col-lg-4 col-xl-4 final bracket">
					<div className="bracketHelperContainer">
						<Match match={final} />
						<div className="bracketType">
							Final
							{notificationBrackets && notificationBrackets[2] &&
								<NotificationButton
									{...notificationBrackets[2]}
									supported={supported}
									swalMessage={'this bracket events'}
									style={{ maxWidth: '.8rem', minWidth: '.6rem' }}
									color={'rgba(0, 0, 0, 1)'}
								/>
							}
						</div>
					</div>
				</Col>
				<Col className="flexy col-12 col-lg-4 col-xl-4 bronze bracket">
					<div className="bracketHelperContainer">
						<Match match={bronze} />
						<div className="bracketType">
							Bronze
							{notificationBrackets && notificationBrackets[3] &&
								<NotificationButton
									{...notificationBrackets[3]}
									supported={supported}
									swalMessage={'this bracket events'}
									style={{ maxWidth: '.8rem', minWidth: '.6rem' }}
									color={'rgba(0, 0, 0, 1)'}
								/>
							}
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
}