import React from "react";
import Button from "../buttons/button/Button"
import "./Card.sass";

const CardElem = ({ text, textBtn, nameBtn, image, seeAnswer }) => {
  return (
    <div className="cardContainer">
	  <div className="cardTop">
      	<img className="cardImg" src={image} />
        <p className="cardText" children={text}/>
	  </div>
	  <div className="cardBottom">
		<Button className="cardBtn" onClick={seeAnswer} name={nameBtn} type={"submit"} text={textBtn} style={{background: "#009494", color: "#FFFFFF"}} />
	  </div>
    </div>
  );
};

export default CardElem;
