import "./Sporturi.sass"
export default function StreetBall({language}) {
    return (
        <>
        {language === "RO" ? 
            <div className="sportRuleSet">
            <div className="sportRule">
                <h4>
                    Competiția se desfășoară conform <a href="https://www.frbaschet.ro/public/storage/pages/Regulamente/05-Regulamentul%20Oficial%20al%20Jocului%20de%20Baschet%203x3.pdf">regulamentului oficial Fiba 3x3</a>, cu următoarele mențiuni:
                </h4>
                <ul>
                    <li>
                        Fiecare echipă va avea în componență 4 jucători (3 pe teren și o rezervă);
                    </li>
                    <li>
                        Echipa din teren va fi formată în permanență din 3 jucători;
                    </li>
                    <li>
						Schimbările de jucători sunt permise oricărei echipe în orice situație de minge moartă, înaintea reluării jocului sau a eventualelor aruncări libere. Pentru schimbări nu este nevoie de acordul arbitrilor sau al oficialilor.
                    </li>
                </ul>
                <h4>
                    Începutul jocului
                </h4>
                <ul>
                    <li>
                        Cele două echipe se vor încălzi simultan înainte de startul jocului.
                    </li>
                    <li>
                        Jocul va începe cu o tragere la sorți. Echipa care va câștiga tragerea la sorți va putea alege să aibă posesia la începutul jocului sau la începutul eventualelor prelungiri.    
                    </li>
                </ul>
                <h4>
                    Scorul
                </h4>
                <ul>
                    <li>
                        Pentru fiecare aruncare reușită din interiorul semicercului se va acorda 1 (un) punct. 
                    </li>
                    <li>
                        Pentru fiecare aruncare reușită din exteriorul semicercului se vor acorda 2 (două) puncte.
                    </li>
                    <li>
                        Pentru fiecare aruncare liberă reușita se va acorda 1 (un) punct.
                    </li>
                </ul>
                <h4>
                    Timpul de joc/Echipa câștigătoare
                </h4>
                <ul>
                    <li>
                        Durata unui joc este de 10 (zece) minute, timp efectiv. Timpul de joc se va opri în situațiile de minge moartă și în timpul aruncărilor libere. Timpul de joc va reporni după un schimb de minge („check-ball”) cu unul dintre jucătorii echipei aflate în apărare, atunci când mingea ajunge în mâinile unui jucător de la echipa aflată în atac. 
                    </li>
                    <li>
                        Totuși, echipa care va marca prima 21 (douăzeci și unu) puncte sau mai multe înainte de expirarea timpului de joc va fi desemnată câștigătoarea jocului. Această regulă se aplică doar în cadrul timpului regulamentar de joc (nu și în timpul eventualelor prelungiri). 
                    </li>
                    <li>
                        În cazul în care, la sfârșitul celor 10 (zece) minute, scorul este egal, se va juca, după o pauză de 1 (un) minut, o repriză de prelungiri. Echipa care va avea prima avans de două puncte va câștiga jocul.    
                    </li>
                    <li>
                        O echipă care nu este prezentă pe teren cu minim 3 (trei) jucători la startul jocului va pierde prin <b>neprezentare</b> („forfeit”). Scorul înregistrat va fi de w-0 sau 0-w în favoarea echipei prezente pe teren în formație validă (w = win = victorie = 3 puncte).  
                    </li>
                    <li>
                        O echipă va pierde în mod automat („default”) dacă părăsește terenul înaintea sfârșitului jocului sau dacă toți jucătorii acelei echipe sunt accidentați sau descalificați. În această situație, echipa câștigătoare poate alege să-și păstreze scorul înregistrat la momentul opririi jocului (punctele marcate) sau să câștige prin Neprezentare(7 - 0), în timp ce echipa care pierde prin <b>descalificare</b> va avea punctele marcate resetate la 0 (zero).
                    </li>
                    <li>
                        O echipă care pierde în mod automat (vezi punctul de deasupra) sau <b>neprezentare</b> comisă cu intenția de a crea un dezavantaj altei echipe („tortuous forfait”) va fi descalificată din competiție.  
                    </li>
                </ul>
                <h4>
                    Greșeli/Aruncări libere
                </h4>
                <ul>
                    <li>
                        O echipă este în situație de penalizare când a acumulat 6 (șase) greșeli de echipă. Jucătorii nu sunt eliminați din joc pe baza numărului de greșeli personale.
                    </li>
                    <li>
                        Sancțiunea pentru o greșeală personală din timpul unei acțiuni de aruncare la coș din interiorul semicercului este de 1 (o) aruncare liberă. Sancțiunea pentru o greșeală personală din timpul unei acțiuni de aruncare la coș din exteriorul semicercului este de 2 (două) aruncări libere. 
                    </li>
                    <li>
                        Sancțiunea pentru o greșeală personală din timpul unei acțiuni de aruncare la coș, finalizată cu un coș valabil, este de 1 (o) aruncare liberă și echipa care a înscris obține și punctul din cadrul fazei finalizate cu fault.
                    </li>
                    <li>
                        Greșelile de echipă 7, 8 și 9 vor fi urmate de 2 (două) aruncări libere în orice situație. Începând cu a 10-a greșeală de echipă, toate greșelile personale vor fi urmate de 2 (două) aruncări libere și posesia.
                    </li>
                    <li>
                        Sancțiunea pentru o greșeală tehnică este de 1 (o) aruncare liberă și posesia.
                    </li>
                    <li>
                        Sancțiunea pentru o greșeală antisportivă sau descalificatoare este de 2 (două) aruncări libere și posesia. 
                    </li>
					<li>
						Nicio aruncare liberă nu va fi acordată ca urmare a unei greșeli personale în atac.
					</li>
                </ul>
                <h4>
                    Modul de joc
                </h4>
                <ul>
                    <li>
                        După fiecare coș reușit sau ultima/singura aruncare liberă reușită (cu excepția situațiilor când jocul se reia cu acordarea posesiei mingii):  
                        <ul>
                            <li>
                                Un jucător de la echipa care nu a marcat va relua jocul prin dribling sau pasă către zona din exteriorul semicercului. După ce mingea a ajuns în zona din exteriorul semicercului, echipa aflată în posesie poate ataca coșul (fără „check ball”).  
                            </li>
                            <li>
                                Echipa în apărare nu poate ataca mingea în interiorul semicercului „no-charge” de sub coș, acesta fiind zona de protecție a echipei aflate în atac.  
                            </li>
                        </ul>
                    </li>
                    <li>
                        După fiecare aruncare ratată sau ultima/singura aruncare liberă ratată (cu excepția situațiilor când jocul se reia cu acordarea posesiei mingii):   
                        <ul>
                            <li>
                                În cazul în care echipa aflată în atac recuperează mingea, aceasta poate ataca coșul fără a mai scoate mingea în exteriorul semicercului;  
                            </li>
                            <li>
                            	În cazul în care echipa aflată în apărare recuperează mingea, aceasta trebuie să scoată mingea în zona din exteriorul semicercului (prin dribling sau prin pas la coechipier).  
                            </li>
                        </ul>
                    </li>
                    <li>
                        În cazul în care echipa aflată în apărare interceptează mingea, aceasta trebuie să scoată mingea în zona din exteriorul semicercului (prin dribling sau prin pas la coechipier).  
                    </li>
                    <li>
                        După fiecare situație de minge moartă, jocul va fi reluat de către echipa îndreptățită la următoarea posesie, din zona din exteriorul semicercului, de pe centrul terenului, printr-un schimb de minge („check-ball”) cu unul dintre jucătorii echipei aflate în apărare.  
                    </li>
                    <li>
                        Un jucător este considerat în exteriorul semicercului când nici unul dintre picioarele sale nu este în interiorul semicercului sau calcă pe semicerc.  
                    </li>
                    <li>
                        În cazul unei situații de 1 la 1 („jump ball”), mingea va reveni echipei aflate în apărare.
                    </li>
                </ul>
				<h4>
					Tragere de timp
				</h4>
					<li>
						Practicarea jocului pasiv sau întârzierea deliberată a jocului vor fi considerate abateri.
					</li>
					<li>
						Un jucător care săvârșește două greșeli antisportive va fi descalificat din joc de către arbitru și poate fi eliminat din competiție de către organizator. Independent de aceasta, organizatorul poate descalifica un jucător din competiție pentru acte de violență, agresiune fizică sau verbală, sau pentru orice formă de interferență nesportivă care afectează rezultatul meciului. De asemenea, organizatorul poate decide să excludă întreaga echipă din competiție în funcție de contribuția celorlalți jucători.
					</li>
                <h4>
                    Timpii de odihnă - „Time-out”-uri  
                </h4>
                <ul>
                    <li>
                        Fiecare echipă va beneficia de un „Time-out” în timpul unui meci. Orice jucător poate solicita „Time-out” atunci când jocul este oprit.
                    </li>
                    <li>
                        Durata unui „Time-out” este de 30 (treizeci) de secunde. 
                    </li>
                </ul>
                <h4>
                    Structura competiției
                </h4>
                <ol>
                    <li>
                        Faza grupelor:
                        <ul>
                            <li>
                                4 grupe 
                            </li>
                            <li>
                                3 grupe a câte 4 echipe și o grupă de 3 echipe
                            </li>
                            <li>
                                va trece mai departe prima echipă clasată din fiecare grupă
                            </li>
                            <li>
                                punctajul pentru grupe, pe baza căruia se va decide cine trece în faza următoare este:
                                <ul>
                                    <li>
                                        Meci castigat - 3p
                                    </li>
                                    <li>
                                        Meci pierdut - 0p
                                    </li>
                                    <li>
                                        Un meci nu se poate termina la egalitate
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Semifinale: meciuri eliminatorii (se joacă un singur meci); 
                    </li>
                    <li>
                        Finala mică și finala mare: meciuri eliminatorii (se joacă un singur meci).
                    </li>
                </ol>
                <h4>
                    Criteriile de departajare ale echipelor
                </h4>
                <div>
                    În caz de egalitate de puncte a doua sau mai multe echipe în faza grupelor, se aplică următoarele criterii de departajare:
                    <br/>Faza grupelor: 
                    <ul>
                        <li>
                            În caz de egalitate la puncte dintre 2 echipe după jocurile din faza grupelor, vor intra în vigoare următoarele criterii: 
                            <ol>
                                <li>
                                    Rezultatul direct. 
                                </li>
                                <li>
                                    Coșaverajul general.
                                </li>
                                <li>
                                    Numărul mai mare de puncte marcate.
                                </li>
                                <li>
                                    Numărul mai mic de puncte primite.
                                </li>
                            </ol>
                        </li>
                        <li>
                            În caz de egalitate de puncte între 3 sau 4 echipe după jocurile din faza grupelor, vor intra în vigoare următoarele criterii de departajare:
                            <ol>
                                <li>
                                    Se va întocmi un clasament doar cu rezultatele dintre cele 3 sau 4 formații aflate la egalitate de puncte și se va califica echipa cu cele mai multe puncte adunate doar din jocurile cu echipele aflate la egalitate de puncte.
                                </li>
                                <li>
                                    Coșaverajul general din jocurile cu celelalte echipe aflate la egalitate de puncte.
                                </li>
                                <li>
                                    Numărul mai mare de puncte marcate în jocurile cu celelalte echipe aflate la egalitate de puncte.
                                </li>
                                <li>
                                    Numărul mai mic de puncte primite în jocul cu celelalte echipe aflate la egalitate de puncte.
                                </li>
                                <li>
                                    Coșaverajul general în toate jocurile din grupă.
                                </li>
                                <li>
                                    Numărul mai mare de puncte marcate în toate jocurile din grupă.
                                </li>
                                <li>
                                    Numărul mai mic de puncte primite în toate jocurile din grupă.
                                </li>
                            </ol>
                        </li>
                    </ul>
                </div>
                <h4>
                    Punctaj Clasament
                </h4>
                <div>
                    Vor primi puncte pentru performanță în cadrul competiției:
                    <ul>
                        <li>
                            100 puncte - locul 1
                        </li>
                        <li>
                            75 puncte - locul 2
                        </li>
                        <li>
                            50 puncte - locul 3
                        </li>
                        <li>
                            25 puncte - locul 4
                        </li>
                    </ul>
                </div>
				<p>
					<i>Menționăm faptul că echipa de organizare își rezervă dreptul de a modifica prezentul regulament în cazul apariției unor situații neprevăzute.</i>
				</p>
				<p>
					<i>De asemenea, fiecare participant în momentul în care se înscrie la evenimentul Poli Olympics își asumă riscurile unor eventuale accidentări venite din situații mai mult sau mai puțin sportive, echipa de organizare neasumându-și responsabilitatea pentru posibilele accidentări ce ar putea surveni. Totuși, echipa de organizare își asumă responsabilitatea asigurării tuturor condițiilor necesare desfășurării evenimentului în condițiile de siguranță impuse prin lege.</i>
				</p>
            </div>
        </div> :
            // ------------------------------------------- Engleza -------------------------------------------
            <div className="sportRuleSet">
            <div className="sportRule">
                <h4>
                        The competition is held according to <a href="https://www.frbaschet.ro/public/storage/pages/Regulamente/05-Regulamentul%20Oficial%20al%20Jocului%20de%20Baschet%203x3.pdf">the official Fiba 3x3 regulation </a>,  with the following mentions:
                </h4>
                <ul>
                    <li>
                        Each team will consist of 4 players (3 on the field and one reserve); 
                    </li>
                    <li>
                        The field team will be permanently made up of 3 players; 
                    </li>
                    <li>
                            Player changes are allowed to any team in any dead ball situation, prior to the resumption of play or possible free throws.  No referees’ consent is required for changes 
                    </li>
                </ul>
                <h4>
                    The beginning of the game
                </h4>
                <ul>
                    <li>
                        The two teams will warm up simultaneously before the start of the game. 
                    </li>
                    <li>
                        The game will start with a draw. The team that wins the draw will be able to choose to have possession at the beginning of the game or at the beginning of any extra time. 
                    </li>
                </ul>
                <h4>
                    Score 
                </h4>
                <ul>
                    <li>
                        For each successful roll inside the semi-circle, 1 (one) point will be awarded. 
                    </li>
                    <li>
                        For each successful throw from outside the semi-circle, 2 (two) points will be awarded. 
                    </li>
                    <li>
                        For each successful free throw, 1 (one) point will be awarded. 
                    </li>
                </ul>
                <h4>
                    Playing Time / Winning Team 
                </h4>
                <ul>
                    <li>
                        The duration of a game is 10 (ten) minutes, effective time. Playing time will stop in dead ball situations and during free throws.  Playing time will restart after a check-ball with one of the defending team's players when the ball ends up in the hands of a player from the attacking team.
                    </li>
                    <li>
                        However, the team that will score the first 21 (twenty-one) points or more before the expiry of the playing time will be designated the winner of the game.  This rule only applies during regular playing time (not during any extra time).
                    </li>
                    <li>
                        If, at the end of the 10 (ten) minutes, the score is tied, a half of extra time will be played after a break of 1 (one) minute.  The team that will have the first two-points lead will win the game. 
                    </li>
                    <li>
                        A team that is not present on the field with a minimum of 3 (three) players at the start of the game will lose by no-show ("forfeit").  The score recorded will be w-0 or 0-w in favor of the team present on the field in a valid formation (w = win = win = 3 points).  
                    </li>
                    <li>
                        A team will automatically lose ("default") if it leaves the field before the end of the game or if all of that team's players are injured or disqualified. In this situation, the winning team may choose to keep their score recorded at the time of stopping play (points scored) or win by No-show(7 - 0), while the team losing by disqualification will have their points scored reset to 0 (zero). 
                    </li>
                    <li>
                        A team that automatically loses (see point above) or no-show committed with the intention of creating a disadvantage for another team ("tortuous forfeit") will be disqualified from the competition. 
                    </li>
                </ul>
                <h4>
                    Mistakes / Free Throws 
                </h4>
                <ul>
                    <li>
                        A team is in a penalty situation when it has accumulated 6 (six) team mistakes. Players are not eliminated from the game based on the number of personal mistakes 
                    </li>
                    <li>
                        The penalty for a personal mistake during a throwing action inside the semi-circle is 1 (one) free throw. The penalty for a personal mistake during a basket throwing action from outside the semi-circle is 2 (two) free throws. 
                    </li>
                    <li>
                        The penalty for a personal mistake during a throwing action, completed with a valid basket, is 1 (one) free throw and the team that scored also gets the point in the final foul phase.
                    </li>
                    <li>
                        Team mistakes 7, 8 and 9 will be followed by 2 (two) free throws in any situation. Starting with the 10th team mistake, all personal mistakes will be followed by 2 (two) free throws and possession. 
                    </li>
                    <li>
                        The penalty for a technical mistake is 1 (one) free throw and possession.
                    </li>
                    <li>
                        The penalty for an anti-sporting or disqualifying mistake is 2 (two) free throws and possession.
                    </li>
                </ul>
                <h4>
                    Gameplay
                </h4>
                <ul>
                    <li>
                        After each successful throw or the last/only successful free throw (except when game resumes with the hand taking possession of the ball):
                        <ul>
                            <li>
                                A player from the team that has not scored will resume play by dribbling or passing to the area outside the semicircle.  After the ball has reached the area outside the semi-circle, the team in possession can attack the basket (without the "check ball"). 
                            </li>
                            <li>
                                The defending team cannot attack the ball inside the "no-charge" semi-circle under the basket, which is the protection zone of the attacking team.
                            </li>
                        </ul>
                    </li>
                    <li>
                        After each missed throw or the last/only missed free throw (except when play resumes with possession of the ball):
                        <ul>
                            <li>
                                If the attacking team recovers the ball, it can attack the basket without removing the ball outside the semi-circle;
                            </li>
                            <li>
                                If the defending team recovers the ball, it must remove the ball to the area outside the semi-circle (by dribbling or by stepping at the teammate). 
                            </li>
                        </ul>
                    </li>
                    <li>
                        If the defending team intercepts the ball, it must take the ball out into the area outside the semi-circle (by dribbling or by stepping at the teammate). 
                    </li>
                    <li>
                        After each dead ball situation, play will be resumed by the team entitled to the next possession, from the area outside the semi-circle, from the center of the pitch, by exchanging a ball ("check-ball") with one of the team's players in defense.
                    </li>
                    <li>
                        A player is considered outside the semicircle when none of his legs are inside the semicircle or step on the semi-circle. 
                    </li>
                    <li>
                        In the event of a 1-on-1 situation ("jump ball"), the ball will return to the defending team. 
                    </li>
                </ul>
                <h4>
                    Rest times - "Time-outs" 
                </h4>
                <ul>
                    <li>
                        Each team will benefit from a "Time-out" during a match
                    </li>
                    <li>
                        The duration of a "Time-out" is 30 (thirty) seconds. 
                    </li>
                    <p>
                        Any player can request "Time-out" when the game is stopped. 
                    </p>
                </ul>
                <h4>
                    Structure of the competition
                </h4>
                <p>
                    Competiția de Streetball este structurată în felul următor:
                </p>
                <ol>
                    <li>
                        <b>Groups stage:</b>
                        <ul>
                            <li>
                                4 groups 
                            </li>
                            <li>
                                3 groups of 4 teams and a group of 3 teams 
                            </li>
                            <li>
                                the first ranked team in each group will pass on
                            </li>
                            <li>
                                The group score, on the basis of which it will be decided who passes in the next phase is: 
                                <ul>
                                    <li>
                                        Match won - 3p
                                    </li>
                                    <li>
                                        Match lost - 0p
                                    </li>
                                    <li>
                                        You can't end a match in a tie 
                                    </li>
                                </ul> 
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Semi-finals:</b> knockout matches (only one match is played); 
                    </li>
                    <li>
                        <b>Small final and grand final:</b> knockout matches (only one match is played). 
                    </li>
                </ol>
                <h4>
                    Team tiebreakers
                </h4>
                <div>
                    In the event of a tie for second or more teams in the group stage, the following tiebreaker criteria shall apply: 
                    <br/>Groups stage: 
                    <ul>
                        <li>
                            In case of a tie for points between 2 teams after the games in the group stage, the following criteria will come into force:
                            <ol>
                                <li>
                                    The direct result. 
                                </li>
                                <li>
                                    Overall number of points scored
                                </li>
                                <li>
                                    The higher number of points scored. 
                                </li>
                                <li>
                                    Lower number of points received. 
                                </li>
                            </ol>
                        </li>
                        <li>
                            In case of a tie for points between 3 or 4 teams after the games in the group stage, the following criteria for tiebreaker will come into force: 
                            <ol>
                                <li>
                                    A ranking will be drawn up only with the results of the 3 or 4 formations on equal points  and will qualify the team with the most points gathered only from games with teams in a tie for points. 
                                </li>
                                <li>
                                    The overall number of points scored in games with the other teams in a tie for points. 
                                </li>
                                <li>
                                    The higher number of points scored in games with the other teams in a tie for points. 
                                </li>
                                <li>
                                    The lower number of points received in the game with the other teams in a tie for points. 
                                </li>
                                <li>
                                    The overall number of points scored in all the games in the group.
                                </li>
                                <li>
                                    Higher number of points scored in all group games.
                                </li>
                                <li>
                                    The lower number of points received in all group games. 
                                </li>
                            </ol>
                        </li>
                    </ul>
                </div>
                <h4>
                    Overall Ranking Score
                </h4>
                <div>
                    The teams will receive points for performance in the competition:
                    <ul>
                        <li>
                            100 points - 1st place
                        </li>
                        <li>
                            75 points -  2nd place
                        </li>
                        <li>
                            50 points -  3rd place
                        </li>
                        <li>
                            25 points -  4th place
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        }
    </>)
}
