import './Game.sass'

const Game = ({ match }) => {
    const set = match.sport === 'VOLLEYBALL' || match.sport === 'PINGPONG' ? match.scores[0] + match.scores[1] : 0;

    return (
        <section className='game'>
			{match.status === 'LIVE' && 
				<div className='game-live'>LIVE</div>
			}
			<div className='game-content'>
				<div className='game-content-teams game-content-left'>
					<div className='game-content-teams-team'>
						{match.teams[0] ? match.teams[0].faculty : "TBD"} 
					</div>

					<div className='game-content-teams-team'>
						{match.teams[1] ? match.teams[1].faculty : "TBD"}
					</div>
				</div>

				<div className='game-content-right'>
					<div className='game-content-date'>
						{match.date}
					</div>

					<div className='game-content-score'>
						<div className='game-content-score-text'>
							{match.status !== 'UPCOMING' ? 
							<>
								{match.sport === 'VOLLEYBALL' || match.sport === 'PINGPsONG' ?
									<>
										<span>
											<div className='game-content-score-text-row'>
												<span className='game-content-score-text-sets'>{match.scores[0]}</span> | <span className="game-content-score-text-sets">{match.partialScores.map((score) => score[0]).join(' ')}</span>
											</div>
											<div className='game-content-score-text-row'>
												<span className='game-content-score-text-sets'>{match.scores[1]}</span> | <span className="game-content-score-text-sets">{match.partialScores.map((score) => score[1]).join(' ')}</span>
											</div>
										</span>
									</> 
									: 
									<span className="game-content-score-text-match">
										{match.scores[0]} - {match.scores[1]}
									</span>
								}
							</> 
								:
							<span className = "game-content-score-text-match">
								{match.scores[0]}  -  {match.scores[1]}
							</span>
							}
						</div>
					</div>
				</div>
			</div>
        </section>
    )
}

export default Game
