import './Modal.sass';
import { Modal } from 'react-bootstrap';
import languageIcon from "../../../images/language.svg";
import exitIcon from "../../../images/X.svg";

const ModalCustom = ({ title, text, component, onExit, show, language, handleLanguage }) => {
  const handleClose = () => {
    onExit();
  };

  let modalContent;
  if (component) {
	modalContent = component;
  } else if (text) {
	modalContent = <p className="modalText" children={text} />;
  } else {
	modalContent = <p className="modalText" children={"No content found"} />;
  }

  return (
    <Modal centered="yes" show={show} onHide={handleClose}>
	  <Modal.Header>
	  	<button className="exitModalBtn" onClick={handleClose}>
			<img className="exitModalIcon" src={exitIcon} alt="Ti-a cazut satelitul"/>
		</button>
        <Modal.Title>
			{title}
		</Modal.Title>
		<div className="wrapperLanguage">
			{/* <button className={`languageBtn ${language ? '' : 'languageBtnHide' }`} onClick={handleLanguage}>
				<img className="languageIcon" src={languageIcon} alt="Ti-a cazut satelitul" />
				<div className="languageText">
					{language}
				</div>
			</button> */}
		</div>
	  </Modal.Header>
      <Modal.Body>{modalContent}</Modal.Body>
    </Modal>
  );
};

export default ModalCustom;
