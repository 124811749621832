import "./Sloth.sass";

export default function Sloth({ className, component, image, style }) {
	let content;
	if (component) {
		content = component;
	} else {
		content = <img className="slothImage" src={image} alt="sloth" />;
	}

	return (
        <div className={`slothContainer ${className}`} style={style}>
			{content}
        </div>
    );
}
