import FIIR from '../../../images/faculties/FIIR/FIIR'
import FIE from '../../../images/faculties/FIE/FIE'
import ETTI from '../../../images/faculties/ETTI/ETTI'
import ACS from '../../../images/faculties/ACS/ACS'
import AERO from '../../../images/faculties/AERO/AERO'
import TRANSPORT from '../../../images/faculties/TRANSPORT/TRANSPORT'
import CHIMIE from '../../../images/faculties/CHIMIE/CHIMIE'
import SIM from '../../../images/faculties/SIM/SIM'
import FAIMA from '../../../images/faculties/FAIMA/FAIMA'
import FILS from '../../../images/faculties/FILS/FILS'
import FSA from '../../../images/faculties/FSA/FSA'
import ENERG from '../../../images/faculties/ENERG/ENERG'
import ISB from '../../../images/faculties/ISB/ISB'
import FIM from '../../../images/faculties/FIM/FIM'
import FIMM from '../../../images/faculties/FIMM/FIMM'
import UPIT from '../../../images/faculties/UPIT/UPIT'
import './LiveCard.sass';
import NotificationButton from '../../Notification/notificationButton/NotificationButton'

const mapLogos = {
  FIIR_logo: FIIR,
  FIE_logo: FIE,
  ETTI_logo: ETTI,
  ACS_logo: ACS,
  AERO_logo: AERO,
  TRANSPORT_logo: TRANSPORT,
  CHIMIE_logo: CHIMIE,
  SIM_logo: SIM,
  FAIMA_logo: FAIMA,
  FILS_logo: FILS,
  FSA_logo: FSA,
  ENERG_logo: ENERG,
  ISB_logo: ISB,
  FIM_logo: FIM,
  FIMM_logo: FIMM,
  UPIT_logo: UPIT,
};

const LivescoreCard = ({ match, supported, notificationMatch, notificationTeam }) => {
  const showSets = match.sport === 'PINGPONG' || match.sport === 'VOLLEYBALL';

  return (
    <div className="card livescore-card border-0 py-1">
      <div className="livescore-card-title-wrapper text-center fw-bold fs-4">
        {match.sport}
		{notificationMatch && notificationMatch.eventInteractions &&
			<NotificationButton
				{...notificationMatch}
				supported={supported}
				swalMessage={'this match events'}
			/>
		}
      </div>

      <div className="card-header text-white d-flex align-items-center justify-content-between px-2">
        <div className="logo-container flex33">
          <img src={mapLogos[`${match.teams[0].faculty}_logo`]} alt={`${match.teams[0].faculty} logo`} className="team-logo" />
          <div className="name d-flex fw-bold pt-2">
		  	{match.teams[0].faculty}
			{notificationTeam && notificationTeam[0] && notificationTeam[0].eventInteractions &&
				<NotificationButton
					{...notificationTeam[0]}
					supported={supported}
					swalMessage={'this team events'}
					style={{ maxWidth: '1rem', minWidth: '.75rem' }}
				/>
			}
		  </div>
        </div>

        <div className='d-flex flex-column flex33'>
          <div className="score px-4">{`${match.scores[0]} - ${match.scores[1]}`}</div>
        </div>

        <div className="logo-container flex33">
          <img src={mapLogos[`${match.teams[1].faculty}_logo`]} alt={`${match.teams[1].faculty} logo`} className="team-logo" />
          <div className="name d-flex fw-bold pt-2">
		  	{match.teams[1].faculty}
			{notificationTeam && notificationTeam[1] && notificationTeam[1].eventInteractions &&
				<NotificationButton
					{...notificationTeam[1]}
					supported={supported}
					swalMessage={'this team events'}
					style={{ maxWidth: '1rem', minWidth: '.75rem' }}
				/>
			}
		  </div>
        </div>
      </div>

      {(showSets && match.partialScores.length > 0) && (
        <div className="d-flex justify-content-center px-1">
          <div className='gridc' style={{ "--columns": (match.partialScores.length) }}>
            <div className='gridi' key={10000000} >{match.teams[0].faculty}</div>
              {
                match.partialScores.map((set, index) => (
                  <div className='gridi' key={index} >{set[0]}</div>
                ))
              }
              <div className='gridi' key={20000000} >{match.teams[1].faculty}</div>
              {
                match.partialScores.map((set, index) => (
                  <div className='gridi' key={-index} >{set[1]}</div>
                ))
              }
          </div>
        </div>
      )}

      <div className="card-footer text-white text-center py-2">
        {`${match.type} • ${match.date}`}
      </div>
    </div>
  );
};

export default LivescoreCard;
