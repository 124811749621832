import axios from 'axios';
import config from '../Config/constants';

// Request Service.
const RequestService = {
    // Async function to make request to the endpoint.
    ask: async function(url, params, callback) {
        // Use localstorage to get auth details:
        let token = localStorage.getItem('loginToken')
        // Axios post request to the endpoint with params and call callback function in case of response/error.
        axios.post(config.API_ADDR + url, {params, token}).then(callback).catch((err) => {
            callback(null, err);
        })
    },
    get: async function(url, param, callback) {
        axios.get(config.API_ADDR + url + "/" + param).then(callback).catch((err) => {
            callback(null, err)
        });
    },
}

export default RequestService;

/*
 example:
 RequestService.ask("hello.world", {param1: value1, param2: value2,...}, (result, error) => {
     doSomething()
 })
 */
