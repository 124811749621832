import { createRoot } from "react-dom/client";
import React from "react";
import "./index.sass";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Register from "./components/helpers/register/Register";
import Landing from "./components/landing/Landing";
import Login from "./components/LogIN/Login";
import ForgotPass from "./components/ForgotPass/Forgot";
import 'mapbox-gl/dist/mapbox-gl.css';
import FAQ from './components/FAQ/Faq';
import "bootstrap/dist/css/bootstrap.min.css";
import ResetPass from "./components/ResetPass/Reset";
import ScoreBoard from "./components/scoreboard/ScoreBoard";
import Partners from "./components/partners/Partners";
import Contact from "./components/Contact/Contact";
import Rules from "./components/Rules/Rules";
import CustomNavbar from "./components/Navbar/Navbar";
import Games from "./components/games/Games";
import Lots from "./components/Lots/Lots";
import SignOut from "./components/SignOut/SignOut";
import ConfirmMail from "./components/ConfirmMail/ConfirmMail";
import "./global.sass";
import Groups from "./components/Groups/Groups";
import Brackets from "./components/Brackets/Brackets";
import Profile from "./components/ProfilePage/Profile";
import FacultyStats from "./components/FacultyStats/FacultyStats";
import Livescore from "./components/Livescore/Livescore";
import Org from "./components/Org/Org";
import './backgrounds.sass';
import Minimap from "./components/Minimap/Minimap";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CustomNavbar />
      <Routes>
        <Route path={"/"} element={<Landing />} />
        <Route path={"/scoreboard"} element={<ScoreBoard />} />
        <Route path={"/partners"} element={<Partners />} />
        <Route path={"/login"} element={<Register component={Login} />} />
        <Route path={"/profile"} element={<Profile component={Lots} />} />
        <Route path={"/forgotPassword"} element={<Register component={ForgotPass} />} />
        <Route path={"/resetPassword/:token"} element={<Register component={ResetPass} />} />
        <Route path={"/contact"} element={<Contact />} />
        <Route path={"/rules"} element={<Rules />} />
        <Route path={"/faq"} element={<FAQ />} />
        <Route path={"/games/:sport"} element={<Games />} />
        <Route path={"/sign_out"} element={<SignOut />} />
        <Route path={"/confirm/:token"} element={<ConfirmMail />} />
        <Route path={"/faculties/:faculty"} element={<FacultyStats />} />
        <Route path={"/lots"} element={<Lots />} />
        <Route path={"/groups"} element={<Groups />} />
        <Route path={"/brackets"} element={<Brackets />} />
        <Route path={"/livescore"} element={<Livescore />} />
		<Route path={"/org"} element={<Org />} />
		<Route path={"/minimap"} element={<Minimap />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
