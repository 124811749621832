import "./Rules.sass";
import { useEffect, useState } from "react";
import General from "./Sporturi/General";
import Fotbal from "./Sporturi/Fotbal";
import Handbal from "./Sporturi/Handbal";
import StreetBall from "./Sporturi/Streetball";
import TenisMasa from "./Sporturi/TenisMasa";
import Volei from "./Sporturi/Volei";
import Card from "../helpers/card/Card";
import ModalCustom from "../helpers/modal/Modal";
import rulesRo from "./data/rulesRo.json";
import rulesEng from "./data/rulesEng.json";
import answers from "./data/fallbackAnswers.json";
import Button from "../helpers/buttons/button/Button";
import Sloth from "../helpers/sloth/Sloth";
import sloth1 from "../../images/sloths/rules/rules-1.png";
import sloth2 from "../../images/sloths/rules/rules-2.png";

// This array has to be in the same order as in the data json files
const components = [
  General,
  Fotbal,
  StreetBall,
  Volei,
  Handbal,
  TenisMasa
];

function rule_picker(index, language) {
  const RuleComponent = components[index];
  return (
  	<RuleComponent name={index} language={language} />
  );
}

export default function Rules() {
  const [ruleKey, setRuleKey] = useState(0);
  const [language, setLanguage] = useState("RO")
  const [answer, setAnswer] = useState("");
  const [show, setShow] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [activeButton, setActiveButton] = useState(0);
  const [styleIndex, setStyleIndex] = useState(0);

  const stylesSloths = [
	[
	  { width: '4rem', top: '1.5rem', right: '1.25rem' }, // Mobile
	  { width: '5.5rem', top: '-2rem', right: '-1.75rem' } // Desktop
	],
	[
	  { width: '0rem', top: '0rem', right: '0rem' }, // Mobile
	  { width: '4rem', bottom: '4.5rem', right: '-1.75rem' } // Desktop
	]
  ];

  // Toggle clicked button
  const handleClick = (index) => {
	setActiveButton(index);
  }

  // Switch language
  const handleLanguage = () => {
    setLanguage(language === "RO" ? "ENG" : "RO");
  }

  // Choose which 'rule component/answer text' to show
  const handleAnswer = (answer, ruleKey) => {
	setAnswer(answer);
	setRuleKey(ruleKey);
    setShow(true);
  };

  // Exit modal
  const handleExit = () => {
	setAnswer("");
	setRuleKey(0);
	setShow(false);
  }

	// If the screen is resized, update sloth positioning
	useEffect(() => {
	  const mediaQuery = window.matchMedia('(max-width: 59rem)');
	  setStyleIndex(mediaQuery.matches ? 0 : 1);

      const handleMediaQueryChange = mediaQueryList => {
    	  setStyleIndex(mediaQueryList.matches ? 0 : 1);
	  };

	  mediaQuery.addEventListener('change', handleMediaQueryChange);
	  return () => {
		  mediaQuery.removeEventListener('change', handleMediaQueryChange);
	  }
  	}, []);

  const currentRules = language === "RO" ? rulesRo : rulesEng;
  const buttonText = language === "RO" ? "Mai multe" : "Learn more";

  // If the screen is resized, check if it's desktop or mobile/tablet(desktop!=media query)
  useEffect(() => {
	const mediaQuery = window.matchMedia('(max-width: 59rem)');
	setIsMobile(mediaQuery.matches);
	setStyleIndex(mediaQuery.matches ? 0 : 1);

	const handleMediaQueryChange = mediaQueryList => {
		setIsMobile(mediaQueryList.matches);
		setStyleIndex(mediaQueryList.matches ? 0 : 1);
	};

	mediaQuery.addEventListener('change', handleMediaQueryChange);
	return () => {
		mediaQuery.removeEventListener('change', handleMediaQueryChange);
	}
  }, []);


  return (
    <div className="rulesContainer">
	  {/* The bar that allows to switch between cards on mobile*/}
	  <div className="rulesSwitchBarMobile">
		{isMobile && currentRules.map((rule, index) => {
			return (
				<Button key={index} className={`rulesSwitchBarBtn ${activeButton === index && "active"}`} onClick={() => {handleClick(index)}} name={`${rule.shortname} Rules Btn`} type={"submit"} text={rule.shortname} style={{background: "#FFFFFF", color: "#454445"}} />
			)})}
	  </div>
	  {/* Container mobile sets the additional background for the card component, only on mobile */}
	  <div className="rulesContainerMobile">
	  	{/* If we are not on desktop we show 1 card at a time */} {/* We use 'require' because we stored images name in json file, not the image objects */}
		{isMobile
			? <>
				<Card key={0} text={currentRules[activeButton].name} textBtn={buttonText} nameBtn={currentRules[activeButton].name} image={require(`../../images/rules/${currentRules[activeButton].image}.svg`)} seeAnswer={() => handleAnswer(answers[activeButton], activeButton)}/>
				<Sloth className={'rulesSloth1'} image={sloth1} style={stylesSloths[0][styleIndex]} />
			</>
			: (currentRules.map((rule, index) => {
				{/* Show all cards at a time */}
				return (
					<div key={index} className="rulesContainerMobileCard">
						<Sloth className={'rulesSloth1'} image={sloth1} style={stylesSloths[0][styleIndex]} />
						<Sloth className={'rulesSloth2'} image={sloth2} style={stylesSloths[1][styleIndex]} />
						<Card text={rule.name} textBtn={buttonText} nameBtn={rule.name} image={require(`../../images/rules/${rule.image}.svg`)} seeAnswer={() => handleAnswer(answers[index], index)} />
					</div>
				);
			}))
		}
		{/* Take the vars coresponding to language and create modal */}
		<ModalCustom title={answer[`title${language}`]} component={rule_picker(ruleKey, language)} text={answer[`text${language}`]} onExit={handleExit} show={show} language={language} handleLanguage={handleLanguage} />
	  </div>
    </div>
  );
}
