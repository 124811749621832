import "./FacultyStats.sass"
import { useState } from "react";
import RequestService from "../../services/universalService";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import constants from "../../Config/constants";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Button from "../helpers/buttons/button/Button";
import SportStats from "./sportStats/SportStats";
import Person from "./person/Person";

export default function FacultyStats() {
    const [facultyInfo, setFacultyInfo] = useState(null);
	const [statsKey, setStatsKey] = useState('Players');
	const [matches, setMatches] = useState(null);
	let { faculty } = useParams();
    const { sportsNames: sports } = constants;

	useEffect(() => {
		if (statsKey === 'Players') return;

		const updatedMatches = Object.keys(facultyInfo.matches).reduce((acc, sport) => {
			acc[sport] = facultyInfo.matches[sport].filter(match => match.status === `${statsKey === 'Past games' ? 'FINALIZAT' : 'UPCOMING'}`);
			return acc;
		}, {});

		setMatches(updatedMatches);
	}, [statsKey, facultyInfo]);

    useEffect(() => {
        RequestService.ask("faculties.getFacultyStats", { shortName: faculty }, (result, error) => {
            if(result?.data?.faculty && !error) {
                setFacultyInfo(result.data);
            } else {
                window.reload();
            }
        })
    }, []);

	const handleStats = (stats) => {
		setStatsKey(stats);
	};

    return (
		<section className='facultyStats'>
			{facultyInfo?.faculty &&
				<div className='facultyStatsContainer'>
					<div className='facultyStatsContainerNav'>
						<div className='facultyStatsContainerNavFaculty'>
							<img className='facultyStatsContainerNavFacultyImg' src={require(`../../images/faculties/${facultyInfo.faculty.shortName + '/' + facultyInfo.faculty.shortName}`)} alt={facultyInfo.faculty.shortName} />
							<DropdownButton id="dropdown-basic-button" className={`facultyStatsContainerNavFacultyDropdown ${facultyInfo.faculty.shortName === 'TRANSPORT' || facultyInfo.faculty.shortName === 'CHIMIE' ? 'fitiar-transportul-si-chimia-de-ras' : ''}`} title={facultyInfo.faculty.shortName} style={{ fontSize: ".8rem" }}>
								{facultyInfo.faculties.map(faculty => 
									<Dropdown.Item  key={faculty} href={`/faculties/${faculty}`}>{faculty}</Dropdown.Item>
								)}
							</DropdownButton>
						</div>
						<div className='facultyStatsContainerNavButtons'>
							<Button onClick={() => handleStats('Players')} text='Players' className={`facultyStatsNavBtn ${statsKey === 'Players' ? 'active' : ''}`} />
							<Button onClick={() => handleStats('Past games')} text='Past games' className={`facultyStatsNavBtn ${statsKey === 'Past games' ? 'active' : ''}`} />
							<Button onClick={() => handleStats('Future games')} text='Future games' className={`facultyStatsNavBtn ${statsKey === 'Future games' ? 'active' : ''}`} />
						</div>
						<div className='facultyStatsContainerNavStats'>
							<div className='facultyStatsContainerNavStatsRank'>
								<div>RANK</div>
								<div>{facultyInfo.rank}</div>
							</div>
							<div className="facultyStatsContainerNavStatsPoints">
								<div>Punctaj total</div>
								<div>{facultyInfo.faculty.totalScore}</div>
							</div>
						</div>
					</div>
					<div className='facultyStatsContainerContent'>
						{statsKey === 'Players' &&
							<div className="facultyStatsContainerContentPlayers">
								{facultyInfo.faculty.players && facultyInfo.faculty.players.map((player, index) => {
									let profilePic;

									try { // Image example: ACS_Mirzea_Gabriel-Alexandru.png
										profilePic = require(`../../images/faculties/${facultyInfo.faculty.shortName}/${player.firstName}_${player.lastName}`);
									} catch (error) {
										profilePic = require('../../images/faculties/placeholder.png');
									}

									return (
										<Person key={index} image={profilePic} lastName={player.lastName} firstName={player.firstName} />
									);
								})}
							</div>
						}
						{(statsKey === 'Past games' || statsKey === 'Future games') && matches &&
							facultyInfo.teams.map((team, index) => (
								<SportStats
									key={index}
									stats={{
										matches: matches[team.sport],
										sport: sports[team.sport],
										points: facultyInfo.faculty.points[team.sport],
									}}
								/>
							))
						}
					</div>
				</div>
			}
		</section>
    )
}
