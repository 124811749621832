import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom"
import RequestService from "../../services/universalService";
import "./ConfirmMail.sass"

export default function ConfirmMail() {

    let {token} = useParams();
    let [validToken, setValidToken] = useState(true);

    useEffect(() => {
        RequestService.get('auth.confirm', token, (response, err) => {
            console.log(err)
            if(!response || err) {
                setValidToken(false)
                return
            }
            window.location.href = '/login'
        })
    })

    return (
        <div className={'confirmContainer'}>
            <div className={'confirmBox px-5 pt-5 pb-5'}>
                <div>
                    Validating...
                </div>
                {
                    validToken === false && <div>Not ok</div>
                }
            </div>
        </div>
    )
}