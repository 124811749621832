import { useLayoutEffect } from "react"
import "./Input.sass"

export default function Input({className, type, name, hasInput, placeholder, icon, ColoScheme, defaultText = '', profile = '', onChange, disabled = false, style}){
    useLayoutEffect(() => {
        if (defaultText.length > 0)
            document.getElementById(name).parentElement.classList.add("hasInput")
    }, [])
    
    function handleInput(e) {
        if(e.target.value.length > 0)
            e.target.parentElement.classList.add("hasInput")
        else
            e.target.parentElement.classList.remove("hasInput")
    }

    function handleFocusChange(e){
        e.target.parentElement.classList.add("hasInput")
    }
    const handleChange = ({ target }) => {
        if(typeof(onChange) === "function")
            onChange(target.value);
    }
    return(
        <div className={`${className} ${hasInput ? "hasInput" : ""} inputContainer ${ColoScheme==="reverse"? "reverse": "default"} ${profile}`} floatingtext={placeholder} style={style}>
            <input type={type} name={name} id={name} onInput={handleInput} onFocus={handleFocusChange} onBlur={handleInput} value={defaultText} onChange={handleChange} disabled={disabled} />
            <i className={`${icon} icon`}/>
        </div>
    )
}