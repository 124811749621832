import Input from "../../input/Input";
import Button from "../../buttons/button/Button";
import { useEffect, useState } from "react";
import validate from "../../../../services/validator";
import RequestService from "../../../../services/universalService";
import Swal from "sweetalert2";
import '../Register.sass';

export default function RegisterPlayer() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [university, setUniversity] = useState("");
    const [tshirtSize, setTshirtSize] = useState("");
    const [gender, setGender] = useState("");
    const [signStatus, setSignStatus] = useState("Înregistrare");

    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [phoneError, setphoneError] = useState("");
    const [passError, setpassError] = useState("");
    const [emailError, setemailError] = useState("");
    const [universityError, setUniversityError] = useState("");
    const [genderError, setGenderError] = useState("");
    const [sizeError, setSizeError] = useState("");
    const [agreeError, setagreeError] = useState("");
    const [agree, setAgree] = useState(false);

    const handlelastName = (value) => {
        if (value.length < 48) {
            setLastName(value);
        }
    }
    const handlefirstName = (value) => {
        if (value.length < 72) {
            setFirstName(value);
        }
    }
    const handlephoneNumber = (value) => {
        setPhoneNumber(value);
    }
    const handleuniversity = (e) => {
        setUniversity(e.target.value)
    }
    const handleEmail = (value) => {
        setEmail(value);
    }
    const handlePassword = (value) => {
        setPassword(value);
    }
    const handleConfirmPassword = (value) => {
        setConfirmPassword(value);
    }
    const handleShirtSize = (e) => {
        setTshirtSize(e.target.value)
    }
    const handleGender = (e) => {
        setGender(e.target.value)
    }

    const sendRegisterRequest = async () => {
        const validateFirstName = validate.validate(firstName, "name", [
            { restrictionType: "minlen", value: "2" },
            { restrictionType: "maxlen", value: "72" },
            { restrictionType: "required" }
        ])

        if (!validateFirstName.valid) {
            setFirstNameError("Nume invalid")
            return;
        }

        const validateLastName = validate.validate(lastName, "name", [
            { restrictionType: "minlen", value: "2" },
            { restrictionType: "maxlen", value: "48" },
            { restrictionType: "required" }
        ])

        if (!validateLastName.valid) {
            setLastNameError("Nume invalid")
            return;
        }

        const validatePass = validate.validate(password, "password", [
            { restrictionType: "minlen", value: "8" },
            { restrictionType: "maxlen", value: "30" },
            { restrictionType: "required" }
        ])

        const validatePhone = validate.validate(phoneNumber, "phone_number", [
            { restrictionType: "required" },
        ])

        if (!validatePhone.valid) {
            setphoneError("Numarul de telefon nu este valid");
            return;
        } else {
            setphoneError("");
        }

        const validateEmail = validate.validate(email, "email", [
            { restrictionType: "required" }, { restrictionType: "institutional" }
        ])

        if (!validateEmail.valid) {
            setemailError("Email-ul trebuie sa fie cel institutional");
            return;
        } else {
            setemailError("");
        }

        if (!university) {
            setUniversityError("Selecteaza facultatea");
            return;
        }

        if (!tshirtSize) {
            setSizeError("Selecteaza marimea");
            return;
        }

        if (!gender) {
            setGenderError("Selecteaza genul");
            return;
        }

        if (password !== confirmPassword) {
            setpassError("Parolele nu coincid");
            return;
        }
        else if (!validatePass.valid) {
            setpassError("Minim 8 caractere");
            return;
        } else {
            setpassError("");
        }
		if (!agree) {
			setagreeError("You have to agree with competition rules");
		}

        const data = {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            university: university,
            gender: gender,
            size: tshirtSize
        }
        if (validateFirstName.valid && validateLastName.valid && validateEmail.valid && validatePass.valid && validatePhone.valid && password === confirmPassword && agree === true) {
			await RequestService.ask("auth.register", data, function (result, error) {
                let textError;
                if (error) {
                    if (error.response.status === 410) {
                        textError = "Mailul de confirmare a fost deja trimis";
                    }
                    else if (error.response.status === 409) {
                        textError = "Numarul de telefon este deja inregistrat";
                    }
                    else if (error.response.status === 400) {
                        textError = "Emailul este deja inregistrat";
                    }
                }

                if (textError) {
					console.log(result, error);
                    Swal.fire({
                        icon: 'error',
                        title: textError,
                    })
                } else {
					setSignStatus("Check your email!");
					Swal.fire({
						icon: 'success',
						title: 'Check your email!',
					})
				}
            })
        }
    }

    useEffect(() => {
        setFirstNameError("")
        setLastNameError("")
        setemailError("");
        setpassError("");
        setphoneError("");
        setSignStatus("Înregistrare");
        setUniversityError("")
        setGenderError("")
        setSizeError("")
		setagreeError("");
    }, [firstName, lastName, email, phoneNumber, password, confirmPassword, university, tshirtSize, gender, agree])

    const handleRegisterSubmit = (e) => {
        e.preventDefault()
        sendRegisterRequest();
    }

	return (
		<form action="/" method="post" className="register-form" onSubmit={handleRegisterSubmit}>
			<div className="registerformBody">
				<h1 className="textReg mb-5">Înregistrare</h1>

				<Input type={"text"} name={"firstName"} ColoScheme={"reverse"} placeholder={"Nume"} icon={"fa-solid fa-user"} defaultText={firstName} onChange={handlefirstName} />
				{firstNameError && <span class="alert-color">{firstNameError}</span>}

				<Input type={"text"} name={"lastName"} ColoScheme={"reverse"} placeholder={"Prenume"} icon={"fa-solid fa-user"} defaultText={lastName} onChange={handlelastName} />
				{lastNameError && <span class="alert-color">{lastNameError}</span>}

				<Input type={"text"} name={"phoneNumber"} ColoScheme={"reverse"} placeholder={"Număr de telefon"} icon={"fa-solid fa-phone"} defaultText={phoneNumber} onChange={handlephoneNumber} />
				{phoneError && <span class="alert-color">{phoneError}</span>}

				<Input type={"email"} name={"email"} ColoScheme={"reverse"} placeholder={"email"} icon={"fa-regular fa-envelope"} defaultText={email} onChange={handleEmail} />
				{emailError && <span class="alert-color">{emailError}</span>}

				<div value={university} onChange={handleuniversity} className="inputContainer reverse">
					<select className="form-select faculty-select" aria-label="Default select example" defaultValue="">
						<option disabled value="">Facultate</option>
						<option value="FIE">Facultatea de Inginerie Electrica</option>
						<option value="ENERG">Facultatea de Energetica</option>
						<option value="ACS">Facultatea de Automatica si Calculatoare</option>
						<option value="ETTI">Facultatea de Electronica, Telecomunicatii si Tehnologia Informatiei</option>
						<option value="FIMM">Facultatea de Inginerie Mecanica si Mecatronica</option>
						<option value="FIIR">Facultatea de Inginerie Industriala si Robotica</option>
						<option value="ISB">Facultatea de Ingineria Sistemelor Biotehnice</option>
						<option value="TRANSPORT">Facultatea de Transporturi</option>
						<option value="FILS">Facultatea de Inginerie în Limbi Straine</option>
						<option value="AERO">Facultatea de Inginerie Aerospatiala</option>
						<option value="SIM">Facultatea de Stiinta si Ingineria Materialelor</option>
						<option value="CHIMIE">Facultatea de Inginerie Chimica si Biotehnologii</option>
						<option value="FSA">Facultatea de Stiinte Aplicate</option>
						<option value="FIM">Facultatea de Inginerie Medicala</option>
						<option value="FAIMA">Facultatea de Antreprenoriat, Ingineria si Managementul Afacerilor</option>
					</select>
					<i className={'fa-solid fa-graduation-cap icon'} />
				</div>
				{universityError && <span class="alert-color">{universityError}</span>}

				<div
					value={tshirtSize}
					onChange={handleShirtSize}
					className="inputContainer reverse">
					<select
						className="form-select faculty-select"
						aria-label="Default select example"
						defaultValue="">
						<option disabled value="">Size</option>
						<option value="XS">XS</option>
						<option value="S">S</option>
						<option value="M">M</option>
						<option value="L">L</option>
						<option value="XL">XL</option>
						<option value="XXL">XXL</option>
					</select>
					<i className={'fa-shirt icon fa-solid'} />
				</div>
				{sizeError && <span class="alert-color">{sizeError}</span>}

				<div
					value={gender}
					onChange={handleGender}
					className="inputContainer reverse">
					<select
						className="form-select faculty-select"
						aria-label="Default select example"
						defaultValue="">
						<option disabled value="">Gender</option>
						<option value="M">Male</option>
						<option value="F">Female</option>
					</select>
					<i className={'fa-male icon fa-solid'} />
				</div>
				{genderError && <span class="alert-color">{genderError}</span>}


				<Input type={"password"} name={"password"} ColoScheme={"reverse"} placeholder={"Parolă"} icon={"fa-solid fa-key"} defaultText={password} onChange={handlePassword} />
				{passError && <span class="alert-color">{passError}</span>}
				<Input type={"password"} name={"confirmPassword"} ColoScheme={"reverse"} placeholder={"Confirmă parola"} icon={"fa-solid fa-key"} defaultText={confirmPassword} onChange={handleConfirmPassword} />
				{passError && <span class="alert-color">{passError}</span>}
				{agreeError && (
					<span class="alert-color">{agreeError}</span>
				)}
				<div className="checkbox">
					<input
						type="checkbox"
						id="terms"
						name="terms"
						value="terms"
						onClick={(e) => setAgree(e.target.checked)}
					/>
					<label className="terms" htmlFor="terms">
						Am citit și sunt de acord cu <a href="/rules" target="_blank">regulile competiției</a>.
					</label>
				</div>
			</div>
			<Button name={"register"} isDisabled={signStatus === "Verifică-ți mail-ul!" || agree === false} type={"submit"} text={signStatus} style={{ background: "#009494", color: "#FFFFFF" }} onClick={() => {}}/>
		</form>
	);
}