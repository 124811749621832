import mapboxgl from "mapbox-gl";
import { renderToString } from "react-dom/server";
import constants from "../Config/constants";

mapboxgl.accessToken = constants.MAPBOX_API_TOKEN;

const MapService = {
	createMap(container, settings, index) {
		return new mapboxgl.Map({
			container: container.current,
			center: settings.center[index], // change center
			style: settings.styleURL.alex, // if you change the Token and the map doesn't work delete this line and uncomment line 19
			zoom: settings.zoom[index].default,
			minZoom: settings.zoom[index].min,
			maxZoom: settings.zoom[index].max,
			maxBounds: settings.bounds[index],
		});
	},

	addConfigs(map) {
		// map.setConfigProperty('basemap', 'showPointOfInterestLabels', false); // Removes points such as 'covrigi la nelu'
	},

	// 'html' is expected to be an object having as keys(1, 2, ...) 'async functions' that each return a 'react component'
	addMouseEvents(map, source, html) {
		// Mouse events manipulation
		map.on('mouseenter', source, () => {
			map.getCanvas().style.cursor = 'pointer';
		});
		map.on('click', source, async (event) => {
			const features = event.features; // Store features as the async operations steps out of the javascript event listener's scope and will be undefined
			const popupHTML = html ? html[features[0].id] : "Ti-a cazut satelitul"; // Retrieve the function from 'html' object
			const htmlSTRING = typeof popupHTML === 'function' ? renderToString(await popupHTML()) : popupHTML; // If function execute it and convert to string, otherwise use the string
			new mapboxgl.Popup()
				.setLngLat(event.lngLat)
				.setHTML(htmlSTRING)
				.addTo(map);
		});
		map.on('mouseleave', source, () => {
			map.getCanvas().style.cursor = '';
		});
	},

	addControls(map) {
		map.addControl(
			new mapboxgl.GeolocateControl({
				positionOptions: {
					enableHighAccuracy: true
				},
				// Receive updates from the user
				trackUserLocation: true,
				showUserHeading: true,
				showAccuracyCircle: false
			}),
			"bottom-left"
		);
	},

	addLayers(map, features, styles) {
		map.addSource(styles.source, {
			type: 'geojson',
			data: features,
		});
		map.addLayer(styles);
	},
	
	addImgLayers(map, features, styles, img) {
		map.loadImage(img, (error, image) => {
			if (error) throw error;
			map.addImage(styles.layout["icon-image"], image);
			map.addSource(styles.source, {
				type: 'geojson',
				data: features,
			});
			map.addLayer(styles);
		});
	},
}

export default MapService;