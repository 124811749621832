import "./Forgot.sass"
import Input from "../helpers/input/Input";
import Button from "../helpers/buttons/button/Button";
import { useEffect, useState } from "react";
import RequestService from "../../services/universalService";
import validate from "../../services/validator";

export default function ForgotPass() {
    const [email, setEmail] = useState("");
    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");

    const handleEmail = (value) => {
        setEmail(value);
    }
    const sendEmail = async () => {
        const validateEmail = validate.validate(email, "email", [
            { restrictionType: "required" }
        ]);

        if (!validateEmail.valid) {
            setMessage("Camp obligatoriu");
            setColor("red");
        }
        else {
            setColor("gray");
            setMessage("Loading...");
            RequestService.ask("auth.forgot", { email: email }, function (result, error) {
                if (error) {
                    if (error.response?.status === 404) {
                        setMessage("Emailul nu exista");
                        setColor("red");
                        return;
                    } else {
                        setMessage("Nu s-a putut trimite link-ul, incearca mai tarziu.");
                        setColor("red");
                        return;
                    }
                }
                
                setMessage("Emailul de resetare a parolei a fost trimis");
                setColor("gray");
            });
        }

        setColor("red");
        return;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        sendEmail();
    }

    useEffect(() => {
        setMessage("");
    }, [email])

    return (
        <form action="/" method="post" className="forgotContainer" onSubmit={handleSubmit}>
            <div className="formBody">
                <h1>Ai uitat parola?</h1>
                <div className={'inputsForgot'}>
                    <Input className="inputForgot" type={"email"} name={"emailLogin"} ColoScheme={"default"} placeholder={"Email"} icon={"fa-regular fa-envelope"} defaultText={email} onChange={handleEmail} />
					{
						message &&
						<div className="infoAlert">
							<span className = {`alert-color-${color}`}>
								{message}
							</span>
						</div>
					}
				</div>
            </div>
            <Button className="forgotBtn" name={"forbot"} type={"submit"} text={"Trimite"} style={{ background: "#FFFFFF", color: "#454445" }}/>
        </form>
    )
}