import "./Sporturi.sass"
export default function Volei({ language }) {
    return (
        <>
            {language === "RO" ?
                // ------------------------------------------- Engleza -------------------------------------------
                <div className="sportRuleSet">
                    <div className="sportRule">
                        <h4>
                            Competiția se desfășoară conform <a href="https://www.frvolei.ro/pagini/5_oficiali/resurse_arbitri/2015/Regulamentul%20jocului%20de%20volei%202015.pdf">regulamentului FRV</a>, cu următoarele modificări:
                        </h4>
                        <ul>
                            <li>
								Echipa este formată din 6 jucători (dintre care minim 3 fete) pe teren și maxim 3 rezerve.
                            </li>
                            <li>
								Se vor juca seturi până la 25 puncte și 15 puncte, conform formatului menționat în secțiunea “Structura competiției de volei”.
                            </li>
                            <li>
								În cazul de egalitate la puncte (24-24 sau 14-14), se continuă până se ajunge la diferență de 2 puncte între echipe.
                            </li>
                            <li>
								Se pot face maxim 6 schimbări pe set, ținându-se cont să nu se încalce următoarea regulă.
                            </li>
							<li>
								<b>Pe tot parcursul meciului trebuie, ca în teren, să rămână minim 3 fete.</b>
							</li>
							<li>
								Schimbările se fac, în timpul dintre puncte, în care mingea nu este în joc, înainte ca arbitrul să fluiere pentru a permite punerea mingii în joc prin servă. Căpitanul va ridica mâna și îi va cere arbitrului să efectueze o schimbare.
							</li>
                            <li>
								Echipele se vor încălzi simultan timp de 2 min pe terenul de volei, înaintea începerii meciului.
                            </li>
                            <li>
                                Alegerea servei / terenului se va face aleator prin aruncarea monedei.
                            </li>
                            <li>
								Dacă o echipă: nu se prezintă, refuză să joace sau nu este eligibilă pentru meciul de volei, <b>va pierde meciul cu scorul de 0-1/0-2 la seturi și 0-25 puncte pe set.</b>
                            </li>
							<li>
								<b>Nu se poate folosi libero.</b>
							</li>
							<li>
								<b>Înălțimea fileului</b> , la care se vor desfășura toate meciurile din competiție este de <b>2,35 m</b>.
							</li>
                        </ul>

                        <h4>Regulile jocului de volei:</h4>
                        <ul>
                            <li>
								Mingea este în teren când atinge toată aria terenului, inclusiv liniile de delimitare
                            </li>
                            <li>
								Serva se va face din spatele liniei terenului, dintre extremitățile fileului.
                            </li>
                            <li>
                                Jucătorii vor sta în următoarele poziții:
                                <ul>
                                    <li>
                                        În poziția 1 (dreapta spate)
                                    </li>
                                    <li>
                                        În poziția 2 (dreapta față)
                                    </li>
                                    <li>
                                        În poziția 3 (centru față)
                                    </li>
                                    <li>
                                        În poziția 4 (stânga față)
                                    </li>
                                    <li>
                                        În poziția 5 (stânga spate)
                                    </li>
                                    <li>
                                        În poziția 6 (centru spate)
                                    </li>
                                </ul>
                            </li>
                            <li>
								După fiecare punct câștigat pe serva adversarilor se schimbă rândul la servă și jucătorii se rotesc în sensul acelor de ceasornic. (2->1,6->5, 5->4...) 
                            </li>
                        </ul>

                        <h4>
							Structura competiției de volei:
                        </h4>
                        <ol>
                            <li>
                                <b>Faza Eliminatorie</b>
								<ul>
									<li>
										Competiția se desfășura în format piramidal, urmând să se joace 7 meciuri de optimi, 4 sferturi, 2 semifinale, și două finale. Toate acestea vor fi meciuri eliminatorii (se joacă un singur meci)
									</li>
									<li>
										Meciurile constau într-un singur set, până la 25 de puncte, cu criteriul de departajare menționat mai sus în caz de egalitate la 24-24.
									</li>
								</ul>
                                <li>
                                    <b>Finala mica </b>si <b>finala mare</b>:
									<ul>
										<li>
											Meciurile constau în două seturi, până la 25 de puncte, cu criteriul de departajare menționat mai sus în caz de egalitate la 24-24. Dacă se ajunge la egalitate de seturi 1-1, se va mai juca un set decisiv până la 15 puncte. Aici intervine același mod de departajare în cazul egalității la puncte 14-14, mai exact se continuă până se ajunge la diferență de 2 puncte între 
										</li>
									</ul>
                                </li>
                            </li>
                    
                        </ol>
                        <h3>
                            Criteriile de departajare ale echipelor
                        </h3>
                        <h4>
                            Faza grupelor
                        </h4>
                        <ul>
                            <li>
								În caz de egalitate la puncte între 2 echipe după jocurile din faza grupelor, vor intra în vigoare următoarele criterii:
                                <ul>
                                    <li>
                                        Rezultatul direct
                                    </li>
                                    <li>
                                        Raportul punctelor marcate/pierdute
                                    </li>
                                    <li>
                                        Numărul mai mare de puncte marcate
                                    </li>
                                    <li>
                                        Numărul mai mic de puncte pierdute
                                    </li>
                                </ul>
                            </li>
                            <li>
								În caz de egalitate de puncte între 3 echipe după jocurile din faza grupelor, vor intra în vigoare următoarele criterii de departajare:
                                <ul>
                                    <li>
										Se va întocmi un clasament doar cu rezultatele dintre cele 3 formații aflate la egalitate de puncte și se va califica echipa cu cele mai multe puncte adunate doar din jocurile cu echipele aflate la egalitate de puncte
                                    </li>
                                    <li>
										Raportul de puncte marcate/pierdute din jocurile cu celelalte echipe aflate la egalitate de puncte
                                    </li>
                                    <li>
										Numărul mai mare de puncte marcate în jocurile cu celelalte echipe aflate la egalitate de puncte
                                    </li>
                                    <li>
										Numărul mai mic de puncte pierdute în jocul cu celelalte echipe aflate la egalitate de puncte
                                    </li>
                                    <li>
										Raportul de puncte marcate/pierdute în toate jocurile din grupă
                                    </li>
                                    <li>
										Numărul mai mare de puncte marcate, în toate jocurile din grupă
                                    </li>
                                    <li>
										Numărul mai mic de puncte pierdute, în toate jocurile din grupă
                                    </li>
                                </ul>
                            </li>

                        </ul>
                        <h3>
                            Punctaj Clasament General
                        </h3>
                        <p>
                            Vor primi puncte pentru performanță în cadrul competiției:
                        </p>
                        <ul>
                            <li>
                                100 puncte -  locul 1
                            </li>
                            <li>
                                75 puncte -  locul 2
                            </li>
                            <li>
                                50 puncte -  locul 3
                            </li>
                            <li>
                                25 puncte -  locul 4
                            </li>
                        </ul>
						<h3>
							Conduită sportivă
						</h3>
						<p>
							Se aplică regulamentul din secțiunea <b>”Conduita sportivilor”</b> și <b>”Conduita suporterilor”</b> din cadrul <b>Regulament General</b>.
                    	</p>
						<p>
							<i>
								Menționăm faptul că echipa de organizare își rezervă dreptul de a modifica prezentul regulament în cazul apariției unor situații neprevăzute. De asemenea, fiecare participant în momentul în care se înscrie la evenimentul Poli Olympics își asumă riscurile unor eventuale accidentări venite din situații mai mult sau mai puțin sportive, echipa de organizare neasumându-și responsabilitatea pentru posibilele accidentări ce ar putea surveni. Totuși, echipa de organizare își asumă responsabilitatea asigurării tuturor condițiilor necesare desfășurării evenimentului în condițiile de siguranță impuse prin lege.
							</i>
						</p>
                    </div>
                </div>
                :
                <div className="sportRuleSet">
                    <div className="sportRule">
                        <h4>
                            The competition is held according to the  <a href="https://www.frvolei.ro/pagini/5_oficiali/resurse_arbitri/2015/Regulamentul%20jocului%20de%20volei%202015.pdf">FRV regulation</a>, with the following changes:
                        </h4>
                        <ul>
                            <li>
                                The team consists of 6 players (3 girls and 3 boys) on the field and 3 reserves
                            </li>
                            <li>
                                Match format:
                                <ul>
                                    <li>
                                        Groups = first team to win a set
                                    </li>
                                    <li>
                                        Semi-finals = first team to win two sets
                                    </li>
                                    <li>
                                        Finals = first team to win three sets
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Each set will be played up to 25 points
                            </li>
                            <li>
                                If it reaches the case of tie on sets (2-2) the last set will be 15 points
                            </li>
                            <li>
                                In the case of a tie on points (24-24) it is continued until the difference of 2 points between the teams is reached
                            </li>
                            <li>
                                A maximum of 6 changes can be made per set
                            </li>
                            <li>
                                The teams will prepare simultaneously for 6 minutes on the volleyball court or 5 min if they do not have a volleyball court at the time of preparing.
                            </li>
                            <li>
                                The choice of serve / field will be done randomly by throwing the coin
                            </li>
                            <li>
                                If a team: does not show up, refuses to play or is not eligible for the volleyball match will lose the match with the score of 0-3 in sets and 0-25 points per set.
                            </li>
                        </ul>
    
                        <h4>The rules of the volleyball game:</h4>
                        <ul>
                            <li>
                                The ball is in the field when it reaches the entire area of the pitch, including the boundary lines
                            </li>
                            <li>
                                The serve will be made from behind the ground line
                            </li>
                            <li>
                                Players will sit in the following positions:
                                <ul>
                                    <li>
                                        In position 1 (back right)
                                    </li>
                                    <li>
                                        In position 2 (front right)
                                    </li>
                                    <li>
                                        In position 3 (front center)
                                    </li>
                                    <li>
                                        In position 4 (front left)
                                    </li>
                                    <li>
                                        In position 5 (rear left)
                                    </li>
                                    <li>
                                        In position 6 (rear center)
                                    </li>
                                </ul>
                            </li>
                            <li>
                                After each point earned on the opponents' serve, the row changes to the serve and the players rotate clockwise. (2 - 1,6 - 5, 5 - 4...)
                            </li>
                        </ul>
    
                        <h4>
                            The Volleyball competition is structured in the following way:
                        </h4>
                        <ol>
                            <li>
                                <b>	Groups stage</b>
                                <ul>
    
                                    <li>
                                        3 groups of 4 teams and 1 group of 3 teams
                                    </li>
                                    <li>
                                        The first ranked team in each group will pass on
                                    </li>
                                    <li>
                                        The score for the groups, on the basis of which it will be decided who passes in next stage is:
                                        <ul>
                                            <li>
                                                3 points for win game
                                            </li>
                                            <li>
                                                0 points for lose game
                                            </li>
                                            <li>
                                                there is no possibility that a match will end in a tie
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <li>
                                    <b>Semi-finals:</b>  knockout matches(only one match is played)
                                </li>
                                <li>
                                    <b>The small final and the grand final:</b>  knockout matches(only one match is played)
                                </li>
                            </li>
                        
                        </ol>
                        <h3>
                            The tie-breakers criteria for the teams
                        </h3>
                        <h4>
                            Groups stage
                        </h4>
                        <ul>
                            <li>
                                In case of a tie for points between 2 teams after the games in the group stage, the following criteria will come into force:
                                <ul>
                                    <li>
                                        Direct result
                                    </li>
                                    <li>
                                        Overall goal difference
                                    </li>
                                    <li>
                                        Higher number of goals scored
                                    </li>
                                    <li>
                                        Lower number of goals conceded
                                    </li>
                                </ul>
                            </li>
                            <li>
                                In case of a tie for points between 3 teams after the games in the group stage, the following criteria will come into force:
    
                                <ul>
                                    <li>
                                        A ranking will be drawn up only with the results of the 3 teams in a tie for points and the team with the most points gathered from the games with the teams in a tie will qualify.
                                    </li>
                                    <li>
                                        Overall goal difference in games with the other teams tied on points
                                    </li>
                                    <li>
                                        The higher number of points scored in games with the other teams in a tie for points
                                    </li>
                                    <li>
                                        The lower number of points conceded in games with the other teams in a tie for points
                                    </li>
                                    <li>
                                        Overall goal difference in all group games
                                    </li>
                                    <li>
                                        The higher number of goals scored in all group games
                                    </li>
                                    <li>
                                        The lower number of goals conceded in all group games
                                    </li>
                                </ul>
                            </li>
    
                        </ul>
                        <h3>Sports conduct</h3>
                        <p>	It is very important for each participant in the competition to have disciplined behavior, respecting the imposed rules.</p>
                        <p> 	If one of the participants behaves inappropriately and causes high-level problems inside the sports facility, he/she will be excluded from the competition. If his attitude makes it impossible to conduct the match that was being played, the game will be stopped and the team that produced the problem will lose the match by default.</p>
                        <h3>
                            Overall Ranking Score
                        </h3>
                        <p>
                            The teams will receive points for performance in the competition:
                        </p>
                        <ul>
                            <li>
                                100 points - 1st place
                            </li>
                            <li>
                                75 points -  2nd place
                            </li>
                            <li>
                                50 points -  3rd place
                            </li>
                            <li>
                                25 points -  4th place
                            </li>
                        </ul>
                    </div>
                </div> 
        }
    </>
)
}