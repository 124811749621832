import { useState } from "react";
import NotificationModal from "../notificationModal/NotificationModal";
import './NotificationModalButton.sass';
import ModalCustom from "../../helpers/modal/Modal";

// This is the button that opens the modal and keeps track of the modal state
const NotificationModalButton = ({ image, textStyle, imageStyle, type, name, text, buttonStyle, className, top, bottom, left, right }) => {
	const [show, setShow] = useState(false);

	const handleClick = () => {
		setShow(true);
	}

	const handleExit = () => {
		setShow(false);
	}

	const renderedComponent = () => {
		return (
			<NotificationModal />
		);
	}

	const containerStyle = {
		top: top,
		bottom: bottom,
		left: left,
		right: right,
	};

	return (
		<div className="notificationHelpModalBtn" style={containerStyle}>
			<button onClick={handleClick} type={type} id={name} name={name} className={"notificationButton " + className} style={buttonStyle}>
				<text className="notificationHelpBtnText" style={textStyle}>{text}</text>
				<img className="notificationHelpBtnImage" src={image} alt={name} style={imageStyle} />
			</button>
			<ModalCustom title={"Helper section for Notifications"} component={renderedComponent()} onExit={handleExit} show={show} />
		</div>
	);
};

export default NotificationModalButton;