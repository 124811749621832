import "./Sporturi.sass"
export default function TenisMasa({language}) {
    return (
    <>
    {language === "RO" ?
            <div className="sportRuleSet">
            <div className="sportRule">
                <h3>
                    Considerente generale
                </h3>
                <ul>
                    <li>
						Mingea este în joc din momentul în care este aruncată pentru serviciu și până în momentul când ea a atins orice altceva în afara suprafeței de joc, a fileului sau accesoriilor acestuia, a paletei ținută în mână, sau mâna paletei sub încheietura acesteia, sau când schimbul s-a terminat printr-o decizie de rejucare, sau prin acordarea punctului.
                    </li>
                    <li>
                        Un jucător lovește mingea, dacă o atinge cu paleta ținută în mână sau cu mâna paletei, dedesubtul încheieturii.
                    </li>
                    <li>
						Un jucător obstrucționează mingea, dacă el sau orice accesoriu deținut, atinge mingea fără ca aceasta să fi atins suprafața sa de joc sau linia ei de fund de când a fost lovită de oponentul său.
                    </li>
                </ul>
                <h3>
                    Un bun serviciu
                </h3>
                <ul>
                    <li>
						Serviciul trebuie să înceapă cu mingea așezată liber și staționară în palma deschisă a mâinii libere a jucătorului la serviciu.
                    </li>
                    <li>
						Jucătorul la serviciu va trebui să arunce mingea în sus, pe verticală, fără a-i imprima niciun efect, în așa fel încât să se înalțe aproximativ 16 cm după ce a părăsit palma mâinii libere și o va lovi, în cădere, fără ca mingea să atingă ceva înainte de a fi lovită.
                    </li>
                    <li>
						În timpul căderii mingii, jucătorul la serviciu o va lovi în așa fel încât aceasta va atinge întâi partea sa de masă și apoi, trecând peste fileu, va atinge jumătate de masă a primitorului. (În meciul de dublu, mingea va lovi jumătatea din dreapta a celui care servește cât și a celui care primește.)
                    </li>
                    <li>
						De la startul serviciului pâna când va fi lovită, mingea va trebui să se afle deasupra suprafeței de joc și în spatele liniei de fund a mesei și nu va fi ascunsă vederii primitorului cu nicio parte a corpului sau a îmbrăcăminții jucătorului la serviciu (sau partenerul de joc în cazul unui meci de dublu).
                    </li>
                    <li>
						Dacă arbitrul nu este sigur cu privire la legalitatea unui serviciu, el poate întrerupe jocul și poate avertiza jucătorul care servește
                    </li>
                    <li>
						În momentul în care greșeala de serviciu este clară nu se va acorda niciun avertisment și se va acorda punct pentru adversar.
                    </li>
                    <li>
						Jucătorul care va trage de timp la serviciu poate primit avertisment sau poate fi penalizat la decizia arbitrului.
					</li>
                </ul>
                <h3>
                    Un bun retur
                </h3>
                <ul>
                    <li>
						Mingea, după ce a fost bine servită, va fi lovită în așa fel încât să treacă peste fileu, fie direct, fie după atingerea acestuia, lovind terenul adversarului.
                    </li>
                </ul>
                <h3>
					Întreruperea și apoi repetarea schimbului de mingi.
                </h3>
                <h4>
					Jocul se întrerupe, iar schimbul de mingi va fi repetat:
                </h4>
                <ul>
                    <li>
						<b>La serviciu</b>: dacă mingea trece în terenul advers, dar atingând fileul, cu condiția ca serviciul să fi fost bine executat.
                    </li>
                    <li>
						<b>La serviciu neregulamentar</b>: dacă, la aprecierea arbitrului, efectuarea serviciului nu se supune regulamentului.
                    </li>
                    <li>
						<b>Corectarea erorilor</b>: pentru a îndrepta o eroare de serviciu, primire sau alegerea terenului.
                    </li>
                    <li>
						<b>Avertizare sau penalizare</b>: pentru a avertiza sau penaliza un jucător.
                    </li>
                    <li>
						<b>Întrerupere externă</b>: dacă, după părerea arbitrului, condițiile de joc sunt tulburate de fenomene capabile să afecteze buna desfășurare a partidei, spre exemplu: o altă minge intră în spațiul destinat meciului.
                    </li>
                    <li>
						<b>Intervenția arbitrilor</b>: arbitrul, din diverse motive, alege oprirea temporară a jocului.
                    </li>
                </ul>
                <h3>
                    Punctul
                </h3>
                <h4>
                    Un punct se pierde:
                </h4>
                <ul>
                    <li>
                        Când se ratează serviciul.
                    </li>
                    <li>
                        Dacă se ratează returul.
                    </li>
                    <li>
                        La executarea unui serviciu bun sau a unui retur bun, mingea atinge orice altceva cu excepția fileului.
                    </li>
                    <li>
                        Dacă mingea trece dincolo de linia de fund a adversarului fără a atinge terenul acestuia.
                    </li>
                    <li>
						Când jucătorul obstrucționează mingea.
                    </li>
                    <li>
                        Dacă mingea este lovită de două ori consecutiv.
                    </li>
                    <li>
						Dacă jucătorul sau orice accesoriu deținut de acesta mișcă suprafața de joc, mingea aflându-se în joc.
                    </li>
                    <li>
						Atunci când jucătorul sau orice accesoriu al acestuia atinge ansamblul fileului.
                    </li>
                    <li>
						Dacă mâna liberă a jucătorului, atinge suprafața de joc, în timp ce mingea este în joc.
                    </li>
					<li>
						În meciul de dublu, când jucătorul care a lovit mingea este în afara ordinii stabilite prin jucătorul la serviciu, respectiv jucătorul la primire.
					</li>
                    <li>
						Dacă jucătorul vorbește în timpul jocului sau scoate în mod voit orice fel de sunete menite să deranjeze sau să intimideze adversarul.
                    </li>
                </ul>
                <h3>
                    Setul
                </h3>
				<ul>
					<li>
						Setul va fi câștigat de jucătorul (sau de perechea) care acumulează 11 puncte. Dacă jucătorii vor ajunge la egalitate de 10 puncte, setul se va continua cu fiecare jucător servind câte o singură dată, iar setul va fi câștigat de jucătorul care dobândește un avans de 2 puncte față de adversar.
					</li>
				</ul>
				<h3>
					Meciul
				</h3>
				<ul>
					<li>
						Meciul constă în câștigarea de către unul din jucători a unui număr de 2 seturi în grupe, 3 seturi în semifinale, respectiv 4 seturi în finala mare și cea mică.
					</li>
				</ul>
                <h3>
                    Alegerea serviciului, primirii sau terenului
                </h3>
                <ul>
                    <li>
						Dreptul de a alege ordinea inițială a serviciului, primirii sau terenului, va fi stabilit prin tragere la sorți sau prin ascunderea mingii sub masă. Câștigătorul poate alege între a servi primul, a primi primul, de a începe pe un anumit teren, sau poate oferi adversarului posibilitatea de a alege.
                    </li>
                    <li>
						Când un jucător a ales să servească, să primească sau și-a ales unul dintre terenuri, celălalt jucător, poate alege orice a rămas.
                    </li>
                    <li>
						Jucătorul care servește primul într-un set va fi cel care primește primul în setul următor al meciului.
                    </li>
                    <li>
						Fiecare jucător are alternativ la dispoziție câte 2 mingi de serviciu, până în momentul în care se determină un câștigător. 
                    </li>
					<li>
						După fiecare set și în ultimul set posibil când un jucător ajunge la 5 puncte, jucătorii vor face schimb de terenuri.
					</li>
                </ul>
                <h3>
                    De menționat
                </h3>
                <ul>
                    <li>
						Orice comportament neadecvat (injurii, intimidări, amenințări, descurajări aduse adversarului, ieșiri nervoase, lovituri ale paletei etc. vor fi sancționate la decizia arbitrului).
                    </li>
                    <li>
						Un jucător are voie să inspecteze paleta adversarului.
                    </li>
                    <li>
						Fețele paletei trebuie să aibă culori diferite.
                    </li>
                    <li>
						Un jucător are dreptul la un minut de time-out pe parcursul meciului și să folosească prosopul pentru a se șterge din 6 în 6 puncte.
                    </li>
                    <li>
						Mingea lovită cu degetul de la mâna ce ține paleta se consideră o lovitură bună.
                    </li>
                    <li>
						Jucătorii au voie sa atingă masa cu antebrațul mâinii ce ține paleta, dar nu cu altă parte a corpului.
                    </li>
                    <li>
						Masa liberă poate fi folosită oricând pentru încălzire, mai puțin când se joacă una din cele două finale.
                    </li>
				</ul>
				<h4>
                    Structura competiției: <b>TBA</b>
                </h4>
                {/* <p>
                    Competiția de tenis de masa este structurată în felul următor:
                </p>
                <ol>
                    <li>
                        <b>Faza grupelor:</b>
                        <ul>
                            <li>
                                4 grupe 
                            </li>
                            <li>
                                va trece mai departe prima echipă clasată din fiecare grupă
                            </li>
                            <li>
                                punctajul pentru grupe, pe baza căruia se va decide cine trece în faza următoare este:
                                <ul>
                                    <li>
                                        Meci castigat - 3p
                                    </li>
                                    <li>
                                        Meci pierdut - 0p
                                    </li>
                                    <li>
                                        Nu se poate termina un meci la egalitate
                                    </li>
                                </ul> 
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Semifinale:</b> meciuri eliminatorii (se joacă un singur meci); 
                    </li>
                    <li>
                        <b>Finala mică și finala mare:</b> meciuri eliminatorii (se joacă un singur meci).
                    </li>
                </ol>
                <p>În faza grupelor, meciul constă în câștigarea de către unul din jucători a unui număr de 2 seturi. Pentru celelalte etape, meciul constă în câștigarea de către unul din jucători a unui număr de 3 seturi.</p> */}
                <h3>
                    Punctaj Clasament General
                </h3>
                <div>
                    Vor primi puncte pentru performanță în cadrul competiției:
                    <ul>
                        <li>
                            100 puncte - locul 1
                        </li>
                        <li>
                            75 puncte - locul 2
                        </li>
                        <li>
                            50 puncte - locul 3
                        </li>
                        <li>
                            25 puncte - locul 4
                        </li>
                    </ul>
                </div>
				<h3>
					Conduită sportivă
				</h3>
				<p>
					Se aplică regulamentul din secțiunea <b>”Conduita sportivilor”</b> și <b>”Conduita suporterilor”</b> din cadrul <b>Regulament General</b>.
				</p>
				<p>
					<i>Menționăm faptul că echipa de organizare își rezervă dreptul de a modifica prezentul regulament în cazul apariției unor situații neprevăzute.</i>
				</p>
				<p>
					<i>De asemenea, fiecare participant în momentul în care se înscrie la evenimentul Poli Olympics își asumă riscurile unor eventuale accidentări venite din situații mai mult sau mai puțin sportive, echipa de organizare neasumându-și responsabilitatea pentru posibilele accidentări ce ar putea surveni. Totuși, echipa de organizare își asumă responsabilitatea asigurării tuturor condițiilor necesare desfășurării evenimentului în condițiile de siguranță impuse prin lege.</i>
				</p>
            </div>
        </div> :
            // ------------------------------------------- Engleza -------------------------------------------

            <div className="sportRuleSet">
            <div className="sportRule">
                <h3>
                    General considerations:
                </h3>
                <ul>
                    <li>
                        The ball is in the game from the moment when, for the execution of the serve, it is stationary in the outstretched palm of the free hand, it is designed for service and until the moment when it has touched anything other than the playing surface, its net or accessories, the palette held in the hand of the pallet, or the hand of the pallet under its wrist, or when the exchange is over by a decision to replay, or by the award of the point.                     </li>
                    <li>
                        A player hits the ball, if he touches it with the palette held in his hand or with the hand of the paddle, under the wrist.
                    </li>
                    <li>
                        A player obstructs the ball, if he or anything he wears, touches the ball without it having gone over his playing surface, or the bottom line, without having touched it, although the last time, it was hit by the opponent. 
                    </li>
                </ul>
                <h3>
                    A good serve:
                </h3>
                <ul>
                    <li>
                        The serve must begin with the ball freely placed and stationary in the open palm of the player's free hand at work.
                    </li>
                    <li>
                        The player will have to project the ball upwards, vertically, without giving it any effect, in such a way that it rises at least 16 cm after leaving the palm of the free hand and he will hit it, in the fall, without the ball touching anything before it is hit.
                    </li>
                    <li>
                        În timpul căderii mingii, jucătorul la serviciu o va lovi în așa fel încât aceasta va atinge întâi partea sa de masă și apoi, trecând peste sau în jurul fileului va atinge jumătate de masă a primitorului. În meciul de dublu, mingea va lovi jumătatea din dreapta a celui care servește cât și a celui care primește.
                    </li>
                    <li>
                        From the start of the serve until it is hit, the ball will have to be above the playing surface and behind the bottom line of the table and will not be hidden from the recipient's view with any part of the body or the player's clothing. 
                    </li>
                    <li>
                        The serve must take place in such a way that the match referee can determine each time whether the serve was a regular one or not. 
                    </li>
                    <li>
                        When the serve mistake is clear, no warning will be given and a point will be awarded to the opponent. 
                        </li>
                    <li>	The player who will draw time at work may receive a warning or may be penalized at the referee's decision.</li>
                </ul>
                <h3>
                    A good return
                </h3>
                <ul>
                    <li>
                        The ball, after being well served or well returned, will be hit in such a way as to pass over the net or around it, hitting the opponent's table side, either directly or after it has touched the net.
                    </li>
                </ul>
                <h3>
                    Repeating the exchange of balls
                </h3>
                <h4>
                    The game must be repeated:
                </h4>
                <ul>
                    <li>
                        If, at work, the ball passes over or around the entire net assembly by touching it, provided that the serve has been well executed, or if the ball has been obstructed by the receiver or its partner.
                    </li>
                    <li>
                        If, at the discretion of the referee, the performance of a good service or a good return, is not subject to the rules, for reasons beyond the control of the player.
                    </li>
                    <li>
                        The referee, for various reasons, chooses to temporarily stop the game.
                    </li>
                </ul>
                <h4>
                    The game can also be interrupted:
                </h4>
                <ul>
                    <li>
                        To rectify an error of service, receipt or choice of the sides.
                    </li>
                    <li>
                        To warn or penalize a player.
                    </li>
                    <li>
                        Because, in the opinion of the main referee, the playing conditions are disturbed by phenomena capable of affecting the smooth running of the game.
                    </li>
                </ul>
                <h3>
                    The point
                </h3>
                <h4>
                    If the game is not stopped, a point is lost:
                </h4>
                <ul>
                    <li>
                        When the serve is missed.
                    </li>
                    <li>
                        When the return is missed.
                    </li>
                    <li>
                        When executing a good serve or a good return, the ball touches everything else except the net and accessories.
                    </li>
                    <li>
                        If the ball crosses the player's end line without touching their court, after being hit by the opponent.
                    </li>
                    <li>
                        When one of the players obstructs the ball.
                    </li>
                    <li>
                        If the ball is hit twice in a row.
                    </li>
                    <li>
                        When, the ball being in play, the player and anything he wears, moves the playing surface.
                    </li>
                    <li>
                        When the player or anything he wears, touches the entirety of the net.
                    </li>
                    <li>
                        If the player's free hand touches the playing surface while the game is going.
                    </li>
                    <li>
                        When the player who hit the ball is out of the order established by the player at serve, respectively the player at the reception.
                        </li>
                    <li>	If the player speaks during the game or willfully makes any kind of sounds. </li>
                </ul>
                <h3>
                    Set
                </h3>
                <p>
                    The set will be won by the player who accumulates 11 points. If the players reach a 10-10 draw, the exchange of balls will continue, serving each player in turn, until one of the players has a 2-point lead over the opponent.
                </p>
                <h3>
                    Match
                </h3>
                <p>
                    The match consists of winning by one of the players a number of 2 sets in qualification groups, 3 sets in the semifinals, respectively 4 sets in the big and small finals. 
                </p>
                <h3>
                    Choice of service, receipt or table side
                </h3>
                <ul>
                    <li>
                        The right to choose the original order of service, receipt or table side, will be determined by drawing lots. The winner can choose between serving first, receiving first, starting on a certain side, or giving the opponent a choice.
                    </li>
                    <li>
                        When a player has chosen to serve, receive or has chosen one of the pitches, the other player can choose whatever is left.
                    </li>
                    <li>
                        Each player alternately has 2 service balls at their disposal until a winner is determined. If the score of 10-10 has been reached, each player begins to serve in turn until one of them has a lead of 2 points. 
                    </li>
                    <li>
                        After each set, players will exchange pitches.
                    </li>
                </ul>
                <h3>
                    Mistakes at work, reception, change of table side
                </h3>
                <ul>
                    <li>
                        If a player receives or serves, without being entitled to do so, the game will be interrupted as soon as the mistake has been discovered and will be resumed with those players, at service or at the reception, who were entitled to do so, according to the succession established at the beginning of the match.
                    </li>
                    <li>
                        If the players have not changed their sides when they should have done so, the game will be interrupted as soon as the mistake has been discovered and will resume with the players in the sides where they should have played in accordance with the sequence established at the beginning of the match. Under any circumstances, all points marked before the discovery of mistakes will not be undone.
                    </li>
                    <li>
                        Under any circumstances, all points marked before the discovery of mistakes will not be undone. 
                    </li>
                    </ul>
                    <h3>It is worth mentioning:</h3>
                    <div>
                        <li>
                        Any inappropriate behavior (insults to the opponent, hitting the palette, etc.) will be sanctioned at the referee's decision.
                        </li>
                        <li>
                        A player is allowed to inspect the opponent's palette.
                        </li>
                        <li>
                        The sides of the palette must have different colors.
                        </li>
                        <li>
                        A player is entitled to one minute of time-out during the match. 
                        </li>
                    </div>
                <h3>
                    Overall Ranking Score
                </h3>
                <div>
                    The teams will receive points for performance in the competition:
                    <ul>
                        <li>
                            100 points - 1st place
                        </li>
                        <li>
                            75 points -  2nd place
                        </li>
                        <li>
                            50 points -  3rd place
                        </li>
                        <li>
                            25 points -  4th place
                        </li>
            
                    </ul>
                </div>
            </div>
        </div>
    }
    </>)
}