import './Lots.sass'
import Input from '../helpers/input/Input'
import { useState, useEffect } from 'react'
import RequestService from "../../services/universalService";
import Swal from 'sweetalert2'
import Button from "../helpers/buttons/button/Button";

// Allow number of players in a lot and a maximum number of men in a lot
const players = 18;
const maxMalePlayers = 14;

const Lots = ({user, lot, facultiesLots, page}) => {
    const [searchLot, setSearchLot] = useState(null);
    const [selectedLot, setSelectedLot] = useState(null);
    const [search, setSearch] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [description, setDescription] = useState("")

    const handleDescription = (value) => {
        if (value.length < 300) {
            setDescription(value)
        }
    }

    const handleDeleteUser = (email) => {
		Swal.fire({
			title: 'Are you sure?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: 'red',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, remove teammate!'
		}).then((result) => {
			if (result.isConfirmed) {
				RequestService.ask("lots.deleteUser", {lotID: lot.lotID, email}, (result, error) => {
					if (error) {
						console.log(error)
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: 'Something went wrong!',
							timer: 1500
						})
					} else if (result && result.data) {
						Swal.fire({
							position: 'center',
							icon: 'success',
							title: 'User deleted',
							showConfirmButton: false,
							timer: 1500
						})

						setTimeout(() => {
							window.location.href = '/profile'
						}, 1500)
					}
				})
			}
		});
    }

    const handleSearch = (value) => {
        setSearchLot(null);
        if (value.length <= 6) {
            setSearch(value);
        }

        if (value.length === 6) {
            handleSearchLot(value)
        }
    }

    const handleSearchLot = (value) => {
        RequestService.ask("lots.getLot", {lotID: value}, (result, error) => {
            if (error) {
                setSearchLot(null);
            } else if (result && result.data) {
                setSearchLot(result.data);
            }
        })
    }

    const createLot = () => {
        RequestService.ask("lots.createLot", {}, (result, error) => {
            if (error) {
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 1500
                })
            } else if (result && result.data) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Lot created',
                    showConfirmButton: false,
                    timer: 1500
                })
                setTimeout(() => {
                    window.location.href = '/profile'
                }, 1500)
            }
        })
    }

    const joinLot = () => {
        RequestService.ask("lots.joinLot", {email: user.email, lotID: search}, (result, error) => {
            if (error) {
				console.log(error.response);
                if (error.response?.status === 403) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Limit of ' + players + ' players reached.',
                    });
                } else if (error.response?.status === 406) {
                    Swal.fire({
                        icon: 'error',
                        title: 'The limit of males in a lot is ' + maxMalePlayers + '.',
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        timer: 1500
                    })
                }                
            } else if (result && result.data) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Lot joined',
                    showConfirmButton: false,
                    timer: 1500
                })
                setTimeout(() => {
                    window.location.href = '/profile'
                }, 1500)
            }
        })
    }

    const sendJoinReq = (lotID, description) => {
        RequestService.ask("lots.sendJoinRequest", {lotID, description}, (result, error) => {
            if (error) {
                if (error.response?.status === 409) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Request already sent.',
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                    setTimeout(() => {
                        window.location.href = '/profile'
                    }, 1500)
                }
            } else if (result && result.data) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Request sent',
                    showConfirmButton: false,
                    timer: 1500
                })
                setTimeout(() => {
                    window.location.href = '/profile'
                }, 1500)
            }
        })
    }

    const resJoinReq = (email, response) => {
        RequestService.ask("lots.resJoinRequest", {lotID: lot.lotID, email, response}, (result, error) => {
            if (error) {
                if (error.response?.status === 409) {
                    Swal.fire({
                        icon: 'error',
                        title: 'User is alreay part of another team.',
                    })
                } else if (error.response?.status === 403) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Limit of ' + players + ' players reached.',
                    });
                } else if (error.response?.status === 406) {
                    Swal.fire({
                        icon: 'error',
                        title: 'The limit of males in a lot is ' + maxMalePlayers + '.',
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                    setTimeout(() => {
                        window.location.href = '/profile'
                    }, 3000)
                }
            } else if (result && result.data) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Response sent',
                    showConfirmButton: false,
                    timer: 1500
                })
                setTimeout(() => {
                    window.location.href = '/profile'
                }, 1500)
            }
            
        })
    }

    const exitLot = () => {
        RequestService.ask("lots.exitLot", {email: user.email, lotID: user.lotID}, (result, error) => {
            if (error) {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 1500
                })
            } else if (result && result.data) {
                // window.location.href = '/profile'
                setTimeout(() => {
                    window.location.href = '/profile'
                }, 1500)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Lot exited',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }

    useEffect(() => {
        setSearch("")
        setSearchLot(null)
    }, [page])

    return (
        <div className='lot-panel'>
                <div className="lot-container w100 h100">
                        {page === "myLot" ? 
                            user && lot ? 
							<div className='leftWrapper'>
                             <div className='w100 h100 space-around'>
                                <div className='w100 scrollable pb-3'>
                                    <div className='lot-fields'>
                                        <h3>Capitan: {lot.captain.firstName} {lot.captain.lastName}</h3>
                                    </div>
                                    <div className='lot-fields'>
                                        <h3>Facultate: {lot.faculty}</h3>
                                    </div>
                                    <div className='lot-fields'>
                                        <h3>Lot Id: {lot.lotID}</h3>
                                    </div>
                                
                                    <div className='lot-fields'>
                                        <h3 className='pb-3'>Jucatori:</h3>
                                            {
                                            lot && lot.players.length > 1 ?
                                                lot.players.slice(1).map((userLot, index) =>
                                                    <div key={index} className='grid-container'>
                                                        <div className='grid-item'>
                                                            <h4>{userLot.firstName + " " + userLot.lastName+ (lot.captain.email === user.email ? (" " + userLot.phoneNumber) : "") }</h4>
                                                        </div>
                                                        <div className='grid-item'>
                                                        {lot.captain.email === user.email &&
                                                            <button className='button-delete-user' onClick={() => handleDeleteUser(userLot.email)}><i className="fa-solid fa-trash"></i> </button>}
                                                        </div>
                                                        {index != lot.players.length - 2 && <hr />}
                                                    </div>) :
                                                    <h4>Nu exista alti participanti inscrisi in lotul tau.</h4>
                                            }
                                    </div>

                                    {
                                        lot && lot.captain.email === user.email && lot.requests.length ?
                                        <div className='lot-fields'>
                                            <h3>Cereri in asteptare:</h3>
                                            {lot.requests.map((req, index) => (
                                                <div className='req-container' key={index}>
                                                    <div className='req-fields'>
                                                        <h4>{req.user.firstName} {req.user.lastName}</h4>
                                                        <h4>{req.user.phoneNumber}</h4>
                                                    </div>
                                                    <div>
                                                        <h4>{req.description}</h4>
                                                    </div>

                                                    <div className='m-1 space-around-row'>
                                                        <Button className={"blue mb-1 w-25 p-1"} onClick={() => resJoinReq(req.user.email, "NO")} name={"decline"} type={"submit"} text={"X"} on/>
                                                        <Button className={"mb-1 w-25 p-1"} onClick={() => resJoinReq(req.user.email, "YES")} name={"accept"} type={"submit"} text={"✓"} on/>
                                                    </div>

                                                    {index != lot.requests.length - 1 && <hr />}
                                                </div>
                                            ))}
                                        </div>
                                        : <></>
                                    }
                                </div>
                             </div>
							 <div className='leave w100'>
                                    <div className='space-around-row'><Button className={"blue mb-1"} onClick={() => exitLot()} name={"leave"} type={"submit"} text={"Leave"} on/></div>
                             </div>
							</div>
                            : 
                                <h3 className='mb-5'>Nu faceti parte din niciun lot. Pentru a putea participa, creati-va un lot sau alaturati-va unui lot existent</h3>
                            : <></>
                        }

                        {page === "createLot" ? 
                            user?.lotID ? <h3 className='mb-5'>Lotul dvs este {user.lotID}. Pentru a crea un lot, este necesar sa iesiti din lotul curent</h3> :
								<div className='leftWrapper'>
									<div className='create-container'>
										<div>
											<Input type={"text"} profile="profile" name={"captain2"} ColoScheme={"default"} placeholder={"Capitan"} defaultText={user.name} icon={"fa-solid fa-user"} disabled={true}/>
										</div>
										<div>
											<Input type={"text"} profile="profile" name={"university2"} ColoScheme={"default"} placeholder={"Facultate"} defaultText={user.uni} icon={"fa-solid fa-graduation-cap"} disabled={true}/>
										</div>
									</div>
									<div className='createLotButtonWrapper'>
										<button className={"button"} onClick={() => createLot()}>
											Creaza Lot
										</button>
									</div>
								</div>
                            : <></>
                        }
                        
                        {page === "joinLot" ? 
                            user?.lotID ? <h3 className='mb-5'>Lotul dvs este {user.lotID}. Pentru a te alatura altui lot, este necesar sa iesiti din lotul curent</h3> :
                                    // -------------------------------- Inscrieri lot ------------------------------
                                    <div className='join-container w100'>
                                        <h3>Insereaza id-ul lotului:</h3>
                                        <div>
                                            <Input type={"text"} defaultText={search} name={"lotid"} ColoScheme={"default"} placeholder={"Lot Id"} icon={"fa-regular fa-id-card"} onChange={handleSearch}/>
                                        </div>
                                        {search ?
                                            <>
                                                {searchLot && searchLot.faculty === user.uni ? 
                                                <div className='req-container w100'>
                                                    <h3>Capitan: {searchLot.captain.firstName} {searchLot.captain.lastName}</h3>
                                                    <h3>Facultate: {searchLot.faculty}</h3>
                                                    <h3>Id: {searchLot.lotID}</h3>
                                                    <div className='push-right'>
                                                        <button className="button-join" onClick={joinLot}>Join</button>  
                                                    </div>
                                                </div> : <h3>Not found</h3>
                                                }
                                            </> : <></>
                                        }
                                    </div>
                                                
                                    // -------------------------------- Inscrieri individuale ------------------------------
                                    // <div className='join-container w100'>
                                    //     <h3>Loturile pentru facultatea {user.uni}:</h3>
                                    //     {   
                                    //         facultiesLots &&
                                    //         facultiesLots.map((facLot, index) => 
                                    //         <div key={index} className='req-container w100'>
                                    //             <div className='lot-req-container'>
                                    //                 <h4>Lot Id: {facLot.lotID}</h4>
                                    //                 <h4>{facLot.players.length} / 18 <i className='fa-solid fa-users'>a</i></h4>
                                    //             </div>
                                    //             <div className='lot-req-container'>
                                    //                 <h4>Capitan: {facLot.captain.firstName} {facLot.captain.lastName}</h4>
                                    //                 {
                                    //                     facLot.players.length !== 18 ? 
                                    //                         <button className="button-join" onClick={() => { 
                                    //                             setSelectedLot(facLot);
                                    //                             handleShow();
                                    //                         }}>
                                    //                         Send Join Request
                                    //                         </button>
                                    //                         : <></>
                                    //                 }
                                    //             </div>
                                    //         </div>
                                    //         )
                                    //     }
                                    //     <Modal show={show} onHide={handleClose}>
                                    //         <Modal.Header closeButton>
                                    //             <Modal.Title>Send Join Request</Modal.Title>
                                    //         </Modal.Header>
                                    //         <Modal.Body>
                                    //             <Input icon={"fa-solid fa-envelope"} className={"join-input"} type={"text"} name={"lastName"} placeholder={"Write something to impress the captain.."} defaultText={description} onChange={handleDescription}/></Modal.Body>
                                    //         <Modal.Footer>
                                    //             <button className="button-join"
                                    //                 onClick={() => {
                                    //                     sendJoinReq(selectedLot.lotID, description);
                                    //                     setSelectedLot(null)
                                    //                     setDescription("")
                                    //                     handleClose();
                                    //                 }}
                                    //             >
                                    //                 Send
                                    //             </button>
                                    //         </Modal.Footer>
                                    //     </Modal>
                                    // </div>
                            : <></>
                        }
                </div>
        </div>
    )
}

export default Lots;
