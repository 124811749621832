import './Games.sass'
import GameRow from '../helpers/gameRow/GameRow'
import { useState, useEffect } from 'react'
import RequestService from '../../services/universalService'
import io from "socket.io-client";
import Websockets from "../../Websockets/Websockets";
import constants from "../../Config/constants"
import Button from '../helpers/buttons/button/Button';
import NotificationButton from '../Notification/notificationButton/NotificationButton';
import { NotificationService, ServiceWorkerService } from '../../services/notificationService';
import Sloth from '../helpers/sloth/Sloth';
import sloth from '../../images/sloths/games/games.png';
import cloudLeft from '../../images/sloths/games/games-cloud-left.png';
import cloudRight from '../../images/sloths/games/games-cloud-right.png';
import ImageButton from '../helpers/buttons/imageButton/ImageButton';
import styles from './styles.json';

function Games(){
    const selectedSport = window.location.href.split("games/")[1];
    const [matchState, setMatchState] = useState("LIVE");
    const [games, setGames] = useState({list:[]});
	const [socket, setSocket] = useState(io);
	const [supported, setSupported] = useState(false);
	const [notificationTeams, setNotificationTeams] = useState([]);
	const [notificationCompetition, setNotificationCompetition] = useState({});
	const [styleIndex, setStyleIndex] = useState(0);

	const stylesSloth = [
		{ width: '4.5rem', top: '-2.25rem', right: '1rem' }, // Mobile
		{ width: '6.5rem', top: '-3.25rem', right: '1.5rem' } // Desktop
	];

    const { sportsNames: sports } = constants;
    const sportsMap = {
        tenisMasa: "PINGPONG",
        volei: "VOLLEYBALL",
        handbal: "HANDBALL",
        streetball: "STREETBALL",
        fotbal: "FOOTBALL",
    }

    const getMatches = (status) => {
        RequestService.ask("matches.getSpecificMatches", {status, sport: sportsMap[selectedSport]}, (result, error) => {
            if (error) {
                console.error(error)
            } else if (result.data.length > 0) {
				const competitionID = result.data[0].competitionID; // TODO: here if the tab doesn't have any match it won't show the notification button for competition
				const teamIDs = result.data.map((match) => match.teams[0]._id).concat(result.data.map((match) => match.teams[1]._id));
				const ids = [competitionID].concat(teamIDs);
				const length = teamIDs.length + 1;

				RequestService.ask("events.getUserSubscriptions", { whoID: ids, length: length }, (subscribers, error) => {
					if (error) {
						console.error(error);
					} else if (subscribers) {
						if (subscribers.data.length > 0) {
							NotificationService.updateNotificationButtonCompetition(competitionID, subscribers.data.slice(0, 1), setNotificationCompetition);
							NotificationService.updateNotificationButtonsTeams(result.data, subscribers.data.slice(1, length), teamIDs.length / 2, setNotificationTeams);
						}
						setGames({list: result.data});
					}
				});
            }
        })
    }

	const eventListeners = {
        newMatchStatus: getMatches
    }
    Websockets(constants.Namespaces.user, "", {}, eventListeners, setSocket);

    useEffect(() => { // This will update matches when tab is changed(this also covers the first initializing case)
        setGames({list: []});
        getMatches(matchState);
    }, [matchState])

    useEffect(() => {
		ServiceWorkerService.isSupported().then((result) => setSupported(result));
    }, [])

	// If the screen is resized, update sloth positioning
	useEffect(() => {
		const mediaQuery = window.matchMedia('(max-width: 59rem)');
		setStyleIndex(mediaQuery.matches ? 0 : 1);

		const handleMediaQueryChange = mediaQueryList => {
			setStyleIndex(mediaQueryList.matches ? 0 : 1);
		};

		mediaQuery.addEventListener('change', handleMediaQueryChange);
		return () => {
			mediaQuery.removeEventListener('change', handleMediaQueryChange);
		}
  	}, []);

	const handleBrackets = () => {
		window.location.href = '/brackets';
	};

	const handleRules = () => {
		window.location.href = '/rules';
	};

    return (
		<section className='dap'>
        <section className="games">
			<div className='gamesContainer'>
				<Sloth image={sloth} style={stylesSloth[styleIndex]} />
				<ImageButton onClick={handleRules} image={cloudLeft} type={'submit'} name={'cloud-rules'} text={'Rules'} top={styles.stylesLeft[styleIndex].top} right={styles.stylesLeft[styleIndex].right} imageStyle={styles.stylesLeft[styleIndex].imageStyle} textStyle={styles.stylesLeft[styleIndex].textStyle} />
				<ImageButton onClick={handleBrackets} image={cloudRight} type={'submit'} name={'cloud-brackets'} text={'Brackets'} top={styles.stylesRight[styleIndex].top} right={styles.stylesRight[styleIndex].right} imageStyle={styles.stylesRight[styleIndex].imageStyle} textStyle={styles.stylesRight[styleIndex].textStyle} />
				<div className='gamesContainerNav'>
					<div className='gamesContainerNavSport'>
						{sports[sportsMap[selectedSport]]}
						{notificationCompetition && notificationCompetition.eventInteractions &&
							<NotificationButton
								{...notificationCompetition}
								supported={supported}
								swalMessage={'this competition events'}
							/>
						}
					</div>
					<div className='gamesContainerNavButtons'>
						<Button onClick={() => setMatchState("LIVE")} text="Live" className={`gamesBtnSizing ${matchState === "LIVE" ? 'active' : ''}`} />
						<Button onClick={() => setMatchState("UPCOMING")} text="Upcoming" className={`gamesBtnSizing ${matchState === "UPCOMING" ? 'active' : ''}`} />
						<Button onClick={() => setMatchState("FINALIZAT")} text="Results" className={`gamesBtnSizing ${matchState === "FINALIZAT" ? 'active' : ''}`} />
					</div>
				</div>
				<div className='gamesList'>
					{games.list.map((match, index) =>
						<GameRow key={index} match={match} supported={supported} notificationTeam={notificationTeams[index]} />
					)}
				</div>
			</div>
        </section>
		</section>
    )
}

export default Games