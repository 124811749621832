import "./Button.sass"
export default function Button({onClick, type, name, text, isDisabled, style, className}) {
    return(
        <>
			{isDisabled ?
				<button type={type} id={name} disabled={true} name={name} className={"buttonContainer buttonDisabled " + className} style={style}>{text}</button>
				: <button onClick={() => onClick()} type={type} id={name} name={name} className={"buttonContainer " + className} style={style}>{text}</button>
			}
		</>
    )
}
