import "./Profile.sass"
import Input from "../helpers/input/Input";
import RequestService from "../../services/universalService";
import { useState, useEffect } from 'react';
import validate from "../../services/validator";
import Button from "../helpers/buttons/button/Button";
import Arrow from "../../images/goBack.svg"
import Swal from "sweetalert2";

export default function Profile({ component }) {
    const [user, setUser] = useState(null)
    const [page, setPage] = useState("profile");
    const [mobilePage, setMobilePage] = useState("default");
    const [lot, setLot] = useState(null);
    const [facultiesLots, setFacultiesLots] = useState(null);
    const [firstName, setFirstName] = useState("a");
    const [lastName, setLastName] = useState("a");
    const [phone, setPhone] = useState("a");
    const [phoneError, setphoneError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [titleOfPage] = useState({
        "profile": "Profile",
        "myLot": "My Lot",
        "joinLot": "Join Lot",
        "createLot": "Create Lot",
    })
	const [activeButton, setActiveButton] = useState(0);

	// Toggle clicked button
	const handleClick = (index, page) => {
		setActiveButton(index);
		setPage(page);
	}

    const handleLastName = (value) => {
        if (value.length < 48) {
                setLastName(value);
        }
    }
    const handleFirstName = (value) => {
        if (value.length < 72) {
            setFirstName(value);
        }
    }
    const handlePhone = (value) => {
        setPhone(value);
    }
    const getFacultiesLots = (e) => {
        RequestService.ask("lots.getFacultyLot", {}, (result, error) => {
            if (error) {
                setFacultiesLots(null);
            } else if (result && result.data) {
                setFacultiesLots(result.data);
            }
        })
    }
    const updateUser = async () => {
        const validateFirstName = validate.validate(firstName, "name", [
            { restrictionType: "minlen", value: "2" },
            { restrictionType: "maxlen", value: "72" },
            { restrictionType: "required" }
        ])

        if (!validateFirstName.valid) {
            setFirstNameError("Nume invalid")
            return;
        }

        const validateLastName = validate.validate(lastName, "name", [
            { restrictionType: "minlen", value: "2" },
            { restrictionType: "maxlen", value: "48" },
            { restrictionType: "required" }
        ])

        if (!validateLastName.valid) {
            setLastNameError("Nume invalid")
            return;
        }
       
        const validatePhone = validate.validate(phone, "phone_number", [
            { restrictionType: "required" },
        ])
        if (!validatePhone.valid) { 
            setphoneError("Numarul de telefon nu este valid");
            return;
        } else {
            setphoneError("");
        }

        const data = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            phoneNumber: phone.trim()
        }

        if (validatePhone.valid) {
            await RequestService.ask("users.updateUser", data, (result, error) => {
                if (error || !result) {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'Something went wrong!',
						timer: 1500
					})
                } else if (result) {
                    setUser(result.data);
                    setLastName(result.data.lastName);
                    setFirstName(result.data.firstName);
                    setPhone(result.data.phone);
					setTimeout(() => {
						window.location.href = '/profile';
                	}, 1500)
					Swal.fire({
						position: 'center',
						icon: 'success',
						title: 'Details saved',
						showConfirmButton: false,
						timer: 1500
					})
                }
            })
        }
    }
    useEffect(() => {
        RequestService.ask("auth.getUser", {}, (result, error) => {
            if(error || !result) {
                localStorage.removeItem("loginToken");
                window.location.href = '/login'
                return;
            }
            if(result && result.data && result.data.email) {
                setUser(result.data);
                setLastName(result.data.lastName);
                setFirstName(result.data.firstName);
                setPhone(result.data.phone);

            } else {
                localStorage.removeItem("loginToken");
                window.location.href = '/login'
                return
            }
        })
    }, [page])

    useEffect(() => {
        if (!user || !user.lotID) {
            return;
        }

        RequestService.ask("lots.getLot", {lotID: user.lotID}, (result, error) => {
            if (error) {
                console.log("Something has occured. Try again.");
            } else if (result && result.data) {
                setLot(result.data);
            }
        })
    }, [user]);

    useEffect(() => {
        setphoneError("");
        setFirstNameError("");
        setLastNameError("");
    }, [firstName, lastName, phone,])
    
    const Component = component;

    return(
		<div className="center-items">
			<div className="buttons-row">
				{user && user.role === 'PLAYER' &&
					<>
						<div className="center-button">
							<button className={`button-lot ${activeButton === 0 ? "active" : ""} ${page === "profile" ? "selected-button" : ""}`} onClick={() => {handleClick(0, "profile")}}>
								Profile
							</button>
						</div>
						<div className="center-button">
							<button className={`button-lot ${activeButton === 1 ? "active" : ""} ${page === "myLot" ? "selected-button" : ""}`} onClick={() => {handleClick(1, "myLot")}}>
								My lot
							</button>
						</div>
						<div className="center-button">
							<button className={`button-lot ${activeButton === 2 ? "active" : ""} ${page === "createLot" ? "selected-button" : ""}`} onClick={() => {handleClick(2, "createLot")}}>
								Create lot
							</button>
						</div>
						<div className="center-button">
							<button className={`button-lot ${activeButton === 3 ? "active" : ""} ${page === "joinLot" ? "selected-button" : ""}`} onClick={() => {handleClick(3, "joinLot") || getFacultiesLots()}}>
								Join lot
							</button>
						</div>
					</>
				}
			</div>
			{/* {page === "profile" ? "profileContainerSingle" : "profileContainer"} */}
			<div className={"profileContainer" + (page === "profile" ? " profileContainerSingle" : "")}>
				<div className={'container desktop' + (page === "profile" ? " singlePageContainerOverwrite" : "")}>
					<div className={"left" + (page === "profile" ? " display-none" : "")}>
						<Component page={page} user={user} lot={lot} facultiesLots={facultiesLots}/>
					</div>
					
					{/* {page === "profile" && */}
						<div className={"right" + (page === "profile" ? " singlePageRightOverwrite" : "")}>
							{user &&
								<div>
									<h2 className="text-profile">
										Profile
									</h2>

									<div className='row input-row'>
										<div>
											<Input className="inputBoxProfile" onChange={handleFirstName} value={firstName} type={"text"} profile="profile" name={"name"} ColoScheme={"default"} placeholder={"Nume"} defaultText={firstName} icon={"fa-solid fa-user"}/>
										</div>

										<div>
											{firstNameError && <span className="alert-color alert-pass">{firstNameError}</span>}
										</div>

										<div>
											<Input className="inputBoxProfile" onChange={handleLastName} value={lastName} type={"text"} profile="profile" name={"prenume"} ColoScheme={"default"} placeholder={"Prenume"} defaultText={lastName} icon={"fa-solid fa-user"}/>
										</div>

										<div>
											{lastNameError && <span className="alert-color alert-pass">{lastNameError}</span>}
										</div>

										<div>
											<Input className="inputBoxProfile" value={phone} onChange={handlePhone} type={"text"} profile="profile" name={"phone"} ColoScheme={"default"} placeholder={"Nr de telefon"} defaultText={phone} icon={"fa-solid fa-phone"} />
										</div>

										<div>
											{phoneError && <span className="alert-color alert-pass">{phoneError}</span>}
										</div>

										<div>
											<Input className="inputBoxProfile" type={"email"} profile="profile" name={"email"} ColoScheme={"default"} placeholder={"E-mail"} defaultText={user.email} icon={"fa-solid fa-envelope"} disabled={true}/>
										</div>
										<div>
											<Input className="inputBoxProfile" type={"text"} profile="profile" name={"university"} ColoScheme={"default"} placeholder={"Facultate"} defaultText={user.uni} icon={"fa-solid fa-graduation-cap"} disabled={true}/>
										</div>
										{   user.role === 'PLAYER' ?
											<div>
												<Input className="inputBoxProfile" type={"text"} profile="profile" name={"lotId"} ColoScheme={"default"} placeholder={"Lot Id"} defaultText={user.lotID || "Nu faceti parte din niciun lot"} icon={"fa-solid fa-users"} disabled={true}/>
											</div> : <></>
										}
									</div>
									<div className="button-div">
											<div><Button onClick={() => updateUser()} name={"save"} type={"submit"} text={"Save"} on/></div>
									</div>
								</div>
							}
						</div>
					{/* } */}
				</div>
				<div className={"container mobile" + (mobilePage === "default" ? " removeMargin" : "")}>
					<div className="right">
						{user &&
							<div>
								{mobilePage === 'default' && <div>
									<div className='profile-container mb-5'>
										<h2 className="text-center heading titleProfileMobile">
											Profile
										</h2>

										<div className='row input-row'>
											<div>
												<Input className="bordered inputBoxProfile" onChange={handleFirstName} hasInput={true} value={firstName} type={"text"} profile="profile" name={"name"} ColoScheme={"reverse"} placeholder={"Nume"} defaultText={firstName} icon={"fa-solid fa-user"}/>
											</div>

											<div>
												{firstNameError && <span className="alert-color alert-pass">{firstNameError}</span>}
											</div>

											<div>
												<Input className="bordered inputBoxProfile" onChange={handleLastName} hasInput={true} value={lastName} type={"text"} profile="profile" name={"prenume"} ColoScheme={"reverse"} placeholder={"Prenume"} defaultText={lastName} icon={"fa-solid fa-user"}/>
											</div>

											<div>
												{lastNameError && <span className="alert-color alert-pass">{lastNameError}</span>}
											</div>

											<div>
												<Input className="bordered inputBoxProfile" value={phone} onChange={handlePhone}  hasInput={true} type={"text"} profile="profile" name={"phone"} ColoScheme={"reverse"} placeholder={"Nr de telefon"} defaultText={phone} icon={"fa-solid fa-phone"} />
											</div>

											<div>
												{phoneError && <span className="alert-color alert-pass">{phoneError}</span>}
											</div>

											<div>
												<Input className="bordered inputBoxProfile" type={"email"} profile="profile" hasInput={true} name={"email"} ColoScheme={"reverse"} placeholder={"E-mail"} defaultText={user.email} icon={"fa-solid fa-envelope"} disabled={true}/>
											</div>
											<div>
												<Input className="bordered inputBoxProfile" type={"text"} profile="profile" hasInput={true} name={"university"} ColoScheme={"reverse"} placeholder={"Facultate"} defaultText={user.uni} icon={"fa-solid fa-graduation-cap"} disabled={true}/>
											</div>
											{   user.role === 'PLAYER' ?
												<div>
													<Input className="bordered inputBoxProfile" type={"text"} profile="profile" hasInput={true} name={"lotId"} ColoScheme={"reverse"} placeholder={"Lot Id"} defaultText={user.lotID || "Nu faceti parte din niciun lot"} icon={"fa-solid fa-users"} disabled={true}/>
												</div> : <></>
											}
										</div>
										<div className="button-div">
												<div><Button onClick={() => updateUser()} name={"save"} type={"submit"} text={"Save"} on/></div>
										</div>
									</div>
									<div className="mobile-buttons">
										<div className="w-50 center">
											<button className={`button-lot-mobile btn btn-light mb-2`} onClick={() => {setMobilePage("myLot")}}>
												My lot
											</button>
										</div>
										<div className="w-50 center">
											<button className={`button-lot-mobile btn btn-light mb-2`} onClick={() => {setMobilePage("createLot")}}>
												Create lot
											</button> 
										</div>
										<div className="w-50 center">
											<button className={`button-lot-mobile btn btn-light mb-2`} onClick={() => {setMobilePage("joinLot") || getFacultiesLots()}}>
												Join lot
											</button>
										</div>
									</div>
								</div>}
								{mobilePage !== 'default' && <div>
									<div className="back-and-title mt-4 mb-5">
										<div onClick={() => setMobilePage('default')} className="arrow-container">
											<img src={Arrow} alt="<-" className="arrow" />
										</div>
										<div className="mobile-title">
											{titleOfPage[mobilePage]}
										</div>
									</div>
									<Component page={mobilePage} user={user} lot={lot} facultiesLots={facultiesLots}/>
								</div>}
							</div>
						}
					</div>
				</div>
			</div>
		</div>
    )
}
