import "./Register.sass";
import { useEffect, useState } from "react";
import RegisterPlayer from "./registerPlayer/RegisterPlayer";
import RegisterParticipant from "./registerParticipant/RegisterParticipant";
import Button from "../buttons/button/Button";

export default function Register({ component }) {
	const [role, setRole] = useState("");
	const [marginTop, setMarginTop] = useState("");

	useEffect(() => {
		if (role === "PLAYER") {
			setMarginTop("0rem");
		} else {
			setMarginTop("");
		}
	}, [role]);

    const Component = component;

    return (
        <div className="registerContainer registerContainerMarginTop" style={{ marginTop }}>
            <div className="container">
                <div className="left">
                    <Component />
                </div>
				{role === "" ?
					<div className="right registerContainerRoleContainer" >
						<Button className={'registerContainerRoleBtn'} onClick={() => setRole('PLAYER')} text={'Register as Player'} />
						<Button className={'registerContainerRoleBtn'} onClick={() => setRole('PARTICIPANT')} text={'Register as Spectator'} />
					</div>
				: role === "PLAYER" ?
					<div className="right">
						<RegisterPlayer />
					</div>
				: role === "PARTICIPANT" ?
					<div className="right">
						<RegisterParticipant />
					</div>
				: null}
            </div>
        </div>
    )
}
