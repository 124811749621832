import './Person.sass';

function Person({ className, image, lastName, firstName }) {
	return (
		<>
			<div className={`personContainer ${className}`}>
				<img className='personImg' src={image} alt={'Ti-a cazut satelitul'} />
				<div className='personDetails'>
					<div className='personDetailsName'>
						{firstName}
					</div>
					<div className='personDetailsName'>
						{lastName}
					</div>
				</div>
			</div>
		</>
    );
}

export default Person