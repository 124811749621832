import "./Faq.sass";
import { useEffect, useState } from "react";
import Card from "../helpers/card/Card";
import ModalCustom from "../helpers/modal/Modal";
import faqs from "./data/faq.json";
import Sloth from "../helpers/sloth/Sloth";
import sloth from "../../images/sloths/faq/faq.png";

export default function Faq() {
  const [language, setLanguage] = useState("RO")
  const [answer, setAnswer] = useState("");
  const [show, setShow] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [styleIndex, setStyleIndex] = useState(0);

  const stylesSloth = [
	{ width: '4.75rem', bottom: '.25rem', right: '-1rem' }, // Mobile
	{ width: '10rem', bottom: '-.25rem', right: '-2rem' } // Desktop
  ];

  // Switch language
  const handleLanguage = () => {
    setLanguage(language === "RO" ? "ENG" : "RO");
  }

  // Choose which 'rule component/answer text' to show
  const handleAnswer = (answer) => {
    setAnswer(answer);
    setShow(true);
  };

  const handleExit = () => {
	setAnswer("");
	setShow(false);
  }

  // If the screen is resized, check if it's desktop or mobile/tablet(desktop!=media query)
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 59rem)');
    setIsMobile(mediaQuery.matches);
	setStyleIndex(mediaQuery.matches ? 0 : 1);

    const handleMediaQueryChange = mediaQueryList => {
        setIsMobile(mediaQueryList.matches);
		setStyleIndex(mediaQueryList.matches ? 0 : 1);
    };

    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
        mediaQuery.removeEventListener('change', handleMediaQueryChange);
    }
  }, []);

  const buttonText = language === "RO" ? "Mai multe" : "Learn more";

  return (
    <div className="faqContainer">
      {/* The bar that shows the faq icon on mobile */} {/* We use math library to get a random number from 1 to 3. Yes it is overengineerd but has nice UX :) */}
      <div className="faqSwitchBarMobile">
          {isMobile && <img className="faqSwitchBarImg" src={require(`../../images/faq/${faqs[Math.floor(Math.random() * 3) + 1].image}.svg`)} alt="Ti-a cazut satelitul" />}
      </div>
      {/* Container mobile sets the additional background for the card component, only on mobile */}
      <div className="faqBackgroundBorder">	
        <div className="faqBackground">
			<Sloth image={sloth} style={stylesSloth[styleIndex]} />
            <div className="faqContainerFadeEffect">
                <div className="faqContainerMobile">
                    {/* Mobile */}
                    {isMobile
                        ? (	<div className="faqContainerFadeEffectMobile">
                                <div className="faqCardsWrapperMobile">
                                    {faqs.map((faq, index) => {
                                        return (
                                            <button className="faqCardBtnMobile" key={index} onClick={() => handleAnswer(faq)}>
                                                {faq[`question${language}`]}
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                        : (faqs.map((faq, index) => {
                            {/* Desktop */}
                            return (
                                <Card key={index} text={faq[`question${language}`]} textBtn={buttonText} nameBtn={faq[`question${language}`]} image={require(`../../images/faq/${faq.image}.svg`)} seeAnswer={() => handleAnswer(faq)}/>
                            )
                        }))
                    }
                    {/* Take the vars coresponding to language and create modal */}
                    <ModalCustom title={answer[`question${language}`]} text={answer[`response${language}`]} onExit={handleExit} show={show} language={language} handleLanguage={handleLanguage} />
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}
