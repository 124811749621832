import "./Sporturi.sass"

export default function Fotbal({language}) {
    return (
    <>
        {language === "RO" ?
            <div className="sportRuleSet">
                <div className="sportRule">
                    <ul>
                        <li>
							O echipă are dreptul de a face schimbări nelimitate, cu mențiunea ca acestea să se producă la întreruperea jocului, la jumătatea terenului.
						</li>
						<li>
							<b><u>Jucătorii nu au voie cu crampoane turnate sau  filet !</u></b>
						</li>
                        <li>
							Numărul minim de jucători pentru a începe o partidă este de 5(4 pe teren și portarul).
                        </li>
                        <li>
							Durata unei partide este de <b>20 minute</b>, împărțită în două reprize de câte <b>10 minute</b>. 
                        </li>
                        <li>
							Între cele 2 reprize există o pauză de maximum 3 minute. Dacă ambele echipe decid să stea mai puțin în pauză se poate începe următoarea repriză.
                        </li>
                        <li>
							Înainte de începutul unei partide, sunt alese terenul și mingea prin aruncarea unei monede. Căpitanii sunt cei care aleg partea monezii. Dacă aceștia nu se pot decide ce parte să aleagă arbitrul o sa aleagă pentru ei.
                        </li>
                        <li>
							Aruncarea de la margine se execută cu piciorul și trebuie realizată în cel mult 6 secunde. În caz contrar, aceasta este atribuită echipei adverse. Totodată, dacă aceasta se execută incorect (mingea nu este situată pe linie, se execută cu mâna) este aplicată aceeași sanc	țiune ca cea de mai sus.
                        </li>
                        <li>
							În cazul unei lovituri libere, zidul trebuie așezat la <b>3 metri de minge</b>.
                        </li>
                        <li>
							Se interzice portarului <b>să prindă mingea în mână</b> dacă primește o pasă <b>intenționată</b> de la coechipier cu piciorul. Autul de poartă trebuie executat în 6 secunde.
                        </li>
                        <li>
							Autul de poartă poate fi executat <b>cu mâna sau piciorul</b>, în cazul în care se execută cu mâna, mingea nu poate fi degajată peste jumătatea terenului. În caz contrar, autul se va reexecuta. Dacă portarul continuă în mod repetat acest lucru, se consideră tragere de timp și se va penaliza ca atare.
                        </li>
                        <li>
							La 2 cartonașe galbene primite, jucătorul nu va mai putea evolua pe tot parcursul meciului. Echipa cu omul în minus va putea introduce un alt jucător pe teren la 2 minute de la eliminare.
                        </li>
                        <li>
							La un cartonaș roșu primit direct, jucătorul nu va mai putea evolua pe tot parcursul meciului plus un viitor meci. Echipa cu omul în minus va putea introduce un alt jucător pe teren la 2 minute de la eliminare.
                        </li>
                        <li>
                            Jocul cu mâna a mingii:
							<ul>
								<li>
									<b>intenționat</b>: se acordă lovitură liberă directă;
								</li>
								<li>
									<b>neintenționat</b>: se acordă lovitură liberă indirectă;
								</li>
							</ul>
                        </li>
                        <li>
							Se consideră comportament nesportiv și va fi sancționat cu cartonaș galben jucătorul care:
							<ul>
                                <li>
                                    Are faulturi repetate;
                                </li>
                                <li>
                                    Produce un fault mai agresiv;
                                </li>
                                <li>
                                    Joacă intenționat mingea cu mâna;
                                </li>
                                <li>
                                    Produce o schimbare fără acordul arbitrului;
                                </li>
                                <li>
                                    Oprirea unui contraatac sau atac periculos;
                                </li>
                                <li>
                                    Trage de timp;
                                </li>
                            </ul>
                        </li>
                        <li>
							Se consideră comportament nesportiv și va fi sancționat cu cartonaș roșu jucătorul care:
                            <ul>
                                <li>
                                    Produce un fault violent, ce pune în pericol integritatea fizică a altui jucător;
                                </li>
                                <li>
                                    Aduce injurii unui jucător sau arbitrului;
                                </li>
                                <li>
									Brutalizează una din persoanele participante la joc sau unul din spectatori;
                                </li>
                                <li>
                                    Acumuleaza două cartonașe galbene într-un singur meci;
                                </li>
                            </ul>
                        </li>
                        <li>
							Este interzisă alunecarea cu scopul de a deposeda unul din adversari. Această faptă este sancționată cu lovitură liberă directă.
                        </li>
                        <li>
							Le este interzis jucătorilor să intre pe teren cu accesorii ce pot pune în pericol sănătatea altor participanți: brățări, lanțuri, inele, cercei, ochelari etc.
                        </li>
						<li>
							În cazul în care jocul este întrerupt în timp ce mingea nu a depășit vreuna din limitele terenului sau nu s-a produs vreun fault, acesta va fi reluat de către arbitru și va acorda mingea echipei ce a avut ultima dată posesia.
						</li>
						<li>
							<b>Arbitrul face parte din joc</b>, prin urmare, în cazul în care acesta este atins de către balon, jocul nu va fi întrerupt.
						</li>
                        <li>
							În cazul meciurilor eliminatorii:
							<ul>
								<li>
									Dacă la finalul timpului regulamentar scorul rămâne egal, se vor executa lovituri de pedeapsă;
								</li>
								<li>
									Fiecare echipă are dreptul la 3 lovituri de pedeapsă, departajarea făcându-se prin numărul de goluri marcate de către fiecare.
								</li>
								<li>
									În cazul egalității după cele 3 lovituri, se vor continua acestea cu serii 1 la 1: dacă una din echipe ratează, iar cealaltă va înscrie, echipa care a înscris va fi considerată câștigătoare.
								</li>
							</ul>
						</li>
					<li>
						O echipă are la dispoziție 10 minute pentru a se prezenta la jocul programat. În cazul în care nu ajunge la timp, va pierde meciul la masa verde, fiind atribuită victoria echipei prezente cu scorul de 3-0. În cazul în care ambele echipe nu se prezintă la timp, jocul va fi anulat, iar niciuna dintre echipe nu va fi recompensată.
					</li>
                    </ul>
                    <h3>
                    	STRUCTURĂ POSIBILĂ
                    </h3>
                    <ol>
                        <li>
                            Faza grupelor:
                            <ul>
                                <li>
                                    4 grupe
                                </li>
                                <li>
                                    3 grupe de câte 4 echipe și o grupă de 3 echipe
                                </li>
								<li>
									Trece mai departe prima echipă clasată din fiecare grupă
								</li>
                                <li>
                                	Punctajul este acordat în felul următor:
                                    <ul>
                                        <li>
                                            3p pentru victorie
                                        </li>
                                        <li>
                                            1p pentru egal
                                        </li>
                                        <li>
                                            0p pentru înfrângere
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            Semifinale (se joacă un singur meci eliminatoriu)
                        </li>
                        <li>
                            Finală mare și finală mică (se joacă un singur meci)
                        </li>
                    </ol>
                    <h3>
                        CRITERII DE DEPARTAJARE GRUPE
                    </h3>
                    <div>        
                        <ul>
                            <li>
								În cazul în care două echipe se află la egalitate de puncte, vor fi departajate prin: 
                                <ul type="1">
                                    <li>
                                        Rezultatul direct
                                    </li>
                                    <li>
                                        Golaverajul 
                                    </li>
                                    <li>
                                        Numărul de goluri marcate
                                    </li>
                                    <li>
                                        Numărul de cartonașe galbene
                                    </li>
                                    <li>
                                        Numărul de cartonașe roșii (cartonașele roșii acordate prin cumularea a două cartonașe galbene sunt luate în considerare pentru calcul)
                                    </li>
                                    <li>
                                        Media de vârstă
                                    </li>
                                    <li>
										Tragere la sorți
                                    </li>
                                </ul>
                            </li>
							<li>
								În cazul în care trei echipe se află la egalitate, se respectă aceleași criterii de departajare, cu mențiunea că nu se va ține cont de statisticile obținute în meciul cu cea de-a patra echipă acolo unde este cazul.
							</li>
                        </ul>
                    </div>
                    <h3>
                        CONDUITA SPORTIVA
                    </h3>
                    <div>
						Se aplică regulamentul din secțiunea <b>”Conduita sportivilor”</b> și <b>”Conduita suporterilor”</b> din cadrul <b>Regulament General</b>.
                    </div>
                    <h3>
                        Punctaj Clasament
                    </h3>
                    <div>
                        Vor primi puncte pentru performanță în cadrul competiției:
                        <ul>
                            <li>
                                100 puncte - locul 1
                            </li>
                            <li>
                                75 puncte - locul 2
                            </li>
                            <li>
                                50 puncte - locul 3
                            </li>
                            <li>
                                25 puncte - locul 4
                            </li>
                        </ul>
                    </div>
					<p>
						<i>Menționăm faptul că echipa de organizare își rezervă dreptul de a modifica prezentul regulament în cazul apariției unor situații neprevăzute.</i>
					</p>
					<p>
						<i>De asemenea, fiecare participant în momentul în care se înscrie la evenimentul Poli Olympics își asumă riscurile unor eventuale accidentări venite din situații mai mult sau mai puțin sportive, echipa de organizare neasumându-și responsabilitatea pentru posibilele accidentări ce ar putea surveni. Totuși, echipa de organizare își asumă responsabilitatea asigurării tuturor condițiilor necesare desfășurării evenimentului în condițiile de siguranță impuse prin lege.</i>
					</p>
                </div>
            </div> :
            // ------------------------------------------- Engleza -------------------------------------------
            <div className="sportRuleSet">
                <div className="sportRule">
                    <h4>
                        The competition is held according to the <a href="https://www.frf.ro/documente-frf/statut-si-regulamente/">FHR regulation</a>, with the following mentions:
                    </h4>
                    <ul>
                        <li>
                            A match will be played between two teams, provided that there are 5 players and the goalkeeper on the field
                        </li>
                        <li>
                            A team has the right to make unlimited changes, with the mention that they occur at the interruption of the game, at the halfway point of the field.
                        </li>
                        <li>
                            The minimum number of players to start a game is 5 (4 on the field and the goalkeeper).
                        </li>
                        <li>
                            The duration of a game is 20 minutes, divided into two halves of 10 minutes each.
                        </li>
                        <li>
                            Before the beginning of the game, the court and the ball are chosen by throwing a coin. It is the captains who choose the currency side.
                        </li>
                        <li>
                            The throw-in is executed with the foot and must be done in no more than 6 seconds. Otherwise, it is assigned to the opposing team. At the same time, if it is executed incorrectly (the ball is not located on the line, it is executed by hand) the same sanction is applied as the one above.
                        </li>
                        <li>
                            In case of a free kick, the wall must be placed 3 meters away from the ball.
                        </li>
                        <li>
                            The goalkeeper is forbidden to catch the ball in his hand if he receives an intentional pass from his teammate with his foot. The goal kick must be executed in 6 seconds.
                        </li>
                        <li>
                            On a yellow card received, the player is forced to leave the field for 2 minutes, after which he will be able to return.
                        </li>
                        <li>
                            With 2 yellow cards received, the player will not be able to perform throughout the rest of the match. The team with the man in minus will be able to introduce another player on the field 2 minutes after elimination.
                        </li>
                        <li>
                            For a red card directly received, the player will not be able to perform throughout the match plus a future match. The team with the man in minus will be able to introduce another player on the field 2 minutes after elimination.
                        </li>
                        <li>
                            It is considered <u>unsportsmanlike behavior</u> and will be sanctioned with <u>yellow card</u> the player who:
                            <ul>
                                <li>
                                    Commits repeated fouls;
                                </li>
                                <li>
                                    Commits a foul with high intensity;
                                </li>
                                <li>
                                    Intentionally uses his hand to play the ball;
                                </li>
                                <li>
                                    Produces a substitution without permission
                                </li>
                            </ul>
                        </li>
                        <li>
                            It is considered  <u>unsportsmanlike behavior </u> and will be sanctioned with <u>red card</u> the player who: 
                            <ul>
                                <li>
                                    Commits a violent foul, which puts in danger the integrity of another player;
                                </li>
                                <li>
                                    Punches another player, referee or any of the spectators;
                                </li>
                                <li>
                                    Brings insults to a player or referee;
                                </li>
                                <li>
                                    Accumulate two yellow cards in a single match
                                </li>
                                
                            </ul>
                        </li>
                        <li>
                            It is forbidden to slide tackle in order to dispossess one of the opponents. This is sanctioned with a direct free kick.
                        </li>
                        <li>
                            It is forbidden for players to enter the field with accessories that can put in danger the integrity of other participants: bracelets, chains, rings, earrings, glasses, etc.
                        </li>
                        <li>
                            If the game is interrupted while the ball has not exceeded any of the limits of the field or no foul has occurred, it will be resumed by the referee and will award the ball to the team that last had possession.
                            </li>
                            <li>
                                The referee is not part of the game, therefore, if he is touched by the ball, the game will be interrupted and resumed with a referee ball.
                            </li>
                            <li>
                                For the knockout matches:
                            <ul>
                                <li>
                                    If at the end of the regular time the score remains equal, penalty kicks will be executed;
                                </li>
                                <li>
                                    Each team will take 3 penalty kicks, the tiebreaker being made by the number of goals scored by each.
                                </li>
                                <li>
                                    In case of equality after the 3 strokes, they will continue with a series: if one of the teams misses, the other will be declared the winner.
                                </li>
                                
                                </ul>
                                
                            </li>
                            <li>	A team has 5 minutes to attend the scheduled game. If no one arrives in time, the game will be considered lost at the green table, being awarded the victory of the present team with the score 3-0. If both teams do not show up on time, the game will be canceled and none of the teams will be rewarded.</li>
                    </ul>
                    <h4>
                        BRACKETS:
                    </h4>
                    <ol>
                        <li>
                            <b>Group phase:</b>
                            <ul>
                                <li>
                                3 groups of 4 teams and a group of 3 teams;
                                </li>
                                <li>
                                    First team from each group advance to the next round;
                                </li>
                                <li>
                                    The points are awarded as follows:
                                    <ul>
                                        <li>
                                            3p for win;
                                        </li>
                                        <li>
                                            1p for draw;
                                        </li>
                                        <li>
                                            0p for loss;
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            Semi-finals (one match)
                        </li>
                        <li>
                            Finals (one match)
                        </li>
            
                    </ol>
                    <h3>
                        <b>CRITERIA FOR TIEBREAKING GROUPS:</b>
                    </h3>
                    <div>        
                        <ul>
                            <li>If two teams are tied on points, they will be separated by: 
                                <ul type="1">
                                    <li>
                                        Direct match;
                                    </li>
                                    <li>
                                        Goal difference;
                                    </li>
                                    <li>
                                        Number of goals scored;
                                    </li>
                                    <li>
                                        Number of yellow cards received;
                                        </li>
                                        <li>
                                            Random draw;
                                        </li>
                                </ul>
                            </li>
                           
                
                        </ul>
                        <div>
                            If three teams are tied, the same tiebreaker criteria are respected, with the mention that the statistics obtained in the match with the fourth team will not be taken into consideration.
                        </div>
                    </div>
                    <h3>
                        CONDUCT AND BEHAVIOR:
                    </h3>
                    <div>
                        It's very important for each participant in the competition to have disciplinary behavior, respecting the imposed rules.
                        <br/>If one of the participants has an inappropriate behavior and causes problems at a high level inside the sports base, he will be excluded from the competition. If his attitude makes it impossible to play the match that is being played, the game will be stopped and the team that produced the problem will lose the game at the green table.
                        <br/><br/>
                    </div>
                    <h3>
                        Overall Ranking Score
                    </h3>
                    <div>
                        The teams will receive points for performance in the competition:
                        <ul>
                            <li>
                                100 points - 1st place
                            </li>
                            <li>
                                75 points -  2nd place
                            </li>
                            <li>
                                50 points -  3rd place
                            </li>
                            <li>
                                25 points -  4th place
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        }
    </>)
}