import "./Login.sass"
import { useState } from "react";
import Input from "../helpers/input/Input";
import Button from "../helpers/buttons/button/Button";
import RequestService from "../../services/universalService";

export default function Login() {
    const [emailLogin, setEmail] = useState("");
    const [passwordLogin, setPassword] = useState("");

    const [emailError, setemailError] = useState("");
    const [passError, setpassError] = useState("");
    const handleEmail = (value) => {
        setEmail(value);
    }
    const handlePassword = (value) => {
        setPassword(value);
    }
    const sendLoginRequest = async () => {
        if (!emailLogin) {
            setemailError("Camp obligatoriu");
            return;
        } else {
            setemailError("");
        }

        if (!passwordLogin) {
            setpassError("Camp obligatoriu");
            return;
        } else {
            setpassError("");
        }

        await RequestService.ask("auth.login", { email: emailLogin, password: passwordLogin }, function (result, error) {
            if (error) {
                console.log(error);
                setemailError("Email sau parola incorecte");
                return;
            }
            localStorage.setItem("loginToken", result.data.token);
            window.location.href = "/";
        });

    }
    const handleLoginSubmit = (e) => {
        e.preventDefault();
        sendLoginRequest();
    }
    return (
        <form action="/" method="post" className="loginContainer" onSubmit={handleLoginSubmit}>
            <div className="formBody">
                <h1>Conectare</h1>
                <div className="inputs">
                    <Input type={"email"} name={emailLogin} ColoScheme={"default"} placeholder={"Email"} icon={"fa-regular fa-envelope"} defaultText={emailLogin} onChange={handleEmail} />
                    {emailError && <span className="alert-color">{emailError}</span>}
                    <Input type={"password"} name={passwordLogin} ColoScheme={"default"} placeholder={"Parolă"} icon={"fa-solid fa-key"} defaultText={passwordLogin} onChange={handlePassword} />
                    {passError && <span className="alert-color">{passError}</span>}
                    <a className="forgotPass" href="/forgotPassword">Ai uitat parola?</a>
                </div>
            </div>
            <Button className="signinBtn" name={"login"} type={"submit"} onClick={() => {}} text={"Conectare"} style={{ background: "#FFFFFF", color: "#454445" }} />
        </form>
    )
}