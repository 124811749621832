// Here happens the logic of subscribing to an event and the component ui of the button itself
// Approach: look at this file as 'EVENT SIDE'
// Logic: every button has an ENTIRE subscription by itself. This way same button works independently for all instances.
// We have done this in order to separate the subscription logic from the everything else and to be able to use on events
// like 'x team won the game' you have multiple games mapped and this way you will be able to manipulate the events easier.
// Overall: 'serviceWorker.js' stores sw manipulation logic, 'notificationService.js' stores
// device manipulation logic and 'notificationButton.jsx' stores the ui and event manipulation logic
import React from "react";
import "./NotificationButton.sass";
import { ServiceWorkerService } from "../../../services/notificationService";
import Swal from "sweetalert2";
import RequestService from "../../../services/universalService";
import BellFilledIcon from "../../../images/notifications/react/BellFilledIcon";
import BellHollowIcon from "../../../images/notifications/react/BellHollowIcon";

// 'eventInteractions' is an object that contains: 'who', 'what', 'where'
// 'eventIdentifiers' is an object that contains: 'whoID', 'whereID'
const NotificationButton = ({ eventInteractions, eventIdentifiers, subscribed, toggleSubscription, supported, swalMessage, style, color }) => {
	const handleClick = async () => {
		if (localStorage.getItem('loginToken') === null) {
			window.location.href = '/login';
			return;
		}

		const message = await ServiceWorkerService.isServiceWorkerRegistered();
		if (message != "success") {
			console.error('Service Worker registration failed');
			Swal.fire({ title: 'Service Worker registration failed', text: message, icon: 'error', showConfirmButton: false, timer: 2000 });
			return;
		}

		const data = {
			...eventInteractions,
			...eventIdentifiers,
		};

		RequestService.ask(`events.${subscribed ? 'unsubscribe' : 'subscribe'}`, data, (result, error) => {
			if (error) {
				Swal.fire({ icon: 'error', title: 'Internal server error', text: error.response.data, showConfirmButton: false, timer: 2000 })
			} else {
				toggleSubscription();
				Swal.fire({ icon: 'success', title: `${subscribed ? 'Unsubscribed' : 'Subscribed'} successfully ${subscribed ? 'from' : 'to'} ${swalMessage}!`, showConfirmButton: false, timer: 1000 });
			}
		});
	}

	return (
		<>
			{supported ?
				<button className='notificationBtn' id='livescore-card-notification-btn' onClick={() => handleClick()}>
					{subscribed ? <BellFilledIcon className="notificationBtnIcon" style={style} color={color} />
								: <BellHollowIcon className="notificationBtnIcon" style={style} color={color} />}
				</button>
			: null}
		</>
  	);
};

export default NotificationButton;