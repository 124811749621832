import './ContactPerson.sass';

function ContactPerson({ className, image, name, role, phone, email, roleColor }) {
	return (
		<>
			<div className='contactPersonContainer'>
				{phone && email ?
					<div className={`contactPersonRelations ${className}`}>
						<img className='contactPersonImg' src={image} alt={'Ti-a cazut satelitul'} />
						<div className='contactPersonInfo'>
							<div className='contactPersonHeader'>
								<div className='contactPersonName contactPersonText'>{name}</div>
								<div className='contactPersonRole contactPersonText' style={{ color: roleColor }}>{role}</div>
							</div>
							<div className='contactPersonFooter'>
								<div className='contactPersonPhoneRow'>
	                                <i className='contactPersonIcon fa-solid fa-phone icon' />
									<div className='contactPersonPhone contactPersonTextContact'>{phone}</div>
								</div>
								<div className='contactPersonEmailRow'>
									<i className='contactPersonIcon fa-regular fa-envelope'></i>
									<div className='contactPersonEmail contactPersonTextContact'>{email}</div>
								</div>
							</div>
						</div>
					</div>
				:	<div className='contactPersonOrg'>
						<img className='contactPersonImg' src={image} alt={'Ti-a cazut satelitul'} />
						<div className='contactPersonOrgDetailsRow'>
							<div className='contactPersonName contactPersonText'>{name}</div>
							<div className='contactPersonRole contactPersonText' style={{ color: roleColor }}>{role}</div>
						</div>
					</div>
				}
			</div>
		</>
    );
}

export default ContactPerson