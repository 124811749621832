const constants = Object.freeze({
    API_ADDR: process.env.REACT_APP_API_URL,
    BACKEND_URL: process.env.REACT_APP_SOCKETS_URL,
	VAPID_PUBLIC_KEY: process.env.REACT_APP_VAPID_PUBLIC_KEY,
	EVENT_DATE: process.env.REACT_APP_EVENT_DATE,
	MAPBOX_API_TOKEN: process.env.REACT_APP_MAPBOX_API_TOKEN,
	MAPBOX_LOCATION: process.env.REACT_APP_MAPBOX_LOCATION,
    Namespaces:{
        user:"users",
        admin:"admins"
    },
    sports: {
        "Fotbal": "FOOTBALL",
        "Handbal": "HANDBALL",
        "StreetBall": "STREETBALL",
        "Tenis de masa": "PINGPONG",
        "Volei": "VOLLEYBALL",
    },
    sportsNames: {
        "FOOTBALL": "Fotbal",
        "HANDBALL": "Handbal",
        "STREETBALL": "StreetBall",
        "PINGPONG": "Tenis de masa",
        "VOLLEYBALL": "Volei",
    },
})
export default constants