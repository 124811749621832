import { useEffect, useState } from 'react';
import NotificationButton from '../../Notification/notificationButton/NotificationButton';
import './Group.sass'

const Group = ({ group, groupsFinished, supported, notificationGroup }) => {
	const [notification, setNotification] = useState(notificationGroup);

	useEffect(() => {
		setNotification(notificationGroup);
	}, [notificationGroup]);

    return (
		<div className='group'>
			<div className='groupNav'>
				<div className='groupNavText'>
					{`Group ${group.group}`}
				</div>
				{notificationGroup && notificationGroup.eventInteractions &&
					<NotificationButton
						{...notification}
						supported={supported}
						swalMessage={'this group events'}
						style={{ maxWidth: '1.25rem', minWidth: '.75rem' }}
						color={'rgba(255, 255, 255, 1)'}
					/>
				}
			</div>
			<div className='groupContainer'>
				<div className='groupContainerTable'>
					<div className='groupContainerTableNav'>
						<div className='groupContainerTableNavCol'>Rank</div>
						<div className='groupContainerTableNavCol'>Facultate</div>
						<div className='groupContainerTableNavCol'>Puncte</div>
						<div className='groupContainerTableNavCol'>Puncte marcate</div>
						<div className='groupContainerTableNavCol'>Puncte primite</div>
						<div className='groupContainerTableNavCol'>Istoric</div>
					</div>
					{group.teams.map((team, index) => (
						<div className={`groupContainerTableRow ${groupsFinished && index === 0 ? 'groupContainerTableWinner' : ''}`} key={index}>
							<div className='groupContainerTableRowCol'>{index + 1}</div>
							<div className='groupContainerTableRowCol'>{team.faculty}</div>
							<div className='groupContainerTableRowCol'>{team.points}</div>
							<div className='groupContainerTableRowCol'>{team.scored}</div>
							<div className='groupContainerTableRowCol'>{team.received}</div>
							<div className='groupContainerTableRowContainer'>
								{team.history?.map((result, index) => (
									<div key={index} className='groupContainerTableRowContainerMark'>{result}</div>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
    );
};

export default Group;
