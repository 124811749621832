import LivescoreCard from '../helpers/liveCard/LiveCard';
import {useState, useEffect} from 'react';
import RequestService from '../../services/universalService';
import io from "socket.io-client";
import Websockets from "../../Websockets/Websockets";
import constants from '../../Config/constants';
import './Livescore.sass'
import NotificationModalButton from '../Notification/notificationModalButton/NotificationModalButton';
import { ServiceWorkerService, NotificationService } from '../../services/notificationService';
import sloth from '../../images/sloths/livescore/livescore.png';
import Sloth from '../helpers/sloth/Sloth';
import cloud from '../../images/sloths/livescore/livescore-cloud.png';
import ImageButton from '../helpers/buttons/imageButton/ImageButton';
import styles from './styles.json';

function Livescore() {
    const [matches, setMatches] = useState({list:[]});
    const [socket, setSocket] = useState(io);
	const [supported, setSupported] = useState(false);
	const [notificationMatches, setNotificationMatches] = useState([]);
	const [notificationTeams, setNotificationTeams] = useState([]);
	const [styleIndex, setStyleIndex] = useState(0);

	const stylesSloth = [
		{ width: '8rem', top: '-2.5rem', left: '1.5rem' }, // Mobile
		{ width: '12rem', top: '-3.75rem', left: '2.5rem' } // Desktop
	];

    const getMatches = () => {
        RequestService.ask("matches.getSpecificMatches", { status: "LIVE" }, (result, error) => {
        if (error) {
            console.error(error);
        } else if (result.data.length > 0) {
			const matchIDs = result.data.map((match) => match.id);
			const teamIDs = result.data.map((match) => match.teams[0]._id).concat(result.data.map((match) => match.teams[1]._id));
			const ids = matchIDs.concat(teamIDs);
			const length = matchIDs.length + teamIDs.length;

			RequestService.ask("events.getUserSubscriptions", { whoID: ids, length: length }, (subscribers, error) => {
				if (error) {
					console.error(error);
				} else if (subscribers) {
					if (subscribers.data.length > 0) {
						NotificationService.updateNotificationButtonsMatches(matchIDs, subscribers.data.slice(0, result.data.length), setNotificationMatches);
						NotificationService.updateNotificationButtonsTeams(result.data, subscribers.data.slice(result.data.length, length), teamIDs.length / 2, setNotificationTeams);
					}	
					setMatches({ list: result.data });
				}
			});
		}});
    };

    const eventListeners = {
        newMatchStatus: getMatches
    }
    Websockets(constants.Namespaces.user, "", {}, eventListeners, setSocket);

    useEffect(() => {
		ServiceWorkerService.isSupported().then((result) => setSupported(result));
		
		// First time loading page, initialize matches
		setMatches({list: []});
		getMatches();
    }, [])

	// If the screen is resized, update sloth positioning
	useEffect(() => {
			const mediaQuery = window.matchMedia('(max-width: 59rem)');
			setStyleIndex(mediaQuery.matches ? 0 : 1);

			const handleMediaQueryChange = mediaQueryList => {
				setStyleIndex(mediaQueryList.matches ? 0 : 1);
			};

			mediaQuery.addEventListener('change', handleMediaQueryChange);
			return () => {
				mediaQuery.removeEventListener('change', handleMediaQueryChange);
			}
	}, []);

    return (
        <div className="livescore">
            <div className='livescore-container'>
				<NotificationModalButton image={cloud} type={'submit'} name={'cloud-livescore'} text={'Nu-mi merg notificarile!'} top={styles[styleIndex].top} left={styles[styleIndex].left} imageStyle={styles[styleIndex].imageStyle} textStyle={styles[styleIndex].textStyle} />
				<Sloth image={sloth} style={stylesSloth[styleIndex]} />
				<div className='livescoreContainerFadeEffect'>
					<div className="livescoreScrollable row">
						{
							matches.list.length === 0 && <div className='placeholder-live'>Nu exista meciuri live in acest moment.</div>
						}
						{matches.list.map((match, index) => (
							<div key={match.id} className="col-12 col-lg-6 col-xl-4 mb-4">
								<LivescoreCard
									match={match}
									index={index}
									supported={supported}
									notificationMatch={notificationMatches[index]}
									notificationTeam={notificationTeams[index]}
								/>
							</div>
						))}
					</div>
				</div>
            </div>
        </div>
    );
}

export default Livescore;