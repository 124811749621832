import { useEffect } from "react";
import io from "socket.io-client";
import constants from "../Config/constants";

const SOCKET_SERVER_URL = constants.BACKEND_URL;
const EnumWSpaths = constants.Namespaces;

/**
 * Initializes a Websocket Connection using Socket.IO
 * @requires socket.io
 *
 * @param namespace name of the namespace where the client will be connected
 * @param roomID the room where to connect the client
 * @param headers headers required for connection
 * @param events a dictionary of key: eventName, value: function(msg) used to listen for events from server
 * @param setSocket a state setter that will save the WS connection in order to be able to emit events to server
 *
 * @return A socket connection using setSocket
 *
 * @author Stoica Mihai
 */
const WShelper = (namespace, roomID, headers, events, setSocket) => {
  useEffect(() => {
    let socketRef = null;
    // if(namespace === EnumWSpaths.admin && !headers.authorization) return
    // debugger;
    socketRef = io(`${SOCKET_SERVER_URL}${namespace}`, {
      // query: {roomId:roomID,},
      // extraHeaders:{...headers},
      // withCredentials: true,
      transports: ["websocket"]
    });
    socketRef.onAny((eventName, msg) => {
      if (events[eventName]) events[eventName](msg);
    });
    setSocket(socketRef);
    return () => {
      socketRef.removeAllListeners();
      socketRef.disconnect();
    };
  }, []);
};

export default WShelper;
