import React, { useRef, useEffect } from "react";
import RequestService from "../../services/universalService";
import constants from "../../Config/constants";
import './Minimap.sass';
import FieldsData from './data/fields.geojson';
import EntrancesData from './data/entrances.geojson';
import PathsData from './data/paths.geojson';
import LayerStyles from './layerStyles/layerStyles.json';
import Settings from './settings.json';
import markerICON from '../../images/marker.png';
import MapService from "../../services/mapService";
import Game from "./game/Game";

/*
COLORS(blue, orange, blue-greenish):
rgba(0, 148, 148, 1)
rgba(255, 87, 41, 1)
rgba(0, 148, 148, 1)
*/

// To set the fields correctly: set the polygons for and theoretical numbers for the fields. Then in backend set the field for matches as this theoretical number.
const fieldsNumber = 9;
const drawings = {
	fields: FieldsData,
	entrances: EntrancesData,
	paths: PathsData,
};
const fieldNames = [
	"Teren 1 - Iris",
	"Teren 2 - Iris",
	"Teren 3 - UTCB",
	"Teren 4 - UTCB",
	"Teren 5 - UTCB",
	"Teren 6 - UTCB",
	"Teren 7 - TEI",
	"Teren 8 - TEI",
	"Teren 9 - UTCB",
];

const Minimap = () => {
    const mapContainer = useRef(null);
    const maps = useRef(null);
	const index = Settings.indexes[`${constants.MAPBOX_LOCATION}`];

	const buildField = (value) => {
		const fieldObject = {};
		for (let i = 1; i <= value; i++) {
			fieldObject[i] = async () => {
				const matches = await getMatches(i);
				return (
					<>
						<div className="mapboxgl-popup-header">{fieldNames[i - 1]}</div>
						{
							matches.map((match, index) => {
								return (
									<>
										<Game key={index} match={match} />
									</>
								);
							})
						}
					</>
				);
			};
		}
		return fieldObject;
	};	

	const getMatches = async (field) => {
		return new Promise((resolve, reject) => {
			RequestService.ask("matches.getMatchesByField", {field}, (result, error) => {
				if (error) {
					console.error(error);
					resolve([]);
				} else if (result) {
					resolve(result.data);
				}
			});
		});
	};

    useEffect(() => {
        if (maps.current) return; // initialize map only once
		maps.current = MapService.createMap(mapContainer, Settings, index);

		const field = buildField(fieldsNumber);
        maps.current.on('style.load', async () => {			
			MapService.addLayers(maps.current, drawings.fields, LayerStyles.field); // Fields polygons
			MapService.addLayers(maps.current, drawings.paths, LayerStyles.path); // Paths routes
			MapService.addImgLayers(maps.current, drawings.entrances, LayerStyles.entrance, markerICON); // Entrances points
			MapService.addMouseEvents(maps.current, LayerStyles.field.source, field); // Matches on fields
        });

		MapService.addControls(maps.current);
    }, []);

    return (
        <div className="map-div">
            <div ref={mapContainer} className="map-container" />
        </div>
    );
};

export default Minimap;
