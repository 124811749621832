import { useState, useEffect } from 'react';
import constants from '../../Config/constants';
import RequestService from '../../services/universalService';
import Group from './group/Group';
import Bracket from '../Brackets/Bracket/BracketHelper';
import Button from '../helpers/buttons/button/Button';
import './Groups.sass';
import { NotificationService, ServiceWorkerService } from '../../services/notificationService';

export default function Brackets({ sport }) {
    const [groupsData, setGroupsData] = useState({list:[]});
    const [bracketData, setBracketData] = useState({})
    const [groupsFinished, setGroupsFinished] = useState(false);
    const [competitionFinished, setCompetitionFinished] = useState(false);
    const [sportKey, setSportKey] = useState(sport || 'Fotbal');
	const [supported, setSupported] = useState(false);
	const [notificationGroups, setNotificationGroups] = useState([]);
	const [notificationBrackets, setNotificationBrackets] = useState([]);
    const { sports } = constants;

    useEffect(() => {

        RequestService.ask('competitions.getCompetition', { sport: sports[sportKey] }, (result, error) => {
            if (error) {
                setGroupsData({list: []});
                setBracketData({})
                setGroupsFinished(false);
                setCompetitionFinished(false);
				console.error(error);
            } else if (result) {
                const { brackets } = result.data;

                const data = brackets && {
                    semifinal1: brackets.semifinal1 ? {
                        team1:  {
                            name: brackets.semifinal1?.teams[0].faculty,
                            score: brackets.semifinal1?.scores[0],
                        },
                        team2:  {
                            name: brackets.semifinal1?.teams[1]?.faculty,
                            score: brackets.semifinal1?.scores[1],
                        },
                        status: brackets.semifinal1?.status
                    } : {},
                    semifinal2: {
                        team1:  {
                            name: brackets.semifinal2?.teams[0].faculty,
                            score: brackets.semifinal2?.scores[0],
                        },
                        team2:  {
                            name: brackets.semifinal2?.teams[1]?.faculty,
                            score: brackets.semifinal2?.scores[1],
                        },
                        status: brackets.semifinal2?.status
                    },
                    final: {
                        team1:  {
                            name: brackets.final?.teams[0].faculty,
                            score: brackets.final?.scores[0],
                        },
                        team2:  {
                            name: brackets.final?.teams[1]?.faculty,
                            score: brackets.final?.scores[1],
                        },
                        status: brackets.final?.status
                    },
                    bronze: {
                        team1:  {
                            name: brackets.bronze?.teams[0].faculty,
                            score: brackets.bronze?.scores[0],
                        },
                        team2:  {
                            name: brackets.bronze?.teams[1]?.faculty,
                            score: brackets.bronze?.scores[1],
                        },
                        status: brackets.bronze?.status
                    }
                };
				const bracketString = ['Semifinal 1', 'Semifinal 2', 'Final', 'Bronze'];
				const whoIDs = result.data.groups.map((group) => group.group).concat(bracketString);
				const whereIDs = Array.from({ length: whoIDs.length }, () => result.data._id);
				const wheres = Array.from({ length: whoIDs.length }, () => 'COMPETITION');
				const length = whoIDs.length;
				const bracketArray = brackets && [brackets.semifinal1 ? "Semifinal 1" : undefined, brackets.semifinal2 ? "Semifinal 2" : undefined, brackets.final ? "Final" : undefined, brackets.bronze ? "Bronze" : undefined]
					.filter(bracket => bracket !== undefined);

				RequestService.ask('events.getUserSubscriptions', { where: wheres, whoID: whoIDs, whereID: whereIDs, length: length}, (subscribers, error) => {
					if (error) {
						console.error(error);
					} else if (subscribers) {
						if (subscribers.data.length > 0) {
							NotificationService.updateNotificationButtonsGroups('COMPETITION', result.data.groups, result.data._id, subscribers.data.slice(0, result.data.groups.length), setNotificationGroups);
							result.data.groupsFinished && bracketArray && NotificationService.updateNotificationButtonsBrackets('COMPETITION', bracketArray, result.data._id, subscribers.data.slice(result.data.groups.length, length), setNotificationBrackets);
						}
						setGroupsFinished(result.data.groupsFinished);
						setCompetitionFinished(result.data.competitionFinished);
						result.data.groupsFinished && setBracketData(data);
						setGroupsData({list: result.data.groups});					
					}
				})
            }
        });
    }, [sportKey]);

	useEffect(() => {
		ServiceWorkerService.isSupported().then((result) => setSupported(result));
	}, []);

    return (
		<section className='groups'>
			<div className='groupsContainer'>
				<div className='groupsContainerNav'>
					<div className='groupsContainerNavButtons1'>
						<Button onClick={() => setSportKey('Fotbal')} text='Fotbal' className={`groupsBtn1 ${sportKey === 'Fotbal' ? 'active' : ''}`} />
						<Button onClick={() => setSportKey('Volei')} text='Volei' className={`groupsBtn1 ${sportKey === 'Volei' ? 'active' : ''}`} />
						<Button onClick={() => setSportKey('StreetBall')} text='StreetBall' className={`groupsBtn1 ${sportKey === 'StreetBall' ? 'active' : ''}`} />
					</div>
					<div className='groupsContainerNavButtons2'>
						<Button onClick={() => setSportKey('Tenis de masa')} text='Tenis de masa' className={`groupsBtn2 ${sportKey === 'Tenis de masa' ? 'active' : ''}`} />
						<Button onClick={() => setSportKey('Handbal')} text='Handbal' className={`groupsBtn2 ${sportKey === 'Handbal' ? 'active' : ''}`} />						
					</div>
				</div>

				{sportKey === 'Volei' ?
					<div className='groupsContainerContent groupsContainerVolleyball'>
						<div>
							Unfortunately, volleyball has an elimination format. You can still view the brackets on challonge: <a href='https://challonge.com/a3ve8ksr' target='_blank' rel='noreferrer'>https://challonge.com/a3ve8ksr</a>
						</div>
					</div>
					: <div className='groupsContainerContent'>
						{
							groupsData.list.map((group, index) => (
								<Group group={group} groupsFinished={groupsFinished} key={index} supported={supported} notificationGroup={notificationGroups[index]} />
							))
						}
						{
							groupsFinished && bracketData ? 
								<>
									<div style={{ margin: "30px auto" }} />
									<Bracket data={{...bracketData, supported, notificationBrackets}} />
								</>
							: 	<></>
						}
					</div>
				}
			</div>
		</section>
    );
}
