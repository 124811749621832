import "./Contact.sass"
import Input from "../helpers/input/Input";
import Button from "../helpers/buttons/button/Button";
import { useEffect, useState } from "react";
import validator from "../../services/validator";
import RequestService from "../../services/universalService";
import Swal from "sweetalert2";
import Sloth from "../helpers/sloth/Sloth";
import sloth from "../../images/sloths/contact/contact.png";

export default function Contact() {
    const [emailError, setEmailError] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [contentError, setContentError] = useState("");
    const [sendMailCheck, setSendMailCheck] = useState(false);
	const [styleIndex, setStyleIndex] = useState(0);

	const stylesSloth = [
		{ width: '4rem', top: '.75rem', left: '-1.5rem' }, // Mobile
		{ width: '6.5rem', top: '1.5rem', left: '-2.75rem' } // Desktop
	];

	// If the screen is resized, update sloth positioning
	useEffect(() => {
		const mediaQuery = window.matchMedia('(max-width: 59rem)');
		setStyleIndex(mediaQuery.matches ? 0 : 1);

		const handleMediaQueryChange = mediaQueryList => {
			setStyleIndex(mediaQueryList.matches ? 0 : 1);
		};

		mediaQuery.addEventListener('change', handleMediaQueryChange);
		return () => {
			mediaQuery.removeEventListener('change', handleMediaQueryChange);
		}
	}, []);

    const handleName = (value) => {
        setName(value);
    }
    const handleEmail = (value) => {
        setEmail(value);
    }
    const handleMessage = (e) => {
        setMessage(e.target.value);
    }   
    const sendContactRequest = async () => {
        if(sendMailCheck) {
            return;
        }
        const validateEmail = validator.validate(email, "email", [{ restrictionType: "required" }]);
        if (!validateEmail.valid) {
            setEmailError("Email Invalid");
            return;
        } else {
            setEmailError("");
        }
        const validateSubject = validator.validate(message, "", [{restrictionType: "required"}]);
        if (!validateSubject.valid) {
            setContentError("Subject Empty")
            return;
        } else {
            setContentError("")
        }
        const data = {
            name: name,
            email: email,
            message: message
        }

        RequestService.ask("contact.contact", data, (result, error) => {
            if (error) {
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            } else {
                setSendMailCheck(true)
            }
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        sendContactRequest();
    }

    return(
        <div className="contactPageButton">
            <div className="contactContainer">
                <div className="container">
					<Sloth image={sloth} style={stylesSloth[styleIndex]} />
                    <form action="/" method="post" className="contactForm" onSubmit={handleSubmit}>
                    <div>
                        <h1 className={'contactTitle'}>Contact</h1>
                    </div>
                        <div className="inputs">
                            <div className={'contactInput'}>
                                    <Input type={"text"} name={"name"} placeholder={"name"} icon={"fa-solid fa-user"} defaultText={name} value={name} onChange={handleName}/>
                            </div>
                            <div className={'contactInput'}>
                                    <Input type={"email"} name={"email"} placeholder={"email"} defaultText={email} icon={"fa-regular fa-envelope"} value={email} onChange={handleEmail} />
                                    {emailError && <span className="alert-color">{emailError}</span>}   
                            </div>
                        </div>
                        {contentError && <span className="alert-color">{contentError}</span>}
                        <textarea className={'contactText'} placeholder="Write your message here" value={message} onChange={handleMessage}/>
                        <Button name={"reset"} type={"submit"} text={"Submit"}/>
                        {sendMailCheck && <p className="alert-ok mt-3"> Thank you for your message!</p>}
                    </form>
                </div>
            </div>
        </div>
    )
}