import { Col, Container, Row } from "react-bootstrap";
import "./Match.sass";
import {useEffect, useState} from "react";

export default function Match(props) {
    const [match, setMatch] = useState(undefined);
    const [winner, setWinner] = useState("");
    const TBD = {
        team1:  { name: "TBD", score: "-", },
        team2:  { name: "TBD", score: "-", },
        status: "UPCOMING"
    }
    useEffect(() => {
        // ensure we have a full match so we don't have to condition every display
        // match is completely undefined
        if (!props.match || !props.match.team1.name) {
            setMatch(TBD);
        }
        // one player has been decided
        else if (props.match && props.match.team2.name == undefined) {
            setMatch({
                team1: props.match.team1,
                team2: TBD.team2,
                status: TBD.status
            })
        }
        // match is ready
        else if (props.match) {
            setMatch(props.match);
            setWinner(props.match.status == "FINALIZAT" ? (
                props.match.team1.score > props.match.team2.score ?
                    props.match.team1.name :
                    props.match.team2.name
            ) : "");
        }
    }, [props.match])
    return (
        <>
            {match ?
            <Container className="match p-0">
                <Row className="borders mb-1">
                    <Col className="name ps-1 p-0 col-8 col-xl-9">
                        <p className="m-1 cut-overflow">{match.team1.name}</p>
                    </Col>
                    <Col className={
                        (match.team1.name == winner ? "yellow" : "light-green") +
                        " score borders-left col-4 col-xl-3"
                    }>
                        <p className="m-1">{match.team1.score}</p>
                    </Col>
                </Row>
                <Row className="borders">
                    <Col className="name ps-1 p-0 col-8 col-xl-9">
                        <p className="m-1 cut-overflow">{match.team2.name}</p>
                    </Col>
                    <Col className={
                        (match.team2.name == winner ? "yellow" : "dark-green") +
                        " score borders-left col-4 col-xl-3"
                    }>
                        <p className="m-1">{match.team2.score}</p>
                    </Col>
                </Row>
                <p className="match-name p-0 mb-1">{props.name}</p>
            </Container> : <></>}
        </>
    );
}
