import "./ImageButton.sass";

export default function ImageButton({ image, textStyle, imageStyle, onClick, type, name, text, buttonStyle, className, top, bottom, left, right }) {
    const containerStyle = {
		top: top,
		bottom: bottom,
		left: left,
		right: right,
	};
	
	return (
        <div className="imageButtonContainer" style={containerStyle}>
            <button onClick={() => onClick()} type={type} id={name} name={name} className={"imageButton " + className} style={buttonStyle}>
				<text className="imageButtonText" style={textStyle}>{text}</text>
                <img className="imageButtonImage" src={image} alt={name} style={imageStyle} />
            </button>
        </div>
    );
}
