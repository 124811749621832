import ContactPerson from '../helpers/contactPerson/ContactPerson';
import './Org.sass';
import persons from './data.json'

function Org() {
    return (
		<>
			<div className='orgContainerWrapper'>
				<div className='orgContainer'>
					<div className='orgContainerFlexbox'>
						{persons.map((person, index) => {
							return (<ContactPerson key={index} image={require(`../../images/org/${person.image}.svg`)} name={person.name} role={person.role} email={person.email} phone={person.phone} roleColor={person.roleColor} />);
						})}
					</div>
				</div>
			</div>
		</>
    );
}

export default Org