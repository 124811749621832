import NotificationButton from '../../Notification/notificationButton/NotificationButton';
import './GameRow.sass'

const GameRow = ({ match, supported, notificationTeam }) => {
    const set = match.sport === 'VOLLEYBALL' || match.sport === 'PINGPONG' ? match.scores[0] + match.scores[1] : 0;

    return (
        <section className='gamerow'>			
			<div className='gamerow-content'>
				<div className='gamerow-content-teams gamerow-content-left'>
					<div className='gamerow-content-teams-team'>
						{match.teams[0] ? match.teams[0].faculty : "TBD"}
						{notificationTeam && notificationTeam[0] && notificationTeam[0].eventInteractions &&
							<NotificationButton
								{...notificationTeam[0]}
								supported={supported}
								swalMessage={'this team events'}
								style={{ maxWidth: '1rem', minWidth: '.75rem' }}
								color={'rgba(0, 0, 0, 1)'}
							/>
						} 
					</div>

					<div className='gamerow-content-teams-team'>
						{match.teams[1] ? match.teams[1].faculty : "TBD"}
						{notificationTeam && notificationTeam[1] && notificationTeam[1].eventInteractions &&
							<NotificationButton
								{...notificationTeam[1]}
								supported={supported}
								swalMessage={'this team events'}
								style={{ maxWidth: '1rem', minWidth: '.75rem' }}
								color={'rgba(0, 0, 0, 1)'}
							/>
						}
					</div>
				</div>

				<div className='gamerow-content-right'>
					<div className='gamerow-content-date'>
						{match.date}
					</div>

					<div className='gamerow-content-score'>
						<div className='gamerow-content-score-text'>
							{match.status !== 'UPCOMING' ? 
							<>
								{match.sport === 'VOLLEYBALL' || match.sport === 'PINGPONG' ?
									<>
										<span>
											<div className='gamerow-content-score-text-row'>
												<span className='gamerow-content-score-text-sets'>{match.scores[0]}</span> | <span className="gamerow-content-score-text-sets">{match.partialScores.map((score) => score[0]).join(' ')}</span>
											</div>
											<div className='gamerow-content-score-text-row'>
												<span className='gamerow-content-score-text-sets'>{match.scores[1]}</span> | <span className="gamerow-content-score-text-sets">{match.partialScores.map((score) => score[1]).join(' ')}</span>
											</div>
										</span>
									</> 
									: 
									<span className="gamerow-content-score-text-match">
										{match.scores[0]} - {match.scores[1]}
									</span>
								}
							</> 
								:
							<span className = "gamerow-content-score-text-match">
								{match.scores[0]}  -  {match.scores[1]}
							</span>
							}
						</div>
					</div>
				</div>
			</div>
        </section>
    )
}

export default GameRow
