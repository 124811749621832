import "./Reset.sass"
import Input from "../helpers/input/Input";
import Button from "../helpers/buttons/button/Button";
import { useState } from "react";
import { useParams } from "react-router-dom"
import validate from "../../services/validator";
import RequestService from "../../services/universalService";
import Swal from "sweetalert2";
export default function ResetPass() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const resetToken = useParams().token;
    const handlePassword = (value) => {
        setPassword(value);
    }
    const handleConfirmPassword = (value) => {
        setConfirmPassword(value);
    }
    const sendPassword = async () => {
        const validatePassword = validate.validate(password, "password", [
            { restrictionType: "required" },
            { restrictionType: "minlen", value: "8" },
            { restrictionType: "maxlen", value: "20" }
        ]);

        if (password !== confirmPassword) { setMessage("Parolele nu coincid"); setColor("red"); return; }
        else if (!validatePassword.valid) { setMessage("Minim 8 caractere"); setColor("red"); return; }

        RequestService.ask("auth.reset", { password: password, resetToken: resetToken}, (res, err) => {
            if (err) {
                setMessage("Eroare la resetarea parolei");
                setColor("red");
                return
            }
            setMessage("Parola schimbata cu succes");
            setColor("gray");

			Swal.fire({
				position: 'center',
				icon: 'success',
				title: 'Password changed!',
				showConfirmButton: false,
				timer: 1500
			}).then(() => {
				window.location.href = "/login";
			});
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        sendPassword();
    }
    return (
        <form action="/" method="post" className="resetContainer" onSubmit={handleSubmit}>
            <div className="formBody">
                <h1>Resetează parola</h1>
                <div className="inputsReset">
					<div className="inputReset">
						<Input className="newPass" type={"password"} name={"pass"} ColoScheme={"default"} placeholder={"Parolă nouă"} icon={"fa-solid fa-key"} defaultText={password} onChange={handlePassword} />
						<Input className="confirmNewPass" type={"password"} name={"confimPass"} ColoScheme={"default"} placeholder={"confirmă parola"} icon={"fa-solid fa-key"} defaultText={confirmPassword} onChange={handleConfirmPassword} />
					</div>
					{
						message
						&& <div className="infoAlert">
							<span className={`alert-color-${color}`}>
								{message}
							</span>
						</div>
					}
                </div>
            </div>
            <Button className="resetBtn" name={"reset"} type={"submit"} text={"Trimite"} style={{ background: "#FFFFFF", color: "#454445" }}/>

        </form>
    )
}