import { useState , useEffect} from "react";
import constants from "../../Config/constants";
import Bracket from './Bracket/BracketHelper';
import './Brackets.sass';
import RequestService from "../../services/universalService";
import Button from "../helpers/buttons/button/Button";
import { NotificationService, ServiceWorkerService } from "../../services/notificationService";

export default function Brackets() {
    const [bracketData, setBracketData] = useState({})
    const [sportKey, setSportKey] = useState("Fotbal")
	const [supported, setSupported] = useState(false);
	const [notificationBrackets, setNotificationBrackets] = useState([]);
    const { sports } = constants;

    useEffect(() => {
        RequestService.ask("competitions.getCompetition", {sport: sports[sportKey]}, (result, error) => {
            if (error) {
                setBracketData({});
                console.error(error);
            } else {
                const { brackets } = result.data;

				if (!brackets) {
					return;
				}

                const data = brackets && {
                    semifinal1: brackets.semifinal1 ? {
                        team1:  {
                            name: brackets.semifinal1?.teams[0].faculty,
                            score: brackets.semifinal1?.scores[0],
                        },
                        team2:  {
                            name: brackets.semifinal1?.teams[1]?.faculty,
                            score: brackets.semifinal1?.scores[1],
                        },
                        status: brackets.semifinal1?.status
                    } : {},
                    semifinal2: {
                        team1:  {
                            name: brackets.semifinal2?.teams[0].faculty,
                            score: brackets.semifinal2?.scores[0],
                        },
                        team2:  {
                            name: brackets.semifinal2?.teams[1]?.faculty,
                            score: brackets.semifinal2?.scores[1],
                        },
                        status: brackets.semifinal2?.status
                    },
                    final: {
                        team1:  {
                            name: brackets.final?.teams[0].faculty,
                            score: brackets.final?.scores[0],
                        },
                        team2:  {
                            name: brackets.final?.teams[1]?.faculty,
                            score: brackets.final?.scores[1],
                        },
                        status: brackets.final?.status
                    },
                    bronze: {
                        team1:  {
                            name: brackets.bronze?.teams[0].faculty,
                            score: brackets.bronze?.scores[0],
                        },
                        team2:  {
                            name: brackets.bronze?.teams[1]?.faculty,
                            score: brackets.bronze?.scores[1],
                        },
                        status: brackets.bronze?.status
                    }
                };
				const whoIDs = ['Semifinal 1', 'Semifinal 2', 'Final', 'Bronze'];
				const whereIDs = Array.from({ length: whoIDs.length }, () => result.data._id);
				const wheres = Array.from({ length: whoIDs.length }, () => 'COMPETITION');
				const length = whoIDs.length;
				const bracketArray = [data.semifinal1.status ? "Semifinal 1" : undefined, data.semifinal2.status ? "Semifinal 2" : undefined, data.final.status ? "Final" : undefined, data.bronze.status ? "Bronze" : undefined]
					.filter(bracket => bracket !== undefined);

				RequestService.ask('events.getUserSubscriptions', { where: wheres, whoID: whoIDs, whereID: whereIDs, length: length}, (subscribers, error) => {
					if (error) {
						console.error(error);
					} else if (subscribers) {
						if (subscribers.data.length > 0 && result.data.groupsFinished && bracketArray) {
							NotificationService.updateNotificationButtonsBrackets('COMPETITION', bracketArray, result.data._id, subscribers.data, setNotificationBrackets);
							setBracketData(data);
						}
					}
				})
            }
        })
    }, [sportKey]);

	useEffect(() => {
		ServiceWorkerService.isSupported().then((result) => setSupported(result));
	}, []);

    return (
		<section className='brackets'>
			<div className='bracketsContainer'>
				<div className='bracketsContainerNav'>
					<div className='bracketsContainerNavButtons1'>
						<Button onClick={() => setSportKey('Fotbal')} text='Fotbal' className={`bracketsBtn1 ${sportKey === 'Fotbal' ? 'active' : ''}`} />
						<Button onClick={() => setSportKey('Volei')} text='Volei' className={`bracketsBtn1 ${sportKey === 'Volei' ? 'active' : ''}`} />
						<Button onClick={() => setSportKey('StreetBall')} text='StreetBall' className={`bracketsBtn1 ${sportKey === 'StreetBall' ? 'active' : ''}`} />
					</div>
					<div className='bracketsContainerNavButtons2'>
						<Button onClick={() => setSportKey('Tenis de masa')} text='Tenis de masa' className={`bracketsBtn2 ${sportKey === 'Tenis de masa' ? 'active' : ''}`} />
						<Button onClick={() => setSportKey('Handbal')} text='Handbal' className={`bracketsBtn2 ${sportKey === 'Handbal' ? 'active' : ''}`} />						
					</div>
				</div>
				{sportKey === 'Volei' ?
					<div className='bracketsContainerContent bracketsContainerVolleyball'>
						<div>
							Unfortunately, volleyball has an elimination format. You can still view the brackets on challonge: <a href='https://challonge.com/a3ve8ksr' target='_blank' rel='noreferrer'>https://challonge.com/a3ve8ksr</a>
						</div>
					</div>
					: <div className='bracketsContainerContent'>
					{
        			    bracketData ? <Bracket data={{...bracketData, supported, notificationBrackets}} /> : <></>
        			}
					</div>
				}
			</div>
		</section>
    )
};