import './NotificationModal.sass';
import Button from '../../helpers/buttons/button/Button';
import RequestService from '../../../services/universalService';
import Swal from 'sweetalert2';
import { ServiceWorkerService } from '../../../services/notificationService';

// This modal contains all the buttons for the help section
const NotificationModal = () => {
    const docsURL = 'https://docs.google.com/document/d/1FY13yWEp3HMj9KwWJbCPmj3KNa9OS9gCwg8Zy7dlpk0/edit?usp=sharing';
    const docsURLIOS = 'https://docs.google.com/document/d/10q4e4SjbhdP_XrVB_9P8FYyvf1dpFFK89PoqnIH11gk/edit?usp=sharing';

    const seeDocs = () => {
        window.location.href = docsURL;
    };

    const seeDocsIOS = () => {
        window.location.href = docsURLIOS;
    };

    const testNotification = async () => {
        const message = await ServiceWorkerService.isServiceWorkerRegistered();
        if (message != "success") {
            console.log('Service Worker registration failed');
            Swal.fire({ title: 'Service Worker registration failed', text: message, icon: 'error', showConfirmButton: false, timer: 2000 });
            return;
        }

        RequestService.ask('notifications.testNotification', null, (result, error) => {
            if (error) {
                console.log(error.response.data);
                Swal.fire({ icon: 'error', title: 'Internal server error', text: error.response.data, showConfirmButton: false, timer: 2000 })
            }
        });
    };

    return (
        <>
            <div className='wrapperNotificationHelp'>
				<div className='notificationHelpText'>
					*iPhone users need to save web application to home screen to receive notifications!
				</div>
                <div className="notificationHelpLeft">
                    <Button className="notificationHelpSeeTroubleshoot notificationHelpBtn" onClick={() => seeDocs()} name={"Troubleshoot"} type={"submit"} text={"Troubleshoot"} style={{background: "#009494", color: "#FFFFFF"}} />
                    <Button className="notificationHelpSeeTroubleshootIOS notificationHelpBtn" onClick={() => seeDocsIOS()} name={"Troubleshoot iOS"} type={"submit"} text={"Troubleshoot iOS"} style={{background: "#009494", color: "#FFFFFF"}} />
                </div>
                <div className="notificationHelpRight">
                    <Button className="notificationHelpTestNotification notificationHelpBtn" onClick={() => testNotification()} name={"Test notification"} type={"submit"} text={"Test notification"} style={{background: "#009494", color: "#FFFFFF"}} />
                </div>
            </div>
        </>
    );
  };

export default NotificationModal;