import { useEffect, useState } from "react";
import "./Timer.sass";

const Timer = ({ eventDate }) => {
	const [times, setTimes] = useState([
		{ label: "DAYS", value: 0 },
		{ label: "HRS", value: 0 },
		{ label: "MINS", value: 0 },
		{ label: "SECS", value: 0 }
	]);

	function getFormattedTime(time) {
		return ("0" + time).slice(-2);
	}

	function getTime(deadline) {
		const time = Date.parse(deadline) - Date.now();

		if (time < 0) {
			return;
		}

		const newTimes = times.map((timeObj, index) => {
			switch (index) {
				case 0:
					return { ...timeObj, value: getFormattedTime(Math.floor(time / (1000 * 60 * 60 * 24))) };
				case 1:
					return { ...timeObj, value: getFormattedTime(Math.floor((time / (1000 * 60 * 60)) % 24)) };
				case 2:
					return { ...timeObj, value: getFormattedTime(Math.floor((time / (1000 * 60)) % 60) + 1) };
				case 3:
					return { ...timeObj, value: getFormattedTime(Math.floor((time / 1000) % 60)) };
				default:
					return timeObj;
			}
		});
	
		setTimes(newTimes);
	}

	function firstDecimal(number) {
		return (number / 10 - (number / 10) % 1);
	}

	function secondDecimal(number) {
		return number % 10;
	}
	
	useEffect(() => {
		const interval = setInterval(
			() => getTime(eventDate),
			1000
		);

		return () => clearInterval(interval);
	}, []);

	return (
	  <div className="timerWrapper">
		<div className="timerPassive">
			{times.map((time, index) => (
				<div key={index} className="timerPassiveWrapper">
					<div className="timerPassiveColumn">
						<div className="timerPassiveDigits">
							<p className="timerPassiveDigit">{8}</p>
							<p className="timerPassiveDigit">{8}</p>
						</div>
						<p className="timerPassiveFrame">{time.label}</p>
					</div>
					{index !== times.length - 1 && <p className="timerPassiveSeparator">:</p>}
				</div>
			))}
		</div>
		<div className="timerActive">
			{times.map((time, index) => (
				<div key={index} className="timerActiveWrapper">
					<div className="timerActiveColumn">
						<div className="timerActiveDigits">
							<p className={`timerActiveDigit ${firstDecimal(time.value) === 1 ? " isOne" : ""}`}>{firstDecimal(time.value)}</p>
							<p className={`timerActiveDigit ${secondDecimal(time.value) === 1 ? " isOne" : ""}`}>{secondDecimal(time.value)}</p>
						</div>
						<p className="timerActiveFrame">{time.label}</p>
					</div>
					{index !== times.length - 1 && <p className="timerActiveSeparator">:</p>}
				</div>
			))}
		</div>
	  </div>
	);
}

export default Timer;