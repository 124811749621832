import FacultyRow from "../helpers/facultyRow/FacultyRow";
import "./ScoreBoard.sass";
import { useEffect, useState } from "react";
import RequestService from "../../services/universalService";
import Swal from "sweetalert2";

const ScoreBoard = (props) => {
  let [faculties, setFaculties] = useState([]);

  useEffect(() => {
    RequestService.ask("faculties.getFaculties", {}, (result, error) => {
      if (error || !result) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'Something went wrong!',
			timer: 1500
		})
		return;
      }
      if (result && result.data) {
        setFaculties(
          result.data.map((e) => {
            return (
              <FacultyRow
                key={e.shortName}
                name={e.shortName}
                score={e.score}
              />
            );
          })
        );
      } else {
		  Swal.fire({
		  	icon: 'error',
		  	title: 'Oops...',
		  	text: 'Something went wrong!',
		  	timer: 1500
		  })
		return;
      }
    });
  }, []);

  return (
    <section className="scoreboard">
      <div className="container pb-5">
        <div className="row scoreboard-row scoreboard-scrollable">
          <div className="headerContainer">
            <span className="faculty-name">Facultate</span>
            <span className="faculty-total">Total</span>
          </div>

          <div className="w-100 scoreboard-box">{faculties}</div>
        </div>
      </div>
    </section>
  );
};

export default ScoreBoard;
