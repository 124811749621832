import "./Sporturi.sass"
export default function Handbal({language}) {
    return (
        <>
        {language === "RO" ? 
        <div className="sportRuleSet">
            <div className="sportRule">
                <h4>
                    Competiția se desfășoara conform <a href="https://frh.ro/documente/regulamente/RegulamentulJoculuideHandbalIndoorEditiaIulie2016.pdf">regulamentului FRH</a>, cu următoarele mențiuni:
                </h4>
                <ul>
                    <li>
                        Echipa de teren este formată din 6 jucători + 1  (minim 2 fete)
                    </li>
                    <li>
                        Structura echipei: un portar, 2 extreme, 2 interni, un pivot și un centru
                    </li>
                    <li>
                        Durata unui meci de 15 de minute, unde repriza durează 7 minute, iar pauza dintre reprize 1 minut
                    </li>
                    <li>
                        Se pot face un număr de schimbări nelimitate
                    </li>
                    <li>
                        Echipa care nu se prezintă pe teren într-un interval de 10 minute de la ora programării și nu a anunțat organizatorul de întârziere va pierde meciul cu 7-0.
                    </li>
                </ul>
                <h3>
                    Structura competiției
                </h3>
                <div>
                    Competiția de <b>handball</b> este <b>structurată</b> în felul următor:
                    <ol>
                        <li>
                            <b>Faza grupelor</b>
                            <ul>
								<li>
									4 grupe
								</li>
                                <li>
                                    3 grupe de 4 echipe și o grupă de 3 echipe
                                </li>
                                <li>
                                    va trece mai departe prima echipă clasată din fiecare grupă
                                </li>
                                <li>
                                    punctajul pentru grupe, pe baza căruia se va decide cine trece în faza următoare este: 
                                    <ul>
                                        <li>
                                            3p pentru meci câștigat
                                        </li>
										<li>
											1p pentru egalitate
										</li>
                                        <li>
                                            0p pentru meci pierdut
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Semifinale</b>: meciuri eliminatorii (se joacă un singur meci)
                        </li>
                        <li>
                            <b>Finala mică</b> și <b>finala mare</b>: meciuri eliminatorii(se joacă un singur meci)
                        </li>
                    </ol>
                </div>
                <h3>
                    Începutul jocului
                </h3>
                <ul>
                    <li>
                        Cele două echipe se vor încălzi simultan înainte de startul jocului
                    </li>
                    <li>
						Jocul va începe cu o tragere la sorți (aruncarea monedei). Echipa câștigătoare are de ales între minge și teren. Dacă aceștia aleg mingea, echipa va începe meciul, iar adversarii au dreptul să-și aleagă terenul. Repriza secundă este începută de către echipa care a ales terenul în prima repriză.
                    </li>
                </ul>
                <h3>
                    Reguli în timpul jocului
                </h3>
                <ul>
                    <li>
                        <b>Pași</b> → Niciun jucător nu are voie să facă mai mult de 3 pași cu mingea în mână sau să țină mai mult de 3 secunde mingea în mână.
                    </li>
                    <li>
                        <b>Dublu dribling</b> → Dacă jucătorul bate mingea, se oprește, apoi bate mingea din nou va fi sancționat cu dublu dribling.
                    </li>
                    <li>
                        <b>Picior</b> → Jucătorii nu pot atinge mingea cu piciorul.
                    </li>
                    <li>
                        <b>Joc prepasiv</b> → Dacă arbitrii au senzația că echipa din atac nu atacă poarta, se va dicta joc prepasiv, iar echipa mai are dreptul la maximum 6 pase pentru a arunca la poartă altfel, mingea va reveni echipei adversarei.
                    </li>
                    <li>
                        <b>7 metri</b> → Orice fault care oprește adversarul într-o poziție clară de gol este penalizat cu o aruncare de la linia de 7 metri
                    </li>
                    <li>
                        Le este <b>interzis</b> jucătorilor să intre pe teren cu accesorii ce pot pune în pericol sănătatea altor participanți: brățări, lanțuri, inele, cercei, ochelari etc.
                    </li>
                </ul>
                <h3>
                    Timpii de odihnă - Time-out-uri
                </h3>
                <p>
                    Fiecare echipă va beneficia de un “Time-out” de 1-2 minute în timpul meciului. Orice jucător poate solicita “Time-out” atâta timp cât echipa sa este în atac.
                </p>
                <h3>
                    Schimbările de jucători
                </h3>
                <p>
					Schimbările de jucători sunt permise în orice moment al jocului. Jucătorii pot intra în teren după ce jucătorul pe care îl înlocuiesc a părăsit terenul. Dacă această regulă este încălcată jucătorul va primi penalizare 2 minute.
                </p>
                <h3>
                    Fault, Eliminări
                </h3>
                <p>
                    Se considera fault atunci cand un jucător:
                </p>
                <ul>
                    <li>
                        Se comportă nesportiv
                    </li>
                    <li>
                        Ține sau încearcă să țină un adversar în încercarea de a-i limita exercitarea dreptului la joc
                    </li>
                    <li>
                        Orice fault care oprește adversarul într-o poziție clară de gol este penalizat cu o aruncare de la linia de 7 metri. Nu este nevoie de un fault dur: este de ajuns ca apărătorul să calce semicercul de șase metri în momentul blocajului
                    </li>
                    <li>
                        Faultul dur, violent, intenționat se sancționează cu eliminarea din joc, echipa jucând în inferioritate pentru 2 minute
                    </li>
                </ul>
                <h3>
                    Criteriile de departajare ale echipelor
                </h3>
                <h5>
                    Faza grupelor
                </h5>
                <ul>
                    <li>
                        În caz de egalitate la puncte între 2 echipe după jocurile din faza grupelor, vor intra în vigoare următoarele criterii: 
                        <ul>
                            <li>
                                Rezultatul direct
                            </li>
                            <li>
                                Golaverajul general
                            </li>
                            <li>
                                Numărul mai mare de goluri marcate 
                            </li>
                            <li>
                                Numărul mai mic de goluri primite
                            </li>
                        </ul>
                    </li>
                    <li>
                        În caz de egalitate de puncte între 3 echipe după jocurile din faza grupelor, vor intra în vigoare următoarele criterii de departajare:
                        <ul>
                            <li>
                                Se va întocmi un clasament doar cu rezultatele celor 3 formații aflate la egalitate de puncte și se va califica echipa cu cele mai multe puncte adunate din jocurile cu echipele aflate la egalitate de puncte
                            </li>
                            <li>
                                Golaverajul general din jocurile cu celelalte echipe aflate la egalitate de puncte
                            </li>
                            <li>
                                Numărul mai mare de puncte marcate în jocurile cu celelalte echipe aflate la egalitate de puncte
                            </li>
                            <li>
                                Numărul mai mic de puncte primite în jocul cu celelalte echipe aflate la egalitate de puncte 
                            </li>
                            <li>
                                Golaverajul general în toate jocurile din grupă 
                            </li>
                            <li>
                                Numărul mai mare de goluri marcate în toate jocurile din grupă 
                            </li>
                            <li>
                                Numărul mai mic de goluri primite în toate jocurile	
                            </li>
                        </ul>
                    </li>
                </ul>
				<h5>
					Semifinale și finale
				</h5>
				<ul>
					<li>
						În caz de egalitate la puncte între cele 2 echipe după jocurile din semifinale sau finale,  vor intra în vigoare următoarele criterii de departajare:
						<ul>
							<li>
								Meciul va intra în prelungiri cu o durată de 5 minute
							</li>
							<li>
								În caz de egalitate după cele 5 minute de prelungiri, se vor executa 3 aruncări de la 7 metri per echipă și se vor stabili câstigătorii
							</li>
						</ul>
					</li>
				</ul>
                <h3>
                    Conduita sportivă
                </h3>
                    <p>
						Se aplică regulamentul din secțiunea <b>”Conduita sportivilor”</b> și <b>”Conduita suporterilor”</b> din cadrul <b>Regulament General</b>.
                    </p>
					<div style={{ color: "red" }}>
						Foarte important: Este interzisă folosirea clisterului în cadrul competiției din cauza afectării integrității sălii!!
					</div>
                <h3>
                    Punctaj Clasament General
                </h3>
                <div>
                    Vor primi puncte pentru performanță în cadrul competiției:
                    <ul>
                        <li>
                            100 puncte -  locul 1
                        </li>
                        <li>
                            75 puncte -  locul 2
                        </li>
                        <li>
                            50 puncte -  locul 3
                        </li>
                        <li>
                            25 puncte -  locul 4
                        </li>
                    </ul>
                </div>
				<p>
					<i>
						Menționăm faptul că echipa de organizare își rezervă dreptul de a modifica prezentul regulament în cazul apariției unor situații neprevăzute.
						<br /><br />
						De asemenea, fiecare participant în momentul în care se înscrie la evenimentul Poli Olympics își asumă riscurile unor eventuale accidentări venite din situații mai mult sau mai puțin sportive, echipa de organizare neasumându-și responsabilitatea pentru posibilele accidentări ce ar putea surveni. Totuși, echipa de organizare își asumă responsabilitatea asigurării tuturor condițiilor necesare desfășurării evenimentului în condițiile de siguranță impuse prin lege.
					</i>
				</p>
            </div>
        </div> : 
            // ------------------------------------------- Engleza -------------------------------------------
        <div className="sportRuleSet">
            <div className="sportRule">
                <h4>
               The competition is held according to the  <a href="http://eurohandbal.ro/regulament-handbal/">FHR regulation</a>, with the following mentions::
                </h4>
                <ul>
                    <li>
                    	The field team consists 6 players + 1 (minimum 3 girls)
                    </li>
                    <li>
                    	The structure of the team: 1 goalkeeper, 2 wingers, 2 backs, 1 center backs and 1 pivot
                    </li>
                    <li>
                    	The duration of a match of 20 minutes, where the half lasts 10 minutes, and the break between halves 3 minutes
                    </li>
                    <li>
                    	A number of unlimited changes can be made
                    </li>
                    <li>
                    	The team that does not show up on the field within 10 minutes of the time of the appointment and has not announced the organizer of the delay will lose the match 7-0
                    </li>
                </ul>
                <h3>
                Possible structure of the competition
                </h3>
                <div>
                The handball competition is structured in the following way:
                    <ol>
                        <li>
                            <b>Group Stage</b>
                            <ul>
                                <li>
                                	3 groups of 4 teams and 1 group of 3 teams
                                </li>
                                <li>
                                	the first ranked team in each group will pass on
                                </li>
                                <li>
                                	the score for the groups, on the basis of which it will be decided who passes in next stage is:
                                    </li>
                                <li>
                                    punctajul pentru grupe, pe baza căruia se va decide cine trece în faza următoare este: 
                                    <ul>
                                        <li>
                                        	3 points for win game
                                        </li>
                                        <li>
                                        	0 points for lose game
                                        </li>
                                        <li>
                                        	there is no possibility that a match will end in a tie
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Semi-finals</b>: knockout matches (only one match is played)
                        </li>
                        <li>
                            <b>The small final</b> and <b>grand final</b>: knockout matches (only one match is played)
                        </li>
                    </ol>
                </div>
                <h3>
                The start of the game
                </h3>
                <ul>
                    <li>
                    	The two teams will prepare simultaneously before the start of the game
                    </li>
                    <li>
                    	The game will start with a draw (coin throwing). The winning team has a choice between the ball and the field. If they choose the ball, the team will start the match and the opponents have the right to those
                    </li>
                </ul>
                <h3>
                Rules during the game
                </h3>
                <ul>
                    <li>
                        <b>Walking</b> → No player is allowed to take more than 3 steps with the ball in his hand
                    </li>
                    <li>
                        <b>Double dribble</b> → If the player beats the ball, stops, then beats the ball again will be sanctioned with double dribbling
                    </li>
                    <li>
                        <b>Foot</b> → Players can't touch the ball with their feet
                    </li>
                    <li>
                        <b>Passive play</b> → If the referees have the feeling that the attacking team is not attacking the goal, passive play will be dictated, and the ball will return to the opponent
                    </li>
                    <li>
                        <b>The 7-meter throw</b> →Any foul that stops the opponent in a clear goal position is penalized with a throw from the 7-meter line
                    </li>
                    <li>
                    Players are forbidden to enter the field with accessories that can endanger the integrity of other participants: bracelets, chains, rings, earrings, glasses, etc.


                    </li>
                </ul>
                <h3>
                    Time-out
                </h3>
                <p>
                Each team will benefit from a time-out of 1-2 minutes during the match. Any player can request time-out as long as his team is in attack.
                </p>
                <h3>
                Player substitutions
                </h3>
                <p>
                Player substitutions  are allowed at any time of the game. Players can enter the field after the player they are replacing has left the field. If this rule is violated the player will receive a 2-minute-suspension.
                </p>
                <h3>
                Fouls, Suspensions
                </h3>
                <p>
                It is considered a foul when a player:
                </p>
                <ul>
                    <li>
                    	Behaves unsportsmanlike
                    </li>
                    <li>
                    	Holds or attempts to hold an opponent in an attempt to limit their enjoyment of the right to play
                    </li>
                    <li>
                    	Stops the opponent in a clear goal position. This will be penalized with a throw from the 7-meter line. There is no need for a hard foul: it is enough for the defender to step on the semicircle of six-meter semicircle at the time of the blockage
                    </li>
                    <li>
                    	Hard, violent, intentional foul is sanctioned with elimination from the game, the team playing in inferiority for 2 minutes
                    </li>
                </ul>
                <h3>
                The tie-breakers criteria for the teams
                </h3>
                <h5>
                Groups stage
                </h5>
                <ul>
                    <li>
                    	In case of a tie for points between 2 teams after the games in the group stage, the following criteria will come into force:
                        <ul>
                            <li>
                            	Direct result
                            </li>
                            <li>
                            	Overall goal difference
                            </li>
                            <li>
                            Numărul mai mare de goluri marcate 
                            </li>
                            <li>
                            	Lower number of goals conceded
                            </li>
                        </ul>
                    </li>
                    <li>
                    	In case of a tie for points between 3 teams after the games in the group stage, the following criteria will come into force:
                        <ul>
                            <li>
                            	A ranking will be drawn up only with the results of the 3 teams in a tie for points and the team with the most points gathered from the games with the teams in a tie will qualify.
                            </li>
                            <li>
                            	Overall goal difference in games with the other teams tied on points
                            </li>
                            <li>
                            	The higher number of points scored in games with the other teams in a tie for points
                            </li>
                            <li>
                            	The lower number of points conceded in games with the other teams in a tie for points
                            </li>
                            <li>
                            	Overall goal difference in all group games
                            </li>
                            <li>
                            	The higher number of goals scored in all group games
                            </li>
                            <li>
                            	The lower number of goals conceded in all group games
                            </li>
                        </ul>
                    </li>
                </ul>
                <h3>
                Sports conduct
                </h3>
                    <p>
                    It is very important for each participant in the competition to have disciplined behavior, respecting the imposed rules.
                    </p>
                    <p>
                    If one of the participants behaves inappropriately and causes high-level problems inside the sports facility, he/she will be excluded from the competition. If his attitude makes it impossible to conduct the match that was being played, the game will be stopped and the team that produced the problem will lose the match at the green table.
                    </p>


                <h3>
                Overall Ranking Score
                </h3>
                <div>
                The teams will receive points for performance in the competition:
                    <ul>
                        <li>
                        	 100 points - 1st place
                        </li>
                        <li>
                        	  75 points -  2nd place
                        </li>
                        <li>
                        	  50 points -  3rd place
                        </li>
                        <li>
                        	  25 points -  4th place
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        }
        </>
    
    )
}