import "./Navbar.sass";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import logo from "../../images/logo.svg";
import navbarLogo from "../../images/vector.svg";
import navImg from "../../images/backgrounds/background-navbar.svg";
import navImgAlt from "../../images/backgrounds/background-navbar-alternative.svg";

export default function CustomNavbar() {
  const [loggedIn, setloggedIn] = useState(false);

  let initialNavbar = [
	{ href: "/minimap", value: "Harta" },
    { href: "/faculties/ACS", value: "Facultati" },
    { href: "/scoreboard", value: "Clasament" },
    { href: "/groups", value: "Grupe" },
    { href: "/rules", value: "Regulament" },
    { href: "/partners", value: "Parteneri" },
    { href: "/contact", value: "Contact" },
    { href: "/faq", value: "FAQ" },
	{ href: "/org", value: "Organizare" },
  ];

  if (!loggedIn) {
    initialNavbar = initialNavbar.concat([
      { href: "/login", value: "Conectare" },
      //   { href: "/forgotPassword", value: "Am uitat parola" },
    ]);
  } else {
    initialNavbar = initialNavbar.concat(
      { href: "/profile", value: "Contul Meu" },
      // { 'href': '/bets', value: 'Pariuri'},
      { href: "/sign_out", value: "Delogare" }
    );
  }
  let sportsNavbar = [
    { href: "/livescore", value: "Live" },
    { href: "/games/fotbal", value: "Fotbal" },
    { href: "/games/handbal", value: "Handbal" },
    { href: "/games/volei", value: "Volei" },
    { href: "/games/streetball", value: "Baschet" },
    { href: "/games/tenisMasa", value: "Tenis de masa" },
  ];

  // different colors for the links(static/active)
  const [initial, setInitial] = useState(true);

  function switchViews() {
    setInitial(!initial);
  }

  useEffect(() => {
    if (localStorage.getItem("loginToken") != null) setloggedIn(true);
  }, []);

  return (
    <Navbar expand={false}>
      <Container fluid id="navbar">
        <img src={logo} alt="PO_LOGO" className="logo" />

        <Navbar.Toggle
          aria-controls="offcanvasNavbar"
          id="hamburger"
          style={{ borderline: "none", boxShadow: "none" }}
        >
          <svg
            width="40"
            height="30"
            viewBox="0 0 81 69"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.949951 6.25001C0.949951 4.75153 1.54522 3.31443 2.6048 2.25485C3.66438 1.19527 5.10148 0.600006 6.59995 0.600006H74.4C75.8984 0.600006 77.3355 1.19527 78.3951 2.25485C79.4547 3.31443 80.05 4.75153 80.05 6.25001C80.05 7.74848 79.4547 9.18558 78.3951 10.2452C77.3355 11.3047 75.8984 11.9 74.4 11.9H6.59995C5.10148 11.9 3.66438 11.3047 2.6048 10.2452C1.54522 9.18558 0.949951 7.74848 0.949951 6.25001ZM0.949951 34.5C0.949951 33.0015 1.54522 31.5644 2.6048 30.5049C3.66438 29.4453 5.10148 28.85 6.59995 28.85H74.4C75.8984 28.85 77.3355 29.4453 78.3951 30.5049C79.4547 31.5644 80.05 33.0015 80.05 34.5C80.05 35.9985 79.4547 37.4356 78.3951 38.4952C77.3355 39.5547 75.8984 40.15 74.4 40.15H6.59995C5.10148 40.15 3.66438 39.5547 2.6048 38.4952C1.54522 37.4356 0.949951 35.9985 0.949951 34.5ZM0.949951 62.75C0.949951 61.2515 1.54522 59.8144 2.6048 58.7549C3.66438 57.6953 5.10148 57.1 6.59995 57.1H74.4C75.8984 57.1 77.3355 57.6953 78.3951 58.7549C79.4547 59.8144 80.05 61.2515 80.05 62.75C80.05 64.2485 79.4547 65.6856 78.3951 66.7452C77.3355 67.8047 75.8984 68.4 74.4 68.4H6.59995C5.10148 68.4 3.66438 67.8047 2.6048 66.7452C1.54522 65.6856 0.949951 64.2485 0.949951 62.75Z"
              fill="#FFFFFF"
            />
          </svg>
        </Navbar.Toggle>

        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
          autoFocus="false"
          scroll="true"
		  style={{ backgroundImage: initial
		  			? `url(${navImg})`
					: `url(${navImgAlt})`}}
        >
          <Offcanvas.Body id="navbar-container">
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link
                className="roll-out nav-link"
                href="/"
              >
                <img src={navbarLogo} alt="logo" className="logo-nav" />
                Acasa
              </Nav.Link>

              <Nav.Link
                className={
                  "roll-out nav-link " + (!initial ? "sports-link" : "")
                }
                onClick={switchViews}
              >
                <img src={navbarLogo} alt="logo" className="logo-nav" />
                Meciuri &#x25BE;
              </Nav.Link>
              {!initial
                ? sportsNavbar.map(function (e, idx) {
                    return (
                      <Nav.Link
                        href={e.href}
                        className="roll-out nav-link"
                        key={idx}
                      >
                        <img src={navbarLogo} alt="logo" className="logo-nav" />
                        {e.value}
                      </Nav.Link>
                    );
                  })
                : null}
              {initial
                ? initialNavbar.map(function (e, idx) {
                    if (e.blank) {
                      return (
                        <a
                          href={e.href}
                          target="_blank"
                          className="roll-out nav-link"
                          key={idx}
                          rel="noreferrer"
                        >
                          {e.value}
                        </a>
                      );
                    }
                    return (
                      <Nav.Link
                        href={e.href}
                        className="roll-out nav-link"
                        key={idx}
                      >
                        <img src={navbarLogo} alt="logo" className="logo-nav" />
                        {e.value}
                      </Nav.Link>
                    );
                  })
                : null}
              {/**uncomment this for all sports */}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
