import { useState } from 'react';
import './SportStats.sass'

const SportStats = ({ stats }) => {
	const [isHovering, setIsHovering] = useState(null);

    const handleMouseOver = (index) => {
        setIsHovering(index);
    };

    const handleMouseOut = () => {
        setIsHovering(null);
    };

    return (
		<>
			{stats.matches.length > 0 &&
				<div className='facultySportStats'>
					<div className='facultySportStatsNav'>
						<div className='facultySportStatsNavText'>
							Punctaj: {stats.sport} - {stats.points}
						</div>
					</div>
					<div className='facultySportStatsContainer'>
						<div className='facultySportStatsContainerTable'>
							{stats.matches.map((match, index) => (
								<div className='facultySportStatsContainerTableRow' key={index}>
									<div className='facultySportStatsContainerTableRowCol'>{match.teams[0] ? match.teams[0].faculty : "TBD"}</div>
									<div className='facultySportStatsContainerTableRowCol'>{match.teams[1] ? match.teams[1].faculty : "TBD"}</div>
									<div className='facultySportStatsContainerTableRowCol'>{match.date}</div>
									{stats.sport === 'Volei' || stats.sport === 'Tenis de masa' ?
										<div className='facultySportStatsContainerTableRowCol' onMouseOver={() => handleMouseOver(index)} onMouseOut={handleMouseOut}>
											{isHovering === index && match.partialScores.length > 0 ?
												<div className='facultySportStatsContainerTableRowColPS'>
														<div className='facultySportStatsPartialScore'>
															{`${match.partialScores[0][0]} - ${match.partialScores[0][1]}`} {/*WARNING: this formula is only for 1 set matches*/}
														</div>
												</div>
												: <>
													{`${match.scores[0]} - ${match.scores[1]}`}
												</>
											}
										</div>
										: <>
											<div className='facultySportStatsContainerTableRowCol'>{match.scores[0]} - {match.scores[1]}</div>
										</>
									}
								</div>
							))}
						</div>
					</div>
				</div>
			}
		</>
    );
};

export default SportStats;
